<ng-container *ngIf="disableWidget">
    <div class="icon-container">
        <button nbButton size="tiny" [status]="disableWidget" [disabled]="disableWidget">
            <nb-icon class="icon" icon="alert-circle-outline"></nb-icon>
            <ng-container>
                <div class="icon-text">
                    <label class="title">Data Quality</label>
                </div>
            </ng-container>
        </button>
    </div>
</ng-container>

<!-- Data quality widget is not disabled -->
<ng-container *ngIf="!disableWidget">
    <div
        class="icon-container"
        [ngClass]="
            (this.controlPanelCollapsed$ | async) ? 'icon-container-collapsed' : 'icon-container-popout-expanded'
        "
    >
        <!-- Loading template -->
        <ng-container *ngIf="dataAuditStore.studyDataQualityLoading$ | async as widgetLoading">
            <button
                nbButton
                size="tiny"
                [disabled]="true"
                [nbSpinner]="widgetLoading"
                nbSpinnerSize="small"
                nbSpinnerStatus="basic"
            >
                <nb-icon class="icon" icon="alert-circle-outline"> </nb-icon>
                <ng-container>
                    <div class="icon-text">
                        <label class="title">Data Quality</label>
                    </div>
                </ng-container>
            </button>
        </ng-container>

        <!-- Data has loaded -->
        <ng-container *ngIf="dataQuality$ | async as dataQuality">
            <button
                nbButton
                size="tiny"
                [status]="getButtonColor(dataQuality.qualityStatus)"
                [disabled]="dataQuality.qualityStatus === QUALITY_STATUS_TYPE.DISABLED"
                (click)="isCollapsed = !isCollapsed"
            >
                <nb-icon class="icon" [icon]="getButtonIcon(dataQuality.qualityStatus)"></nb-icon>
                <ng-container>
                    <div class="icon-text">
                        <label class="title">Data Quality</label>
                    </div>
                </ng-container>
            </button>

            <div class="displayBelow" [ngbCollapse]="!isCollapsed">
                <nb-card>
                    <!-- Render each row of data -->
                    <ng-container *ngFor="let row of dataQuality.data; let isLast = last">
                        <div class="property-box">
                            <span class="property-title">{{ row.name }}</span>
                            <span class="property-value">
                                <span class="property-text">{{ getFormattedValue(row.value, row.unit) }} </span>
                                <span class="property-icon">
                                    <ng-container [ngSwitch]="row.trend">
                                        <nb-icon
                                            class="trend-icon trend-icon-up"
                                            *ngSwitchCase="'up'"
                                            icon="diagonal-arrow-right-up-outline"
                                        ></nb-icon>
                                        <nb-icon
                                            class="trend-icon trend-icon-down"
                                            *ngSwitchCase="'down'"
                                            icon="diagonal-arrow-right-down-outline"
                                        ></nb-icon>
                                        <nb-icon
                                            class="trend-icon trend-icon-flat"
                                            *ngSwitchCase="'flat'"
                                            icon="arrow-forward-outline"
                                        ></nb-icon>
                                    </ng-container>
                                </span>
                            </span>
                        </div>
                        <!-- Break between items -->
                        <hr *ngIf="!isLast" class="property-divider" />
                    </ng-container>
                </nb-card>
            </div>
        </ng-container>
    </div>
</ng-container>
