import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpService} from '../../common/api/http.service';
import {AssetHealthIndex} from '../../../interfaces/engin/assets';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';

@Injectable()
export class AssetsApi {
    private readonly prefix = 'analyzer/assets/study';

    constructor(private api: HttpService) {}

    // Study Assets
    getStudyAssetsDataSource(studyId: string): Observable<DataSource> {
        return this.api.getServerDataSource(`${this.prefix}/${studyId}/list`);
    }

    searchStudyAssets(studyId: string, assetId: string): Observable<string[]> {
        return this.api.get(`${this.prefix}/${studyId}/search/${assetId}`);
    }

    getStudyAsset(studyId: string, assetId: string) {
        return this.api.get(`${this.prefix}/${studyId}/${encodeURIComponent(assetId)}`);
    }

    // Asset Details
    getAssetDetails(studyId: string, assetId: string): Observable<any> {
        return this.api.get(`${this.prefix}/${studyId}/details/${encodeURIComponent(assetId)}`);
    }

    getAssetDetailsHealthIndex(studyId: string, assetId: string): Observable<AssetHealthIndex> {
        return this.api.get(`${this.prefix}/${studyId}/details-hi/${encodeURIComponent(assetId)}`);
    }
}
