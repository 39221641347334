import {Component, Input, ChangeDetectionStrategy} from '@angular/core';
import {Page} from '../../../@core/interfaces/common/pages';

@Component({
    selector: 'ngx-submenu-popover',
    template: `
        <div class="submenu-container">
            <div class="header-container">
                <div class="pointer"></div>
                <label>{{ item.title }}</label>
            </div>
            <ngx-submenu [items]="item.children"></ngx-submenu>
        </div>
    `,
    styleUrls: ['./menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubmenuPopoverComponent {
    @Input() item: Page;
}
