import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class CrossSelectorService {
    // crossFilterDataSubject = new BehaviorSubject(null);
    // crossFilterData$ = this.crossFilterDataSubject.asObservable();
    //
    // crossGroupDataSubject = new BehaviorSubject(null);
    // crossGroupData$ = this.crossGroupDataSubject.asObservable();

    crossDataSubject = {};

    getCDS(key: string): BehaviorSubject<any> {
        if (!(key in this.crossDataSubject)) {
            this.crossDataSubject[key] = new BehaviorSubject(null);
        }
        return this.crossDataSubject[key];
    }
}
