import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ThemeModule} from '../@theme/theme.module';
import {DemographicsBarChartComponent} from './analyzer/demographics-bar-chart/demographics-bar-chart.component';
import {OptimizerBarChartComponent} from './optimizer/optimizer-bar-chart/optimizer-bar-chart.component';
import {EolMetricStatusComponent} from './analyzer/eol-metric-status/eol-metric-status.component';
import {EolMetricSummaryComponent} from './analyzer/eol-metric-summary/eol-metric-summary.component';
import {EolMetricSummaryBarComponent} from './analyzer/eol-metric-summary-bar/eol-metric-summary-bar.component';

const CHART_COMPONENTS = [
    // Analyzer
    EolMetricStatusComponent,
    EolMetricSummaryComponent,
    EolMetricSummaryBarComponent,
    DemographicsBarChartComponent,

    // Optimizer
    OptimizerBarChartComponent,
];

@NgModule({
    declarations: [...CHART_COMPONENTS],
    exports: [...CHART_COMPONENTS],
    imports: [ThemeModule, CommonModule],
})
export class ChartLibModule {}
