import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {User, UserNotification, UsersService} from '@core/interfaces/common/users';
import {switchMap, takeUntil} from 'rxjs/operators';
import {Unsubscribable} from '@core/interfaces/unsubscribable';
import {Router} from '@angular/router';
import {NbToastrService} from '@nebular/theme';

export enum Org {
    EII,
    SWI,
    MES,
    BBA,
}
@Injectable()
export class UsersStore extends Unsubscribable {
    public multipleTenants = new BehaviorSubject<boolean>(false);
    public currentUser = new BehaviorSubject<User>(null);
    readonly currentUser$: Observable<User> = this.currentUser.asObservable();

    constructor(private userService: UsersService, private router: Router, private toastrService: NbToastrService) {
        super();

        this.userService.getCurrentUser().subscribe((user: User) => {
            // If user timezone is undefined (never set), one-time set based on timezone from browser
            if (!user.timezone) {
                const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                const newUserInfo = {
                    ...user,
                    timezone: localTimezone,
                };
                this.userService
                    .update(newUserInfo)
                    .pipe(takeUntil(this.unsubscribe$))
                    .subscribe(() => {
                        this.currentUser.next(newUserInfo);
                    });
            } else {
                this.currentUser.next(user);
            }

            // Support for different icons for EII vs. partner (SWI) organizations
            try {
                const userOrg: Org = this.userOrg(user);
                if (userOrg != this.iconOrg()) {
                    const oldLinkPng = document.getElementById('dynamic-favicon-png'),
                        oldLinkIco = document.getElementById('dynamic-favicon-ico'),
                        linkPng = document.createElement('link'),
                        linkIco = document.createElement('link');
                    linkPng.id = 'dynamic-favicon-png';
                    linkPng.rel = 'icon';
                    linkPng.href = this.getFaviconPng(userOrg);
                    linkIco.id = 'dynamic-favicon-ico';
                    linkIco.rel = 'icon';
                    linkIco.href = this.getFaviconIco(userOrg);
                    if (oldLinkPng) {
                        document.head.removeChild(oldLinkPng);
                    }
                    if (oldLinkIco) {
                        document.head.removeChild(oldLinkIco);
                    }
                    document.head.appendChild(linkPng);
                    document.head.appendChild(linkIco);
                }
            } catch (err) {
                // do nothing
            }
        });
    }

    logout() {
        // Clear all local data, navigate to login page; send session expired message
        localStorage.clear();
        this.router.navigate(['/auth/login'], {state: {sessionExpire: true}});
        setTimeout(() => {
            this.toastrService.warning(
                'Session has expired and you have been automatically logged out. ' + 'Please log back in.',
                'Session is expired',
                {duration: 0},
            );
        }, 1500);
    }

    public updateUserDefaultStudyStrategy(latestAsDefault, defaultCollectionId) {
        const currentUser: User = this.currentUser.getValue();

        // Update value if it has actually changed
        if (currentUser.studyStrategy.latestAsDefault !== latestAsDefault) {
            const newUser: User = {
                ...currentUser,
                studyStrategy: {
                    ...currentUser.studyStrategy,
                    latestAsDefault: latestAsDefault,
                    defaultCollectionId: defaultCollectionId,
                },
            };
            this.currentUser.next(newUser);
        }
    }

    public updateUserInfo(newUser) {
        const currentUser: User = this.currentUser.getValue();
        const newUserInfo: User = {
            ...currentUser,
            ...newUser,
        };
        this.currentUser.next(newUserInfo);
    }
    public fromLogin = new BehaviorSubject<boolean>(false);

    readonly getUserNotifications$: Observable<UserNotification[]> = this.fromLogin.asObservable().pipe(
        switchMap((flag) => {
            if (flag) {
                return this.userService.getCurrentUser().pipe(
                    switchMap((userInfo) => {
                        return this.userService.getUsersNotification(userInfo.id);
                    }),
                );
            } else {
                return of(null);
            }
        }),
    );

    private userOrg(user: User): Org {
        if (user && user.username) {
            if (user.username.includes('swi.') || user.username.includes('.swi')) return Org.SWI;
            if (user.username.includes('mes.') || user.username.includes('.mes')) return Org.MES;
            if (user.username.includes('bba.') || user.username.includes('.bba')) return Org.BBA;
            return Org.EII;
        }
        return Org.EII;
    }
    private iconOrg(): Org {
        const favicon = document.getElementById('dynamic-favicon-png');
        if (favicon) {
            if (favicon['href'].includes('swi')) return Org.SWI;
            if (favicon['href'].includes('mes')) return Org.MES;
            if (favicon['href'].includes('bba')) return Org.BBA;
            return Org.EII;
        }
        return Org.EII;
    }
    private getFaviconPng(org: Org): string {
        switch (org) {
            case Org.SWI:
                return 'assets/favicon/favicon_swi.png';
            case Org.MES:
            case Org.BBA:
                return 'assets/favicon/favicon_mes.png';
            case Org.EII:
            default:
                return 'assets/favicon/favicon.png';
        }
    }
    private getFaviconIco(org: Org): string {
        switch (org) {
            case Org.SWI:
                return 'assets/favicon/favicon_swi.ico';
            case Org.MES:
            case Org.BBA:
                return 'assets/favicon/favicon_mes.png';
            case Org.EII:
            default:
                return 'assets/favicon/favicon.ico';
        }
    }
    public getCurrentOrg(): Org {
        try {
            return this.userOrg(this.currentUser.value);
        } catch (err) {
            return Org.EII;
        }
    }
    public checkCurrentOrg(user: User): Org {
        try {
            return this.userOrg(user);
        } catch (err) {
            return Org.EII;
        }
    }
}
