import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'ngx-column-with-icon',
    templateUrl: './column-with-icon.component.html',
    styleUrls: ['./column-with-icon.component.scss'],
})
export class ColumnWithIconComponent implements OnInit {
    @Input() rowData: any;
    @Input() dataHasName: boolean;
    @Input() preparedData: {name: string; icon: string; class: string};
    @Input() colTitle: string;
    @Input() tableIcons: any;

    // @Input() tableSettings: SCTableSettings;

    constructor() {}

    // Supporting structures to render icon/name/class appropriately
    nameWithIcon = false;
    iconList;
    name;
    class;

    ngOnInit() {
        if (this.dataHasName) {
            if (this.preparedData) {
                this.nameWithIcon = true;
                this.name = this.preparedData.name;
                this.iconList = this.preparedData.icon;
                this.class = this.preparedData.class;
            }
        } else {
            if (this.preparedData) {
                this.iconList = this.preparedData.icon;
                this.class = this.preparedData.class;
            }
        }

        if (this.colTitle.toLowerCase() === 'file-extract') {
            this.actionIconSelect(this.rowData, this.tableIcons);
        }
    }

    actionIconSelect(data, icons) {
        const commentIcon = 'message-square-outline';
        this.iconList = icons;

        this.class = 'file-extract-default';
        if (icons === commentIcon) {
            if (data.comment) {
                if (data.type === 'new') {
                    this.class = 'file-extract';
                } else {
                    this.class = 'message';
                }
            }
        }
    }
}
