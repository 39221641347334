import {Observable} from 'rxjs';

// Optimizer request for main analysis
export class OptimizerAnalysisRequest {
    isConstrained: boolean;
    applySystemDropdown: boolean;
    stateChanged: boolean;
    sensitivityStudy: {
        studyId: string;
        userId: string;
        currentYear: number;
        evaluationPeriod: number;
        sensitivityParams: [StringSet];
    };
    longtermPlanningMethod: number;
    sliders: {
        dropdown: string;
        sliders: {
            slider: string;
            values: number[];
            isUnitMonetary: boolean;
        }[];
    }[];

    constructor(
        isConstrained: boolean,
        applySystemDropdown: boolean,
        stateChanged: boolean,
        sliders: any,
        longtermPlanningMethod: number,
    ) {
        const defaultPlanningMethod = 2;
        this.isConstrained = isConstrained;
        this.applySystemDropdown = applySystemDropdown;
        this.stateChanged = stateChanged;
        this.sliders = sliders;
        this.sensitivityStudy = null;
        this.longtermPlanningMethod = longtermPlanningMethod ? longtermPlanningMethod : defaultPlanningMethod;
    }
}

// Optimizer request for charts
export class OptimizerChartRequest {
    isConstrained: boolean;
    sensitivityStudy: {
        studyId: string;
        userId: string;
        currentYear: number;
        evaluationPeriod: number;
        sensitivityParams: [StringSet];
    };
    graphList: {
        graphId: string;
        unit: string;
    }[];

    constructor() {}
}

// Generic chart response object contains data for constrained and unconstrained views
export interface OptimizerChartResponse {
    constrained: ClusteredSeries[];
    unconstrained: ClusteredSeries[];
}

export interface ClusteredSeries {
    code: string;
    name: string;
    graphTitle: string;
    graphId: string;
    legend: string[];
    labels: string[];
    data: number[][];
    unit: Unit;
}

export interface Unit {
    prefix: string[];
    suffix: string[];
    symbol: string[];
}

// Specific chart response for reporting summary KPIs
export interface OptimizerKpiResponse {
    constrained: KpiDataResp[];
    unconstrained: KpiDataResp[];
}

export interface KpiDataResp {
    title: string;
    subtitle: string;
    unit: string;
    residualValue: number;
    delta: number;
}

// Common
export interface StringSet {
    [param: string]: string;
}

export abstract class OptimizerService {
    abstract checkOptimizerResultsReady(request: OptimizerAnalysisRequest): Observable<boolean>;

    abstract getChartDataSummarySpending(request: OptimizerChartRequest): Observable<OptimizerChartResponse>;

    abstract getChartDataSummaryOutcomes(request: OptimizerChartRequest): Observable<OptimizerKpiResponse>;

    abstract getChartDataProactiveForecast(request: OptimizerChartRequest): Observable<OptimizerChartResponse>;

    abstract getChartDataReactiveForecast(request: OptimizerChartRequest): Observable<OptimizerChartResponse>;

    abstract getChartDataFailureForecast(request: OptimizerChartRequest): Observable<OptimizerChartResponse>;

    abstract getChartDataOutageForecast(request: OptimizerChartRequest): Observable<OptimizerChartResponse>;

    abstract getChartDataRiskForecastByImpact(request: OptimizerChartRequest): Observable<OptimizerChartResponse>;

    abstract getChartDataRiskForecastByCategory(request: OptimizerChartRequest): Observable<OptimizerChartResponse>;
}
