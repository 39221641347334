import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpService} from '../../../@core/backend/common/api/http.service';
import {DataUploadRecord} from './pojo/failure-data-management.pojo';
import {SurvivalStudyRequest} from './pojo/survival-study.pojo';
import {CurveCalibrationDetails} from './pojo/curve-calibration-outputs.pojo';
import {DownloadLink} from '../../../@core/interfaces/common/legacyUploads';
import {HttpParams} from '@angular/common/http';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';

@Injectable()
export class SurvivalAnalysisApi {
    private readonly prefix = 'survivalAnalysis';

    constructor(private api: HttpService) {}

    getUploadHistoryData(): Observable<DataUploadRecord[]> {
        return this.api.get(`${this.prefix}/data/uploads`);
    }
    getUploadDatatableHistory(): Observable<DataSource> {
        return this.api.getServerDataSource(`${this.prefix}/data/uploadsList`);
    }

    getAnalysisHistoryData(): Observable<DataSource> {
        return this.api.getServerDataSource(`${this.prefix}/analysis/history`);
    }
    getCurveCalibrationData(survivalStudyId: string): Observable<CurveCalibrationDetails[]> {
        return this.api.get(`${this.prefix}/analysis/history/${survivalStudyId}`);
    }

    getSurvivalStudyEditAvailable(survivalStudyId: string): Observable<boolean> {
        return this.api.get(`${this.prefix}/analysis/checkEditAvailable/${survivalStudyId}`);
    }

    // Execution
    executeNewSurvivalStudy(req: SurvivalStudyRequest): Observable<any> {
        return this.api.post(`${this.prefix}/analysis/execute`, req);
    }

    submitSurvivalAnalysis(studyId: string): Observable<any> {
        return this.api.get(`${this.prefix}/analysis/submit/${studyId}`);
    }

    // Update
    updateDataUploadComment(uploadId: string, newComment: string): Observable<boolean> {
        const req = {
            uploadId: uploadId,
            comment: newComment,
        };
        return this.api.post(`${this.prefix}/data/uploads/edit/comment`, req);
    }

    updateCurveCalibrationComment(studyId: string, curveId: number, newComment: string) {
        const req = {
            studyId: studyId,
            curveId: curveId,
            comment: newComment,
        };
        return this.api.post(`${this.prefix}/analysis/edit/comment`, req);
    }

    updateCurveCalibrationActions(studyId: string, curveId: number, isAccepted: boolean, isRejected: boolean) {
        const req = {
            studyId: studyId,
            curveId: curveId,
            isAccepted: isAccepted,
            isRejected: isRejected,
        };
        return this.api.post(`${this.prefix}/analysis/edit/action`, req);
    }

    downloadFile(fileKey: string, type: string, medium: string): Observable<DownloadLink> {
        const params = new HttpParams().set('fileKey', fileKey);
        return this.api.get(`${this.prefix}/data/download/${medium}/${type}`, {params: params});
    }
}
