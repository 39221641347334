import {Injectable} from '@angular/core';
import {ProgramManagementApi} from '@core/backend/engin/api/program-management.api';
import {ProgramManagementService} from '@core/interfaces/engin/program-management';

@Injectable()
export class ProgramManagementServiceImpl extends ProgramManagementService {
    constructor(private api: ProgramManagementApi) {
        super();
    }
}
