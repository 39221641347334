import {Observable} from 'rxjs';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {ErrorInfo} from '../../error-handling/engin-error-handler.service';
import {APIResponse, SimpleMultiSeries, SimpleSeries} from '@core/interfaces/system/system-common';
import {Workflow} from './workflow';
import {DisplaySettings} from '../common/pages';
import {DownloadCSVUrl} from '@core/interfaces/engin/workflow-validation';

// (base) Request
export class AnalyzerRequest {
    pageId: string;
    tabId: string;
    filterList: {
        fieldPhysical: string;
        comparisonType: string;
        options: [BooleanSet];
    }[];
    groupList: {
        fieldPhysical: string;
        selected: boolean;
    }[];
    sensitivityList: {
        code: string;
        fieldPhysical: string;
        selected: boolean;
    }[];
    inputList: {
        namePhysical: string;
        dataType: string;
        value: number;
    }[];
    graphList: {
        graphId: string;
        unit: string;
    }[];

    constructor(filterList: any, groupList: any, sensitivityList: any, inputList: any) {
        this.pageId = '';
        this.tabId = '';
        this.filterList = filterList;
        this.groupList = groupList;
        this.sensitivityList = sensitivityList;
        this.inputList = inputList;
        this.graphList = null;
    }
}

export class AnalyzerAcaRequest extends AnalyzerRequest {
    timeInterval: string;
}

export class AnalyzerRiskForecastRequest extends AnalyzerRequest {
    probabilityType: ProbabilityTypeEnum;
    investmentType: InvestmentTypeEnum;
    prioritizeBy?: PrioritizeByEnum;
    investmentPercent?: number;
}

export class AbstractRiskRequest extends AnalyzerRequest {
    riskType: RiskTypeEnum[];
    startYear: number;
    endYear: number;
}

export class RiskCapitalRequest extends AbstractRiskRequest {}

export class RiskAggregateRequest extends AbstractRiskRequest {}

export enum ProbabilityTypeEnum {
    PDF = 'PDF',
    HR = 'HR',
}
export const ProbabilityTypeLabel = {
    [ProbabilityTypeEnum.PDF]: 'PDF',
    [ProbabilityTypeEnum.HR]: 'HR',
};

export enum InvestmentTypeEnum {
    RunToFailure = 'RUN_TO_FAILURE',
    SetInvestment = 'SET_INVESTMENT',
}
export const InvestmentTypeLabel = {
    [InvestmentTypeEnum.RunToFailure]: 'Run To Failure',
    [InvestmentTypeEnum.SetInvestment]: 'Set Investment',
};

export enum PrioritizeByEnum {
    None = '',
    Economic = 'ECONOMIC',
    Health = 'HEALTH',
    Age = 'AGE',
}
export const PrioritizeByLabel = {
    [PrioritizeByEnum.None]: 'None',
    [PrioritizeByEnum.Economic]: 'Economic',
    [PrioritizeByEnum.Health]: 'Health',
    [PrioritizeByEnum.Age]: 'Calendar Age',
};

export enum RiskTypeEnum {
    All = 'All',
    Financial = 'Financial',
    Customer = 'Customer',
    Environment = 'Environment',
    Collateral = 'Collateral',
}
export const RiskTypeLabel = {
    [RiskTypeEnum.All]: 'All',
    [RiskTypeEnum.Financial]: 'Financial',
    [RiskTypeEnum.Customer]: 'Customer',
    [RiskTypeEnum.Environment]: 'Environment',
    [RiskTypeEnum.Collateral]: 'Collateral',
};

// Response with error handling
export interface AnalyzerResponseWrapper {
    successFlag: boolean;
    error: ErrorInfo;
    response: AnalyzerResponse[];
}

export interface AnalyzerResponse {
    pageId: string;
    title: string;
    tabId: string;
    graphId: string;
    labels: string[];
    unit: Unit;
    data: any;
    legend: string[];
    highlight: boolean[];
}

export interface BooleanSet {
    [code: string]: boolean;
}

export interface Unit {
    prefix: string[];
    suffix: string[];
    symbol: string[];
}

export interface DataAvailability {
    fieldPhysical: string;
    fieldName: string;
    valuePhysical: string;
    valueName: string;
    count: number;
    available: number;
    valid: number;
    children: DataAvailability[];
}

export interface DataGapOptions {
    hiClassList: string[];
    hiSubclassList: string[];
    degFactorList: string[];
}

export abstract class AnalyzerService {
    // EOL Metrics, Demographics
    abstract getAnalyzerDataAbstract(
        req: AnalyzerRequest,
        studyId: string,
    ): Observable<APIResponse<AnalyzerResponse[]>>;

    // ACA
    abstract getHealthHistorySummary(
        req: AnalyzerRequest,
        workflowItemId: number,
        groupBy: string,
    ): Observable<APIResponse<SimpleMultiSeries<string>>>;

    abstract getHealthHistoryBreakdown(
        req: AnalyzerRequest,
        workflowItemId: number,
        groupBy: string,
    ): Observable<APIResponse<SimpleMultiSeries<string>>>;

    abstract getCurrentDataQuality(
        req: AnalyzerRequest,
        workflowItemId: number,
    ): Observable<APIResponse<SimpleMultiSeries<string>>>;

    abstract getDataGapChart(workflowItemId: number): Observable<APIResponse<SimpleSeries<string, number>[]>>;

    abstract getDataGapList(workflowItemId: number): Observable<DataSource>;

    abstract getDataGapListCsv(workflowItemId: number): Observable<APIResponse<DownloadCSVUrl>>;

    abstract getDataGapFilterOptions(workflowItemId: number): Observable<APIResponse<DataGapOptions>>;
    // Risk Profile
    abstract getRiskForecastByCategory(
        req: AnalyzerRiskForecastRequest,
        workflowItemId: string,
    ): Observable<APIResponse<SimpleMultiSeries<string>>>;

    abstract getAggregatedRiskByGrouping(
        req: RiskAggregateRequest,
        workflowItemId: string,
    ): Observable<APIResponse<SimpleMultiSeries<string>>>;

    abstract getRiskVsInvestmentRatio(
        req: RiskCapitalRequest,
        workflowItemId: string,
    ): Observable<APIResponse<SimpleSeries<string, number>>>;

    // Reporting
    abstract getAssetClassList(studyId: string): Observable<string[]>;

    // Demo - APM
    abstract getAnalyzerDataAbstractMockSystem(studyId: string): Observable<APIResponse<AnalyzerResponse[]>>;

    abstract getAnalyzerDataAbstractMockStation(studyId: string): Observable<APIResponse<AnalyzerResponse[]>>;

    // Helpers
    public getActiveStudyId(popoutValue: AnalyzerRequest, activeCollection: Workflow): string {
        const activeStudy = popoutValue.sensitivityList.filter((item) => item.selected)[0];
        return activeStudy ? activeStudy.fieldPhysical : activeCollection.studies[0].id.toString();
    }

    public prepareAnalyzerRequest(
        popoutValue: AnalyzerRequest,
        displaySettings: DisplaySettings,
        activeCollection: Workflow,
    ): AnalyzerRequest {
        const req = popoutValue;
        // Update the sensitivityList with proper sensitivityList items
        req.sensitivityList = req.sensitivityList.map((item) => {
            const relatedStudy = activeCollection.studies.filter((study) => study.sensitivityCode === item.code);
            if (relatedStudy && relatedStudy[0] && relatedStudy[0].id) {
                return {
                    ...item,
                    fieldPhysical: relatedStudy[0].id.toString(),
                };
            } else {
                return {
                    ...item,
                    fieldPhysical: activeCollection.studies[0].id.toString(),
                };
            }
        });
        // Add in page, tab, and graphList information
        req.pageId = displaySettings.pageId;
        req.tabId = displaySettings.tabId;
        req.graphList = displaySettings.graphList;
        return req;
    }
}
