import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpService} from './http.service';
import {
    AnalyzerPopoutSettings,
    ForecasterPopoutSettings,
    OptimizerPopoutSettings,
    DataAuditPopoutSettings,
} from '../../../interfaces/common/popout';
import {APIResponse} from '../../../interfaces/system/system-common';

@Injectable()
export class PopoutApi {
    private readonly apiController: string = 'popoutSettings';

    constructor(private api: HttpService) {}

    getAnalyzerPopoutSettings(): Observable<AnalyzerPopoutSettings> {
        return this.api.get(`${this.apiController}/get/analyzer`);
    }

    getOptimizerPopoutSettings(studyId: string, panelConfig: number): Observable<APIResponse<OptimizerPopoutSettings>> {
        return this.api.get(`${this.apiController}/get/optimizer/${studyId}/${panelConfig}`);
    }

    getForecasterPopoutSettings(): Observable<ForecasterPopoutSettings> {
        return this.api.get(`${this.apiController}/get/forecaster`);
    }

    getDataAuditToolPopoutSettings(): Observable<DataAuditPopoutSettings> {
        return this.api.get(`${this.apiController}/dataAudit`);
    }
}
