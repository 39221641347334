import {Component, Input} from '@angular/core';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'ngx-chart-bar-demographics',
    templateUrl: './demographics-bar-chart.component.html',
    styleUrls: ['./demographics-bar-chart.component.scss'],
})
export class DemographicsBarChartComponent {
    @Input() chartData;
    @Input() reportFile = false;
    @Input() interactive = true;
}
