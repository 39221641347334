import {Observable} from 'rxjs';
import {DatasetType} from './dataset';
import {APIResponse, SimpleUnit} from '../system/system-common';

/*
 * Response DTOs
 */
export interface DatasetItem {
    code: DatasetType;
    name: string;
}

export interface WorkflowConfig {
    code: string;
    name: string;
    datasetList: DatasetList[];
}

export interface TenantUnitsConfig {
    currency: SimpleUnit;
    count: SimpleUnit;
    length: SimpleUnit;
}

/*
 * Supporting DTOs
 */
export interface DatasetList {
    code: string;
    name: string;
    required: boolean;
}

export interface TenantUnits {
    currency: SimpleUnit;
    count: SimpleUnit;
    length: SimpleUnit;
    graphUnits: GraphUnitConfig[];
}

export interface GraphUnitConfig {
    value: string;
    label: string;
}

export interface AssetClassConfig {
    assetClass: string;
    physicalCode: string;
    assetClassCode: string;
}

export abstract class ConfigurationService {
    abstract getWorkFlows(): Observable<APIResponse<WorkflowConfig[]>>;

    abstract getDatasets(): Observable<APIResponse<DatasetItem[]>>;

    abstract getTablesFromSchema(schemaName: string): Observable<APIResponse<string[]>>;

    abstract getAssetClassCodes(): Observable<APIResponse<AssetClassConfig[]>>;

    abstract getTenantUnits(): Observable<APIResponse<TenantUnitsConfig>>;
}
