import {Injectable} from '@angular/core';
import {
    OptimizerPopoutSettings,
    OptimizerSliderSettings,
    PopoutService,
    SliderSettingsBase,
} from '../../@core/interfaces/common/popout';
import {FormArray, FormBuilder, FormControl} from '@angular/forms';
import {BehaviorSubject, combineLatest, Observable, Subscription} from 'rxjs';
import {debounceTime, filter, map, shareReplay, startWith, switchMap, takeUntil, tap} from 'rxjs/operators';
import {OptimizerAnalysisRequest} from '../../@core/interfaces/engin/optimizer';
import {StudiesStore} from '../common/studies.store';
import {PagesStore} from '../config/pages.store';
import {Unsubscribable} from '../../@core/interfaces/unsubscribable';
import {APIResponse} from '../../@core/interfaces/system/system-common';
import {of} from 'rxjs/internal/observable/of';
import {APIException} from '../../@core/error-handling/exception.dto';
import {EnginErrorHandlerService} from '../../@core/error-handling/engin-error-handler.service';
import {UsageAnalyticsService} from '@core/utils/usage-analytics.service';

export class StateChanged {
    value: boolean;
    events: boolean;
}

@Injectable()
export class OptimizerPopoutStore extends Unsubscribable {
    public popoutFormGroup = this.fb.group({
        customPeriodType: this.fb.control(false),
        applySystemDropdown: this.fb.control(true),
        constrained: this.fb.control(false),
        selectedYear: this.fb.control(0),
        selectedDropdownIndex: this.fb.control(1),
        temporaryDropdownIndex: this.fb.control(1),
    });

    private controlPanelCollapsed = new BehaviorSubject<boolean>(true);
    readonly controlPanelCollapsed$: Observable<boolean> = this.controlPanelCollapsed.asObservable();

    readonly currentYear = new Date().getFullYear();
    readonly customPeriodType$: Observable<boolean> = this.customPeriodTypeFormControl.valueChanges;

    readonly selectedYear$: Observable<number> = this.selectedYearFormControl.valueChanges;
    readonly yearsArray: number[] = new Array(10).fill(0).map((yearCell, index) => this.currentYear + index);

    private selectedDropdownIndexSubscription: Subscription;
    private selectedDropdownSlidersSubscription: Subscription;
    private selectedYearSubscription: Subscription;
    private customPeriodTypeSubscription: Subscription;
    private applySystemDropdownSubscription: Subscription;

    readonly stateChanged: BehaviorSubject<StateChanged> = new BehaviorSubject<StateChanged>({
        value: true,
        events: true,
    }); // True for first load of page
    private dropdownsList = new BehaviorSubject<any[]>([]);
    public selectedDropdownFormGroup = this.fb.group({});
    /*
     * 0: disabled
     * 1: optimization
     * 2: levelization
     * 3: prioritization
     */
    private popoutConfiguration: BehaviorSubject<number> = new BehaviorSubject<number>(null);
    public popoutConfiguration$: Observable<number> = this.popoutConfiguration.asObservable();

    protected serverPopout = new BehaviorSubject<any[]>(null);
    readonly serverPopout$: Observable<OptimizerAnalysisRequest> = combineLatest<Observable<any>, Observable<number>>([
        this.serverPopout.asObservable(),
        this.popoutConfiguration$,
    ]).pipe(
        filter(([serverFilter, config]) => serverFilter !== null),
        map(([serverFilter, longtermPlanningMethod]: [any, number]) => {
            const isConstrained = this.constrainedFormControl.value;
            const applySystemDropdown = this.applySystemDropdownFormControl.value;
            const slidersConfig = serverFilter.map((dropdown) => {
                return {
                    dropdown: dropdown.id,
                    sliders: dropdown.sliders.map((slider) => {
                        return {
                            slider: slider.id,
                            values: slider.values.map((value) => {
                                return value;
                            }),
                        };
                    }),
                };
            });

            return new OptimizerAnalysisRequest(
                isConstrained,
                applySystemDropdown,
                true,
                slidersConfig,
                longtermPlanningMethod,
            );
        }),
        shareReplay({
            bufferSize: 1,
            refCount: true,
        }),
    );

    readonly constrained$: Observable<boolean> = this.constrainedFormControl.valueChanges.pipe(
        shareReplay({
            bufferSize: 1,
            refCount: false,
        }),
    );
    readonly applySystemDropdown$: Observable<boolean> = this.applySystemDropdownFormControl.valueChanges.pipe(
        shareReplay({
            bufferSize: 1,
            refCount: true,
        }),
    );

    /*
    protected getPopoutSettings(): Observable<OptimizerPopoutSettings> {
      return combineLatest<Observable<string>, Observable<number>>([
        this.studiesStore.activeStudyId$, this.popoutConfiguration$,
      ]).pipe(switchMap(( [studyId, popoutConfiguration]: [string, number]) => {
        if (studyId !== null) return this.popoutData.getOptimizerPopoutSettings(studyId, popoutConfiguration);
        else return [];
      }),
        share());
    }
     */

    protected initSelectedDropdownFormGroup(index: number) {
        const selectedDropdownValue = this.dropdownsList.value[index];
        this.selectedDropdownFormGroup = this.fb.group({
            id: this.fb.control(selectedDropdownValue.id),
            description: this.fb.control(selectedDropdownValue.description),
            dirtyYears: this.fb.array([]),
            sliders: this.fb.array(
                selectedDropdownValue.sliders.map((slider) => {
                    return this.fb.group({
                        id: this.fb.control(slider.id),
                        description: this.fb.control(slider.description),
                        prefix: this.fb.control(slider.prefix),
                        suffix: this.fb.control(slider.suffix),
                        max: this.fb.control(slider.max),
                        min: this.fb.control(slider.min),
                        value: this.fb.control(slider.value),
                        various: 'various',
                        values: this.fb.array(slider.values),
                    });
                }),
            ),
            backlog: selectedDropdownValue.backlog ? this.fb.array(selectedDropdownValue.backlog) : this.fb.array([]),
        });
    }

    protected initDropdownsList() {
        const initialDropdownsList = this.popoutSettings.value.dropdowns.map((dropdown) => {
            return {
                id: dropdown.id,
                description: dropdown.description,
                dirtyYears: [],
                sliders: dropdown.sliders.map((slider) => {
                    return {
                        id: slider.id,
                        description: slider.description,
                        prefix: slider.prefix,
                        suffix: slider.suffix,
                        max: slider.max,
                        min: slider.min,
                        value: slider.value,
                        values: this.popoutSettings.value.years.map(() => slider.value),
                    };
                }),
                backlog: dropdown.backlog,
            };
        });
        this.dropdownsList.next(initialDropdownsList);
        this.serverPopout.next(initialDropdownsList);
    }

    public popoutSettings = new BehaviorSubject<OptimizerPopoutSettings>(null);
    readonly popoutSettings$: Observable<OptimizerPopoutSettings> = this.popoutSettings.asObservable().pipe(
        filter((settings) => settings != null),
        tap((settings) => {
            this.selectedDropdownIndexSubscription && this.selectedDropdownIndexSubscription.unsubscribe();
            this.selectedDropdownSlidersSubscription && this.selectedDropdownSlidersSubscription.unsubscribe();
            this.selectedYearSubscription && this.selectedYearSubscription.unsubscribe();
            this.customPeriodTypeSubscription && this.customPeriodTypeSubscription.unsubscribe();
            this.applySystemDropdownSubscription && this.applySystemDropdownSubscription.unsubscribe();
            this.initDropdownsList();
            this.subscribeDropdownOnChange();
        }),
        shareReplay({
            bufferSize: 1,
            refCount: true,
        }),
    );

    public loadPopoutSettings() {
        // Load Optimizer popout configuration once by looking into global page settings
        this.pagesStore.optimizerControlPanelConfig$
            .pipe(
                map((config: number) => {
                    const defaultConfiguration = 0;
                    const finalConfig = config === -1 ? defaultConfiguration : config;
                    return finalConfig;
                }),
                takeUntil(this.unsubscribe$),
            )
            .subscribe((config: number) => {
                this.popoutConfiguration.next(config);
            });

        // Load Optimizer panel data from API each time the underlying data sources change
        combineLatest<Observable<string>, Observable<number>>([
            this.studiesStore.activeStudyId$,
            this.popoutConfiguration$,
        ])
            .pipe(
                switchMap(([studyId, popoutConfiguration]: [string, number]) => {
                    if (studyId !== null && popoutConfiguration > 0) {
                        return this.popoutData.getOptimizerPopoutSettings(studyId, popoutConfiguration);
                    } else {
                        return [];
                    }
                }),
                takeUntil(this.unsubscribe$),
            )
            .subscribe((res: APIResponse<OptimizerPopoutSettings | APIException>) => {
                this.stateChanged.next({value: true, events: true});
                if (res.status === 200) {
                    const settings = res.response as OptimizerPopoutSettings;
                    const initPopoutSettings = {
                        ...settings,
                        years: this.yearsArray,
                    };
                    this.popoutSettings.next(initPopoutSettings);
                } else {
                    // Else handle exception
                    const exception = res.response as APIException;
                    this.errorService.handleCustomException(exception);
                    return of([]);
                }
            });
    }

    /*
    protected popoutSettings = new BehaviorSubject<PopoutSettingsBase>(null);
    readonly popoutSettings$: Observable<PopoutSettingsBase> = this.getPopoutSettings().pipe(
      map(settings => {
        // Same data structure should work for any configuration
        return {
          ...settings,
          years: this.yearsArray,
        };
      }),
      tap(settings => {
        this.popoutSettings.next(settings);
      }),
      tap(() => this.initDropdownsList()),
      tap(() => this.subscribeDropdownOnChange()),
      shareReplay(1),
    );
     */

    private selectedDropdownSliders = new BehaviorSubject<OptimizerSliderSettings>(null);
    readonly selectedDropdownSliders$ = this.selectedDropdownSliders.asObservable().pipe(
        filter((sliders) => {
            return sliders !== null;
        }),
    );

    constructor(
        protected popoutData: PopoutService,
        protected fb: FormBuilder,
        protected studiesStore: StudiesStore,
        private pagesStore: PagesStore,
        private errorService: EnginErrorHandlerService,
        private usageAnalyticsService: UsageAnalyticsService,
    ) {
        super();
    }

    get selectedDropdownDirtyYearsFormArrayValue(): number[] {
        const dirtyYears = this.selectedDropdownFormGroup.get('dirtyYears') as FormArray;
        return dirtyYears ? dirtyYears.value : [];
    }

    public get selectedDropdownIndexFormControl(): FormControl {
        return this.popoutFormGroup.get('selectedDropdownIndex') as FormControl;
    }

    private get customPeriodTypeFormControl(): FormControl {
        return this.popoutFormGroup.get('customPeriodType') as FormControl;
    }

    private get selectedYearFormControl(): FormControl {
        return this.popoutFormGroup.get('selectedYear') as FormControl;
    }

    private get temporaryDropdownIndexFormControl(): FormControl {
        return this.popoutFormGroup.get('temporaryDropdownIndex') as FormControl;
    }

    private get selectedDropdownDirtyYearsFormArray(): FormArray {
        return this.selectedDropdownFormGroup.get('dirtyYears') as FormArray;
    }

    private get selectedDropdownSlidersFormArray(): FormArray {
        return this.selectedDropdownFormGroup.get('sliders') as FormArray;
    }

    private get constrainedFormControl(): FormControl {
        return this.popoutFormGroup.get('constrained') as FormControl;
    }

    private get applySystemDropdownFormControl(): FormControl {
        return this.popoutFormGroup.get('applySystemDropdown') as FormControl;
    }

    public updateServerPopout() {
        this.serverPopout.next(this.dropdownsList.value);
        this.usageAnalyticsService.logView('Optimizer Control Panel');
        // this.stateChanged.next(false); // Reset to false in optimizer.store.ts when recalculating API
    }

    public destroy() {
        this.selectedDropdownIndexSubscription && this.selectedDropdownIndexSubscription.unsubscribe();
        this.selectedDropdownSlidersSubscription && this.selectedDropdownSlidersSubscription.unsubscribe();
        this.selectedYearSubscription && this.selectedYearSubscription.unsubscribe();
        this.customPeriodTypeSubscription && this.customPeriodTypeSubscription.unsubscribe();
        this.applySystemDropdownSubscription && this.applySystemDropdownSubscription.unsubscribe();
    }

    public resetAllYears() {
        this.customPeriodTypeFormControl.setValue(false);
    }

    get slidersArray() {
        return this.selectedDropdownFormGroup.get('sliders') as FormArray;
    }

    protected subscribeDropdownOnChange() {
        // Change of dropdown list, change of state is not necessarily true
        this.selectedDropdownIndexSubscription = this.selectedDropdownIndexFormControl.valueChanges
            .pipe(startWith(1))
            .subscribe((index) => {
                if (Object.keys(this.selectedDropdownFormGroup.value).length > 0) {
                    const newDropdownSelected = this.dropdownsList.value[index - 1];
                    this.selectedDropdownDirtyYearsFormArray.clear();
                    newDropdownSelected.dirtyYears.forEach((dirtyYear) => {
                        this.selectedDropdownDirtyYearsFormArray.push(this.fb.control(dirtyYear));
                    });
                    // Manually reset form array controls to allow for change in number of sliders per dropdown
                    const newLength = newDropdownSelected.sliders.length;
                    this.slidersArray.clear();
                    for (let i = 0; i < newLength; i++) {
                        this.slidersArray.push(
                            this.fb.group({
                                id: this.fb.control(newDropdownSelected.sliders[i].id),
                                description: this.fb.control(newDropdownSelected.sliders[i].description),
                                prefix: this.fb.control(newDropdownSelected.sliders[i].prefix),
                                suffix: this.fb.control(newDropdownSelected.sliders[i].suffix),
                                max: this.fb.control(newDropdownSelected.sliders[i].max),
                                min: this.fb.control(newDropdownSelected.sliders[i].min),
                                value: this.fb.control(newDropdownSelected.sliders[i].value),
                                values: this.fb.array(newDropdownSelected.sliders[i].values),
                            }),
                        );
                    }
                } else {
                    this.initSelectedDropdownFormGroup(0);
                }
                this.temporaryDropdownIndexFormControl.setValue(index);
                this.selectedDropdownSliders.next(this.selectedDropdownSlidersFormArray.value);
            });

        // Change of slider, change of state is true
        this.selectedDropdownSlidersSubscription = this.selectedDropdownSlidersFormArray.valueChanges
            .pipe(
                filter((dropdownSliders) => dropdownSliders.length > 0),
                debounceTime(100), // for reduce changes of popout when moving sliders
            )
            .subscribe((dropdownSliders: SliderSettingsBase[]) => {
                this.stateChanged.next({value: true, events: true});
                const selectedYear = this.selectedYearFormControl.value;
                if (this.customPeriodTypeFormControl.value && selectedYear !== 0) {
                    dropdownSliders = dropdownSliders.map((slider) => {
                        return {
                            ...slider,
                            values: slider.values.map((value, index) => {
                                return this.yearsArray.indexOf(selectedYear) === index ? slider.value : value;
                            }),
                        };
                    });
                    if (this.selectedDropdownDirtyYearsFormArray.value.indexOf(selectedYear) < 0) {
                        this.selectedDropdownDirtyYearsFormArray.push(this.fb.control(selectedYear));
                    }
                } else {
                    dropdownSliders = dropdownSliders.map((slider) => {
                        return {
                            ...slider,
                            values: slider.values.map(() => slider.value),
                        };
                    });
                }
                dropdownSliders.forEach((slider, index) => {
                    this.selectedDropdownSlidersFormArray
                        .at(index)
                        .patchValue({values: slider.values}, {emitEvent: false, onlySelf: true});
                });
                this.updateDropdownsList(this.temporaryDropdownIndexFormControl.value - 1, dropdownSliders);
            });

        // Change of selected year, change of state is not necessarily true
        this.selectedYearSubscription = this.selectedYear$
            .pipe(
                filter((year) => year > 0),
                map((year) => this.yearsArray.indexOf(year)),
            )
            .subscribe((yearIndex) => {
                this.selectedDropdownSlidersFormArray.value.forEach((slider, index) => {
                    this.selectedDropdownSlidersFormArray
                        .at(index)
                        .patchValue({value: slider.values[yearIndex]}, {emitEvent: false, onlySelf: true});
                });
            });

        // Change of method for selecting years, popout resets, so change of state is true
        this.customPeriodTypeSubscription = this.customPeriodType$
            .pipe(filter((customPeriod) => !customPeriod))
            .subscribe(() => {
                this.stateChanged.next({value: true, events: true});
                this.resetPopout();
            });

        // change to apply system settings, change of state is true
        this.applySystemDropdownSubscription = this.applySystemDropdown$.pipe().subscribe(() => {
            this.stateChanged.next({value: true, events: true});
            // this.resetPopout();
        });
    }

    private updateDropdownsList(index: number, value: SliderSettingsBase[]) {
        const dropdownsList = this.dropdownsList.value;
        dropdownsList[index].sliders = value;
        dropdownsList[index].dirtyYears = this.selectedDropdownDirtyYearsFormArray.value;
        this.dropdownsList.next(dropdownsList);
    }

    private resetPopout() {
        this.initDropdownsList();
        this.selectedYearFormControl.setValue(0);
        this.selectedDropdownIndexFormControl.setValue(1);
        this.customPeriodTypeFormControl.setValue(false, {emitEvent: false});
    }

    public toggleControlPanelCollapsed(collapsed: boolean) {
        if (collapsed != null) {
            this.controlPanelCollapsed.next(collapsed);
        }
    }
}
