<nb-card class="kpi-chart-card" [class.wide]="wide">
    <nb-card-header class="chart-card-header">
        <span class="primary-header">{{ data.title }}</span>
        <span class="sub-header">{{ data.subtitle }}</span>
    </nb-card-header>

    <nb-card-body>
        <div class="kpi-bar">
            <div [ngClass]="deltaColor" class="kpi-bar-part bg" [ngStyle]="{'height.%': data.deltaPercent}"></div>
            <div
                class="kpi-bar-part color-gradient-category-1 bg"
                [ngStyle]="{'height.%': 100 - data.deltaPercent}"
            ></div>
        </div>

        <nb-icon
            *ngIf="wide"
            icon="arrow-down"
            pack="engin"
            status="basic"
            [class.arrow-up]="data.kpiDirection === KpiOptions.Increasing"
        ></nb-icon>

        <div class="kpi-values">
            <div [ngClass]="deltaColor" class="kpi-values-title color">
                Delta: {{ data.kpiDirection === KpiOptions.Decreasing ? "-" : "" }}{{ data.deltaPercent }}%
            </div>
            <div class="kpi-values-subtitle">{{ data.deltaValue }}</div>
            <ng-container [ngSwitch]="data.kpiDirection === KpiOptions.Increasing">
                <div class="kpi-values-info" *ngSwitchCase="true">Initial: {{ data.baseValue }}</div>
                <div class="kpi-values-info" *ngSwitchCase="false">Residual: {{ data.residualValue }}</div>
            </ng-container>
        </div>
    </nb-card-body>
</nb-card>
