<div class="section" *ngFor="let section of pageGroups$ | async">
    <ng-container *ngIf="section.id">
        <div class="section-header">
            <ngx-icon-box
                [ngClass]="(colors | async)?.[section.id]"
                [title]="section.title"
                [iconDetails]="section.icon"
            ></ngx-icon-box>

            <ng-container *ngIf="section.id === 'modules'">
                <ngx-user-notification
                    *ngIf="userNotifications$ | async as messageList"
                    class="user-notification"
                    [messageList]="messageList"
                >
                </ngx-user-notification>
            </ng-container>
        </div>

        <!-- While waiting for dashboard data, display cards with empty body -->
        <div class="section-items-container">
            <ngx-dashboard-card
                *ngFor="let child of section.children"
                [class.disabled-link]="!child.enabled"
                [routerLink]="child.link"
                [icon]="child.icon"
                [iconColor]="(colors | async)?.[section.id]"
                [title]="child.title"
                [subtitle]="child.subTitle"
                [displayAction]="child.displayAction"
                [body]="getDashboardBody(child.id, child.body)"
            ></ngx-dashboard-card>
        </div>
    </ng-container>
</div>
