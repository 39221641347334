<ng-container *ngFor="let message of messageList; let i = index">
    <ng-container *ngIf="message.open">
        <div
            [ngClass]="message.type + '-message'"
            [ngStyle]="{
                'margin-top': i > 0 ? '10px' : '0',
                'pointer-events': clickEvent ? 'auto' : 'none',
                height: height ? height : '40px'
            }"
            (click)="messageAction(message)"
        >
            <ngx-icon-box [icon]="getIcon(message.type)" [ngClass]="message.type + '-icon'"> </ngx-icon-box>
            <span [ngClass]="message.type + '-label'">{{ message.message }}</span>
        </div>
    </ng-container>
</ng-container>
