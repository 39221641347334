import {Injectable} from '@angular/core';
import {Unsubscribable} from '@core/interfaces/unsubscribable';
import {BehaviorSubject, Observable} from 'rxjs';
import {
    ConfigurationService,
    DatasetItem,
    GraphUnitConfig,
    TenantUnits,
    TenantUnitsConfig,
} from '@core/interfaces/common/configuration';
import {map, takeUntil} from 'rxjs/operators';
import {APIResponse} from '@core/interfaces/system/system-common';
import {switchMap} from 'rxjs/internal/operators/switchMap';
import {UsersService} from '@core/interfaces/common/users';

@Injectable()
export class ConfigurationStore extends Unsubscribable {
    // System defaults to CAD and metric (m)
    private defaultUnits: TenantUnitsConfig = {
        currency: {
            prefix: '$',
            suffix: '',
            symbol: 'CAD',
        },
        count: {
            prefix: '',
            suffix: '',
            symbol: '#',
        },
        length: {
            prefix: '',
            suffix: 'm',
            symbol: 'km',
        },
    };

    private units: BehaviorSubject<TenantUnits> = new BehaviorSubject<TenantUnits>(null);
    readonly units$: Observable<TenantUnits> = this.units.asObservable();

    constructor(private configurationService: ConfigurationService, private userService: UsersService) {
        super();
        this.userService
            .getCurrentUser()
            .pipe(
                takeUntil(this.unsubscribe$),
                switchMap((_) => {
                    return this.configurationService.getTenantUnits();
                }),
            )
            .subscribe((res: APIResponse<TenantUnits>) => {
                let config: TenantUnitsConfig = this.defaultUnits;
                if (res.status === 200) {
                    config = res.response;
                }

                const graphUnits: GraphUnitConfig[] = [];

                // Create consistent list of graph unit options from tenant units configuration
                graphUnits.push({value: 'count-length-phase', label: `# (${config.length.symbol})`});
                graphUnits.push({value: 'currency', label: config.currency.symbol});
                graphUnits.push({value: 'count', label: config.count.symbol});

                this.units.next({
                    ...config,
                    graphUnits: graphUnits,
                });
            });
    }

    readonly datasetList$: Observable<DatasetItem[]> = this.configurationService
        .getDatasets()
        .pipe(map((res) => res.response));
}
