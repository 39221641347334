import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ReportType} from '../../../pages/reporting-tool/model/report-type';
import {Workflow, WorkflowService} from '@core/interfaces/engin/workflow';
import {TimeLocalizePipe} from '../../pipes';
import {BehaviorSubject, Observable} from 'rxjs';
import {filter, map, takeUntil} from 'rxjs/operators';
import {Unsubscribable} from '@core/interfaces/unsubscribable';
import {switchMap} from 'rxjs/internal/operators/switchMap';
import {APIResponse, PagingResponse} from '@core/interfaces/system/system-common';

@Component({
    selector: 'ngx-workflow-selector',
    templateUrl: './workflow-selector.component.html',
    styleUrls: ['./workflow-selector.component.scss'],
    providers: [TimeLocalizePipe],
})
export class WorkflowSelectorComponent extends Unsubscribable implements OnInit {
    // Component inputs
    @Input() selectedType: ReportType;
    @Input() title: string;
    @Input() dataReady: boolean;
    // @Input() workflowList: Workflow[];
    // Component outputs/events
    @Output() workflowItemChange: EventEmitter<number> = new EventEmitter<number>();

    // Component internal data
    activeWorkflow: Workflow;
    private activeWorkflowId: BehaviorSubject<number> = new BehaviorSubject<number>(null);
    workflowItemId: number;
    multipleStudyList: boolean;

    constructor(private timeLocalizePipe: TimeLocalizePipe, private workflowService: WorkflowService) {
        super();
    }

    ngOnInit() {
        this.activeWorkflowId
            .asObservable()
            .pipe(
                takeUntil(this.unsubscribe$),
                filter((d) => d && !!d),
                switchMap((workflowId: number) => {
                    return this.workflowService.getWorkflowById(workflowId);
                }),
            )
            .subscribe((workflow: APIResponse<Workflow>) => {
                this.activeWorkflow = workflow.response as Workflow;
                if (this.activeWorkflow.studies.length === 1) {
                    this.workflowItemId =
                        this.activeWorkflow.studies.filter((s) => s.defaultItem)[0].id ||
                        this.activeWorkflow.studies[0].id;
                    this.workflowItemChange.emit(this.workflowItemId);
                    this.multipleStudyList = false;
                } else {
                    this.multipleStudyList = true;
                }
            });
    }

    readonly workflows$: Observable<Workflow[]> = this.workflowService.listLatestWorkflows(50, 'study').pipe(
        takeUntil(this.unsubscribe$),
        map((resp: PagingResponse<Workflow>) => {
            const workflows = resp.items as Workflow[];
            return workflows.filter((w) => w.status === 'SUCCESS');
        }),
    );

    onWorkflowChange(workflowId) {
        this.workflowItemId = 0;
        this.activeWorkflowId.next(workflowId);
    }

    onWorkflowItemSelected(workflowItemId: number) {
        this.workflowItemId = workflowItemId;
        if (this.multipleStudyList) {
            this.workflowItemChange.emit(this.workflowItemId);
        }
    }

    public getWorkflowName(w: Workflow): string {
        return `Snapshot V${w.snapshotVersion}: ${this.timeLocalizePipe.transform(w.createdOn)}`;
    }
}
