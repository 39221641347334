import {Injectable} from '@angular/core';

import {BehaviorSubject, Observable, of, Subject} from 'rxjs';

import {DecimalPipe} from '@angular/common';
import {debounceTime, delay, switchMap, tap} from 'rxjs/operators';
import {SortDirection} from '@swimlane/ngx-datatable';
import {SortColumn} from './ngx-sc-table-directive/ngx-sc-sortable.directive';
import {NgxScTableFunction} from './ngx-sc-table-function';
import {SearchResult, State} from './pojo/ngx-sc-pojo';

@Injectable({providedIn: 'root'})
export class NgxScTableStore {
    _dataset_original = new BehaviorSubject<any[]>([]);
    _workingDataset = new BehaviorSubject<any[]>([]);
    _patchData = new BehaviorSubject<any>([]);
    patchFunction: any;

    private _loading$ = new BehaviorSubject<boolean>(true);
    private _search$ = new Subject<void>();
    private _dataset$ = new BehaviorSubject<any[]>([]);
    private _total$ = new BehaviorSubject<number>(0);

    private _state: State = {
        pagesCount: 0,
        pageIndex: 1,
        perPage: 5,
        searchTerm: '',
        searchTermMap: new Map(),
        sortColumn: '',
        sortDirection: SortDirection.asc,
    };

    constructor(private pipe: DecimalPipe) {
        this._search$
            .pipe(
                tap(() => this._loading$.next(true)),
                debounceTime(200),
                switchMap(() =>
                    this._processingData(
                        this._patched_dataset(
                            this._workingDataset.getValue(),
                            this._patchData.getValue(),
                            this.patchFunction,
                        ),
                    ),
                ),
                delay(200),
                tap(() => this._loading$.next(false)),
            )
            .subscribe((result) => {
                this._dataset$.next(result.dataset);
                this._total$.next(result.total);
            });

        this._search$.next();
    }

    public propagateDataPatch(patchData) {
        this._patchData.next(patchData);
        this.triggerProcess();
    }

    // ========
    get dataset$() {
        return this._dataset$.asObservable();
    }

    get total$() {
        return this._total$.asObservable();
    }

    get loading$() {
        return this._loading$.asObservable();
    }

    // ========

    get pagesCount() {
        return this._state.pagesCount;
    }

    get pageIndex() {
        return this._state.pageIndex;
    }

    get perPage() {
        return this._state.perPage;
    }

    get searchTerm() {
        return this._state.searchTerm;
    }

    set pagesCount(pagesCount: number) {
        this._state.pagesCount = pagesCount;
    }

    set pageIndex(pageIndex: number) {
        this._set({pageIndex: pageIndex});
    }

    set perPage(perPage: number) {
        this._set({perPage: perPage});
    }

    set searchTerm(searchTerm: string) {
        this._set({searchTerm});
    }

    // ========
    get searchTermMap() {
        return this._state.searchTermMap;
    }

    set searchTermMap(searchTermMap: Map<string, string>) {
        this._set({searchTermMap});
    }

    set sortColumn(sortColumn: SortColumn) {
        this._set({sortColumn});
    }

    set sortDirection(sortDirection) {
        this._set({sortDirection});
    }

    private _set(patch: Partial<State>) {
        Object.assign(this._state, patch);
        this.triggerProcess();
    }

    triggerProcess() {
        this._search$.next();
    }

    // Call a custom (input) function which patches values to the original dataset, allowing for updated values
    private _patched_dataset(workingDataset, patchData, patchFunction) {
        if (patchFunction && patchData) {
            const newData = patchFunction(workingDataset, patchData);
            this._workingDataset.next(newData);
            return newData;
        } else return workingDataset;
    }

    private _processingData(source_dataset): Observable<SearchResult> {
        const {sortColumn, sortDirection, perPage, pageIndex, searchTerm, searchTermMap} = this._state;

        // 1. sort
        let dataset = NgxScTableFunction.sort(source_dataset, sortColumn, sortDirection);

        // 2. full text filter
        dataset = dataset.filter((dataItem) => NgxScTableFunction.matches(dataItem, searchTerm, this.pipe));

        // 3. filter by column
        dataset = dataset.filter((dataItem) => NgxScTableFunction.matchesByColumn(dataItem, searchTermMap, this.pipe));

        // 4. paginate
        const total = dataset.length;
        dataset = dataset.slice((pageIndex - 1) * perPage, (pageIndex - 1) * perPage + perPage);
        this.pagesCount = dataset.length / this.perPage + 1;
        return of({dataset: dataset, total});
    }
}
