import {Component, Input, ChangeDetectionStrategy} from '@angular/core';
import {Page} from '../../../@core/interfaces/common/pages';

@Component({
    selector: 'ngx-submenu',
    template: `
        <div class="submenu-items" *ngIf="items">
            <div
                *ngFor="let child of items"
                class="submenu-item"
                [ngClass]="{'submenu-item-disabled': !child.enabled}"
                [routerLink]="child.link"
                routerLinkActive="active"
            >
                {{ child.title | titlecase }}
            </div>
        </div>
    `,
    styleUrls: ['./menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubmenuComponent {
    @Input() items: Page[];
}
