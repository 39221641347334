<h1 id="title" class="title">Login</h1>
<p class="sub-title">Hello! Log in with your username.</p>

<nb-alert *ngIf="showMessages.error && errors?.length && !submitted" outline="danger" role="alert">
    <p class="alert-title"><b>Invalid Login!</b></p>
    <ul class="alert-message-list">
        <li *ngFor="let error of errors" class="alert-message">{{ error }}</li>
    </ul>
</nb-alert>

<nb-alert *ngIf="showMessages.success && messages?.length && !submitted" outline="success" role="alert">
    <p class="alert-title"><b>Success!</b></p>
    <ul class="alert-message-list">
        <li *ngFor="let message of messages" class="alert-message">{{ message }}</li>
    </ul>
</nb-alert>

<form (ngSubmit)="login()" #form="ngForm" aria-labelledby="title">
    <div class="form-control-group">
        <label class="label" for="input-username">Username:</label>
        <input
            nbInput
            fullWidth
            [(ngModel)]="user.username"
            #username="ngModel"
            name="username"
            id="input-username"
            pattern="[\.@\w]+"
            placeholder="Username"
            fieldSize="large"
            autofocus
            [status]="username.dirty ? (username.invalid ? 'danger' : 'success') : ''"
            [required]="true"
            [attr.aria-invalid]="username.invalid && username.touched ? true : null"
        />
        <ng-container *ngIf="username.invalid && username.touched">
            <p class="caption status-danger" *ngIf="username.errors?.required">Username is required.</p>
            <p class="caption status-danger" *ngIf="username.errors?.pattern">Invalid username.</p>
        </ng-container>
    </div>

    <div class="form-control-group">
        <span class="label-with-link">
            <label class="label" for="input-password">Password:</label>
        </span>
        <input
            nbInput
            fullWidth
            [(ngModel)]="user.password"
            #password="ngModel"
            name="password"
            type="password"
            id="input-password"
            placeholder="Password"
            fieldSize="large"
            [status]="password.dirty ? (password.invalid ? 'danger' : 'success') : ''"
            [required]="true"
            [minlength]="getConfigValue('forms.validation.password.minLength')"
            [maxlength]="getConfigValue('forms.validation.password.maxLength')"
            [attr.aria-invalid]="password.invalid && password.touched ? true : null"
        />
        <ng-container *ngIf="password.invalid && password.touched">
            <p class="caption status-danger" *ngIf="password.errors?.required">Password is required.</p>
            <p class="caption status-danger" *ngIf="password.errors?.minlength || password.errors?.maxlength">
                Password should contain from {{ getConfigValue("forms.validation.password.minLength") }} to
                {{ getConfigValue("forms.validation.password.maxLength") }}
                characters.
            </p>
        </ng-container>
    </div>

    <button
        nbButton
        fullWidth
        status="primary"
        size="large"
        [disabled]="submitted || !form.valid"
        [class.btn-pulse]="submitted"
    >
        Log In
    </button>
</form>

<section class="another-action display-flex flex-column" aria-label="Register">
    <a class="text-link" routerLink="../forgot-password">Forgot Password?</a>
</section>
