import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {APIResponse} from '../../../interfaces/system/system-common';
import {
    ConfigurationService,
    DatasetItem,
    TenantUnitsConfig,
    WorkflowConfig,
    AssetClassConfig,
} from '../../../interfaces/common/configuration';
import {ConfigurationApi} from '../api/configuration.api';

@Injectable()
export class ConfigurationServiceImpl extends ConfigurationService {
    constructor(private api: ConfigurationApi) {
        super();
    }

    getWorkFlows(): Observable<APIResponse<WorkflowConfig[]>> {
        return this.api.getWorkFlows();
    }

    getDatasets(): Observable<APIResponse<DatasetItem[]>> {
        return this.api.getDatasets();
    }

    getTablesFromSchema(schemaName: string): Observable<APIResponse<string[]>> {
        return this.api.getTablesFromSchema(schemaName);
    }

    getAssetClassCodes(): Observable<APIResponse<AssetClassConfig[]>> {
        return this.api.getAssetClassCodes();
    }

    getTenantUnits(): Observable<APIResponse<TenantUnitsConfig>> {
        return this.api.getTenantUnits();
    }
}
