import {Component, OnInit, Optional} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Unsubscribable} from '@core/interfaces/unsubscribable';
import {NbDialogRef, NbDialogService, NbToastrService} from '@nebular/theme';
import {FormatsService} from '@core/utils';
import {takeUntil} from 'rxjs/operators';
import {AboutSystemInfo, SupportService} from '@core/interfaces/common/support';
import {HelpSupportViewModel} from '@theme/components/help-support/help-support.vm';
import {BehaviorSubject} from 'rxjs';

@Component({
    selector: 'ngx-help-support-dialog',
    templateUrl: './help-support-dialog.component.html',
    styleUrls: ['./help-support-dialog.component.scss'],
    providers: [HelpSupportViewModel],
})
export class HelpSupportDialogComponent extends Unsubscribable {
    dialogTitle: String = 'Support Ticket';
    dialogSubtitle: String =
        'Submit a support ticket to the technical support team at support@engincloud.com. ' +
        'Support tickets are managed via ZenDesk.';
    dialogFieldName: String = 'Name';
    dialogFieldEmail: String = 'Contact email - where can we reach you?';
    dialogFieldContext: String = 'Context - where are you experiencing an issue?';
    dialogFieldSubject: String = 'Subject - title of support ticket';
    dialogFieldDescription: String = 'Description - what is the issue?';
    dialogValidationName: String = 'Name';
    dialogValidationEmail: String = 'Contact email';
    dialogValidationSubject: String = 'Subject';
    dialogValidationDescription: String = 'Description';
    formGroup: FormGroup;
    submitted = true;
    constructor(
        @Optional() private dialogRef: NbDialogRef<any>,
        private fb: FormBuilder,
        public vm: HelpSupportViewModel,
        private dialogService: NbDialogService,
        private toastrService: NbToastrService,
        private supportService: SupportService,
    ) {
        super();
        this.resetAll();
        this.helpDeskInfoFormGroup.valueChanges.subscribe((_) => {
            if (this.helpDeskInfoFormGroup.valid) {
                this.disableSubmitButton.next(false);
            } else {
                this.disableSubmitButton.next(true);
            }
        });
    }
    public disableSubmitButton = new BehaviorSubject(true);
    currentPage = new BehaviorSubject<string>(null);
    helpDeskInfoFormGroup: FormGroup = this.fb.group({
        // User fields
        name: this.fb.control('', Validators.required),
        email: this.fb.control('', [Validators.required, Validators.pattern(FormatsService.EMAIL_PATTERN)]),
        context: this.fb.control('', Validators.required),
        subject: this.fb.control('', [Validators.required, Validators.minLength(3), Validators.maxLength(10000)]),
        description: this.fb.control('', [Validators.required, Validators.minLength(3), Validators.maxLength(10000)]),

        // Automatic fields
        tenantId: this.fb.control(''),
        browserVersion: this.fb.control(''),
        enginVersion: this.fb.control(''),
    });
    // Reset form
    public resetAll() {
        this.helpDeskInfoFormGroup.reset();
        this.vm.aboutSystemInfo$.pipe(takeUntil(this.unsubscribe$)).subscribe((aboutSystemInfo: AboutSystemInfo) => {
            this.currentPage.next(aboutSystemInfo.getFeatureId());
            // Prepopulate name, email, and context
            this.helpDeskInfoFormGroup.setValue({
                name: aboutSystemInfo.getUserName(),
                email: aboutSystemInfo.getUserEmail() !== 'Unknown' ? aboutSystemInfo.getUserEmail() : '',
                context: aboutSystemInfo.getFeatureId(),
                subject: '',
                description: '',

                tenantId: aboutSystemInfo.getTenantId(),
                browserVersion: aboutSystemInfo.getBrowserInfo(),
                enginVersion: aboutSystemInfo.getEnginVersion(),
            });
        });
    }
    // Validate form inputs, and submit if valid
    private validateAndSubmitForm() {
        if (this.helpDeskInfoFormGroup.valid && this.helpDeskInfoFormGroup.dirty) {
            // send the data to node side.
            this.supportService
                .createTicket(this.helpDeskInfoFormGroupValue)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe((result) => {
                    if (result) {
                        this.toastrService.success(
                            'A new support ticket has been created. ' + result.id ? 'Ticket #' + result.id : '',
                            'Success',
                            {duration: 10000},
                        );
                    } else {
                        this.toastrService.danger('Failed to create support ticket.', 'Error creating support ticket', {
                            duration: 10000,
                        });
                    }
                    this.resetAll();
                });
            this.resetAll();
        } else {
            this.submitted = false;
            this.toastrService.danger(
                'Invalid inputs, could not create support ticket.',
                'Error creating support ticket',
                {
                    duration: 10000,
                },
            );
        }
    }

    save() {
        this.validateAndSubmitForm();
    }

    closeMe() {
        this.dialogRef.close();
    }
    get helpDeskInfoFormGroupValue(): FormControl {
        return this.helpDeskInfoFormGroup.value;
    }

    get contextFormControl(): FormControl {
        return this.helpDeskInfoFormGroup.get('context') as FormControl;
    }

    get nameFormControl(): FormControl {
        return this.helpDeskInfoFormGroup.get('name') as FormControl;
    }

    get emailFormControl(): FormControl {
        return this.helpDeskInfoFormGroup.get('email') as FormControl;
    }

    get subjectFormControl(): FormControl {
        if (this.helpDeskInfoFormGroup.get('subject').touched) {
            this.submitted = true;
        }
        return this.helpDeskInfoFormGroup.get('subject') as FormControl;
    }

    get descriptionFormControl(): FormControl {
        if (this.helpDeskInfoFormGroup.get('description').touched) {
            this.submitted = true;
        }
        return this.helpDeskInfoFormGroup.get('description') as FormControl;
    }
}
