import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule, DecimalPipe} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

/*
 * Nebular imports
 */
import {
    NbAccordionModule,
    NbActionsModule,
    NbAlertModule,
    NbButtonModule,
    NbCalendarKitModule,
    NbCalendarModule,
    NbCalendarRangeModule,
    NbCardModule,
    NbChatModule,
    NbCheckboxModule,
    NbContextMenuModule,
    NbDatepickerModule,
    NbDialogModule,
    NbIconModule,
    NbInputModule,
    NbLayoutModule,
    NbListModule,
    NbMenuModule,
    NbPopoverModule,
    NbProgressBarModule,
    NbRadioModule,
    NbRouteTabsetModule,
    NbSearchModule,
    NbSelectModule,
    NbSidebarModule,
    NbSpinnerModule,
    NbStepperModule,
    NbTabsetModule,
    NbThemeModule,
    NbToastrModule,
    NbTooltipModule,
    NbUserModule,
    NbWindowModule,
} from '@nebular/theme';
import {NbEvaIconsModule} from '@nebular/eva-icons';
import {NbSecurityModule} from '@nebular/security';

/*
 * Components
 */
import * as components from './components';
import {EmptyDataComponent} from './components';

/*
 * Pipes
 */
import {
    CapitalizePipe,
    GroupByPipe,
    NumberWithCommasPipe,
    PluralPipe,
    RoundPipe,
    SafePipe,
    ShowEnabledPipe,
    TimeLocalizePipe,
    TimeUnlocalizePipe,
    TimezonePipe,
    TimingPipe,
    TwoLevelLoopsPipe,
    UnitPipe,
} from './pipes';

/*
 * Directives
 */
import {DynamicComponentDirective} from './directives';

/*
 * Empty components
 */
import {
    EmptyBarChartStackedComponent,
    EmptyBarChartStackedSingleComponent,
    EmptyColumnChartComponent,
    EmptyKpiChartComponent,
    EmptyLabelValueVerticalComponent,
    EmptyLineChartComponent,
    EmptyPieChartComponent,
    EmptyPieChartDonutComponent,
    EmptyTableComponent,
    EmptyTableSmallComponent,
} from './empty-components';

/*
 * Styles and theme
 */
import {DEFAULT_THEME} from './styles/theme.default';
import {DARK_THEME} from './styles/theme.dark';
import {DEFAULT_ACCESSIBILITY_THEME} from './styles/theme.accessibility.default';
import {DARK_ACCESSIBILITY_THEME} from './styles/theme.accessibility.dark';
// Other
import {AuthModule} from '@auth/auth.module';
import {RouterModule} from '@angular/router';
import {SidebarStore} from '../@store';

// External
import {ChartModule} from 'angular2-chartjs';
import {NgxEchartsModule} from 'ngx-echarts';
import {NgxValidationMessageComponent} from './components/validation-message/validation-message.component';
import {ReportNavbarComponent} from './components/report-navbar/report-navbar.component';
import {DropdownSelectorComponent} from './components/dropdown-selector/dropdown-selector.component';
import {ReportTypeIndicatorComponent} from './components/report-type-indicator/report-type-indicator.component';
import {CrossSelectorService} from './components/dropdown-selector/cross-selector-service';
import {NgxScTableComponent} from './components/ngx-sc-table-package/ngx-sc-table/ngx-sc-table.component';
import {NgxScTableStore} from './components/ngx-sc-table-package/ngx-sc-table.store';
import {NgxScSortableDirective} from './components/ngx-sc-table-package/ngx-sc-table-directive/ngx-sc-sortable.directive';
import {NgxScFilterableDirective} from './components/ngx-sc-table-package/ngx-sc-table-directive/ngx-sc-filterable.directive';
import {NgxScTableContainerComponent} from './components/ngx-sc-table-package/ngx-sc-table-container/ngx-sc-table-container.component';
import {NgxScTableSimpleComponent} from './components/ngx-sc-table-package/ngx-sc-table-simple/ngx-sc-table-simple.component';
import {NgxScCompareListComponent} from './components/ngx-sc-compare-list-package/ngx-sc-compare-list/ngx-sc-compare-list.component';
import {NgxCheckMarkFieldComponent} from './components/ngx-sc-compare-list-package/ngx-check-mark-field/ngx-check-mark-field.component';
import {ColumnWithIconComponent} from './components/ngx-sc-table-package/column-with-icon/column-with-icon.component';
import {NgxScChatComponent} from './components/ngx-sc-table-package/ngx-sc-chat/ngx-sc-chat.component';
import {ChartDataSortComponent} from './components/chart-data-sort/chart-data-sort.component';
import {ChartDataSortPopupComponent} from './components/chart-data-sort/chart-data-sort-popup.component';
import {ChartLegendBarComponent} from './components/chart-legend-bar/chart-legend-bar.component';
import {ChartTitleBarComponent} from './components/chart-title-bar/chart-title-bar.component';
import {NestedAccordionComponent} from './components/nested-accordion/nested-accordion.component';
import {InlineRadioGroupComponent} from './components/inline-radio-group/inline-radio-group.component';
import {NgxMiniLineChartComponent} from './components/ngx-mini-line-chart/ngx-mini-line-chart.component';
import {PageHeaderComponent} from './components/page-header/page-header.component';
import {DataAuditWidgetComponent} from './components/data-audit-widget/data-audit-widget.component';
import {SystemInfoDialogComponent} from './components/system-info-dialog/system-info-dialog.component';
import {DeviceDetectorService} from 'ngx-device-detector';
import {StudySelectorViewModel} from './components/study-selector/study-selector.vm';
import {HelpSupportComponent} from './components/help-support/help-support.component';
import {HelpSupportDialogComponent} from './components/help-support/help-support-dialog/help-support-dialog.component';
import {HelpSupportSideBarComponent} from './components/help-support/help-support-side-bar/help-support-side-bar.component';
import {HeaderSettingsComponent} from '@theme/components';
import {HeaderSettingsDialogComponent} from '@theme/components';
import {NgxSmartTableModule} from '@mominsamir/ngx-smart-table';
import {MiniDataTableComponent} from './components/mini-data-table-card/mini-data-table/mini-data-table.component';
import {Ng2SmartTableModule} from 'ng2-smart-table';
import {MiniTrendComponent} from './components/mini-trend/mini-trend.component';
import {ButtonGroupComponent} from './components/button-group/button-group.component';
import {PageHeaderBreadcrumbComponent} from './components/page-header-breadcrumb/page-header-breadcrumb.component';
import {WorkflowSelectorComponent} from './components/workflow-selector/workflow-selector.component';
import {PopoverMessageBoxComponent} from './components/popover-message-box/popover-message-box.component';
import {TooltipComponent} from '@theme/components/tooltip/tooltip.component';
import {PrevButtonComponent} from './components/pager-button/prev-button/prev-button.component';
import {NextButtonComponent} from './components/pager-button/next-button/next-button.component';
import {AccessibilitySwitcherComponent} from './components/accessibility-switcher/accessibility-switcher.component';
import {UserNotificationComponent} from './components/user-notification/user-notification.component';
import {OnlyNumberDirective} from '@theme/directives/only-number.directive';
import {ChartSimpleLegendBarComponent} from './components/chart-simple-legend-bar/chart-simple-legend-bar.component';
import {DashboardCardComponent} from './components/dashboard/dashboard-card/dashboard-card.component';
import {TruncateTextDirective} from '@theme/directives/truncate-text.directive';
import {DashboardCardTextComponent} from './components/dashboard/components/dashboard-card-text/dashboard-card-text.component';
import {DashboardCardNumberComponent} from './components/dashboard/components/dashboard-card-number/dashboard-card-number.component';
import {DashboardCardTableComponent} from './components/dashboard/components/dashboard-card-table/dashboard-card-table.component';
import {ChartKpiComponent} from './components/chart-kpi/chart-kpi.component';
import {ChartKpiContainerComponent} from './components/chart-kpi/chart-kpi-container/chart-kpi-container.component';

const BASE_MODULES = [CommonModule, FormsModule, ReactiveFormsModule];
const NB_MODULES = [
    NbEvaIconsModule,
    NbCardModule,
    NbLayoutModule,
    NbTabsetModule,
    NbRouteTabsetModule,
    NbMenuModule,
    NbUserModule,
    NbActionsModule,
    NbSearchModule,
    NbSidebarModule,
    NbCheckboxModule,
    NbPopoverModule,
    NbContextMenuModule,
    NgbModule,
    NbSecurityModule, // *nbIsGranted directive,
    NbProgressBarModule,
    NbCalendarModule,
    NbCalendarRangeModule,
    NbStepperModule,
    NbButtonModule,
    NbListModule,
    NbToastrModule,
    NbInputModule,
    NbAccordionModule,
    NbDatepickerModule,
    NbDialogModule,
    NbWindowModule,
    NbAlertModule,
    NbSpinnerModule,
    NbRadioModule,
    NbSelectModule,
    NbChatModule,
    NbTooltipModule,
    NbCalendarKitModule,
    NbIconModule,
    ChartModule,
    NgxEchartsModule,
];
const APP_MODULES = [NgxSmartTableModule];

const COMPONENTS = [
    // Auth
    components.PasswordResetComponent,
    components.PasswordForceComponent,
    components.ChangePasswordComponent,
    components.InputPasswordComponent,

    NgxValidationMessageComponent,
    components.ThemeSwitcherComponent,
    components.FooterComponent,
    components.ValueBoxComponent,
    components.CheckboxGroupComponent,
    components.IconBoxComponent,
    components.PagesGroupPopoverComponent,
    components.MenuComponent,
    components.SubmenuComponent,
    components.SubmenuPopoverComponent,
    components.RouteTabsetComponent,
    components.TablePagerComponent,
    components.SidebarActionComponent,
    components.SidebarActionPopoverComponent,
    components.ChartComponent,
    components.ChartSettingsComponent,
    components.ChartSettingsPopupComponent,
    components.SliderComponent,
    components.FileUploadComponent,
    components.FileDownloadComponent,
    components.ActiveStudySelectorComponent,
    components.DefaultStudySelectorComponent,
    components.DataTableComponent,
    components.DateFilterComponent,
    components.StringDateFilterComponent,
    components.MultiSelectFilterComponent,
    components.NumericRangeFilterComponent,
    components.ConformDialogComponent,
    components.ReportFilterDialogComponent,
    components.InputErrorComponent,
    components.EmptyDataComponent,
    components.StatusCellComponent,
    components.TableSettingsComponent,
    components.TableSettingsPopupComponent,

    HelpSupportComponent,
    HelpSupportDialogComponent,
    HelpSupportSideBarComponent,
    HeaderSettingsComponent,
    HeaderSettingsDialogComponent,

    // General
    PageHeaderComponent,
    PageHeaderBreadcrumbComponent,
    PopoverMessageBoxComponent,
    TooltipComponent,
    PrevButtonComponent,
    NextButtonComponent,
    UserNotificationComponent,

    // General, related to failure curve tool
    NgxScTableComponent,
    NgxScTableContainerComponent,
    ColumnWithIconComponent,
    NgxScTableSimpleComponent,
    NgxScCompareListComponent,
    NgxCheckMarkFieldComponent,

    // General, related to data audit tool
    InlineRadioGroupComponent,
    NestedAccordionComponent,
    NgxMiniLineChartComponent,
    ChartDataSortComponent,
    ChartDataSortPopupComponent,
    ChartTitleBarComponent,
    ChartLegendBarComponent,
    ChartSimpleLegendBarComponent,
    DataAuditWidgetComponent,
    MiniTrendComponent,
    ButtonGroupComponent,

    // General, related to reporting tool
    ReportNavbarComponent,
    DropdownSelectorComponent,
    ReportTypeIndicatorComponent,
    WorkflowSelectorComponent,

    //Data table Card
    MiniDataTableComponent,

    // Empty chart components
    EmptyBarChartStackedComponent,
    EmptyBarChartStackedSingleComponent,
    EmptyColumnChartComponent,
    EmptyKpiChartComponent,
    EmptyLabelValueVerticalComponent,
    EmptyLineChartComponent,
    EmptyPieChartComponent,
    EmptyPieChartDonutComponent,
    EmptyTableComponent,
    EmptyTableSmallComponent,
];

const ENTRY_COMPONENTS = [NgxCheckMarkFieldComponent, EmptyDataComponent];

const PIPES = [
    CapitalizePipe,
    PluralPipe,
    RoundPipe,
    TimingPipe,
    NumberWithCommasPipe,
    SafePipe,
    ShowEnabledPipe,
    TimezonePipe,
    GroupByPipe,
    TwoLevelLoopsPipe,
    UnitPipe,
];
const DATA_PIPES = [TimeLocalizePipe, TimeUnlocalizePipe];

const DIRECTIVES = [
    DynamicComponentDirective,
    NgxScSortableDirective,
    NgxScFilterableDirective,
    OnlyNumberDirective,
    TruncateTextDirective,
];
const VM = [StudySelectorViewModel];
const NB_THEME_PROVIDERS = [
    ...NbThemeModule.forRoot(
        {
            name: 'default',
        },
        // [DEFAULT_THEME, DARK_THEME],
        [DEFAULT_THEME, DARK_THEME, DEFAULT_ACCESSIBILITY_THEME, DARK_ACCESSIBILITY_THEME],
    ).providers,
    ...NbSidebarModule.forRoot().providers,
    ...NbMenuModule.forRoot().providers,
    ...NbDatepickerModule.forRoot().providers,
    ...NbDialogModule.forRoot().providers,
    ...NbWindowModule.forRoot().providers,
    ...NbToastrModule.forRoot().providers,
    ...NbChatModule.forRoot({
        messageGoogleMapKey: 'AIzaSyA_wNuCzia92MAmdLRzmqitRGvCF7wCZPY',
    }).providers,
];

@NgModule({
    imports: [
        AuthModule,
        RouterModule,
        ...BASE_MODULES,
        ...NB_MODULES,
        ...APP_MODULES,
        NgxEchartsModule.forRoot({
            echarts: () => import('echarts'),
        }),
        Ng2SmartTableModule,
    ],
    exports: [
        ...BASE_MODULES,
        ...NB_MODULES,
        ...COMPONENTS,
        ...PIPES,
        ...DATA_PIPES,
        ...DIRECTIVES,
        DashboardCardComponent,
        DashboardCardTextComponent,
        DashboardCardNumberComponent,
        DashboardCardTableComponent,
        ChartKpiComponent,
        ChartKpiContainerComponent,
    ],
    providers: [SidebarStore, NgxScTableStore, CrossSelectorService, DecimalPipe, DeviceDetectorService, ...VM],
    declarations: [
        ...COMPONENTS,
        ...PIPES,
        ...DATA_PIPES,
        ...DIRECTIVES,
        NgxScChatComponent,
        SystemInfoDialogComponent,
        AccessibilitySwitcherComponent,
        DashboardCardComponent,
        DashboardCardTextComponent,
        DashboardCardNumberComponent,
        DashboardCardTableComponent,
        ChartKpiComponent,
        ChartKpiContainerComponent,
    ],
    entryComponents: [...ENTRY_COMPONENTS],
})
export class ThemeModule {
    static forRoot(): ModuleWithProviders<ThemeModule> {
        return {
            ngModule: ThemeModule,
            providers: [...NB_THEME_PROVIDERS],
        };
    }
}
