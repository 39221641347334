import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {SimpleTableData} from '../../../../pages/connectivity-analysis/history/details/simple-table/simple-table.component';
import {APIResponse, SimpleMultiSeries} from '@core/interfaces/system/system-common';
import {ConnectivityService} from '@core/interfaces/engin/connectivity';
import {DownloadCSVUrl} from '@core/interfaces/engin/workflow-validation';
import {ConnectivityApi} from '@core/backend/engin/api/connectivity.api';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class ConnectivityServiceImpl extends ConnectivityService {
    constructor(private api: ConnectivityApi) {
        super();
    }

    public getSummaryDetails(workflowId: number): Observable<APIResponse<SimpleTableData>> {
        return this.api.getSummaryDetails(workflowId);
    }

    public getAssetDemographicsChart(workflowId: number): Observable<APIResponse<SimpleMultiSeries<string>>> {
        return this.api.getAssetDemographicsChart(workflowId);
    }

    public getCustomerDemographicsChart(workflowId: number): Observable<APIResponse<SimpleMultiSeries<string>[]>> {
        return this.api.getCustomerDemographicsChart(workflowId);
    }

    public getRegionDetails(workflowId: number): Observable<APIResponse<SimpleTableData>> {
        return this.api.getRegionDetails(workflowId);
    }

    public getRegionChart(workflowId: number): Observable<APIResponse<SimpleMultiSeries<string>[]>> {
        return this.api.getRegionChart(workflowId);
    }

    // Feeder
    public getFeederList(workflowId: number): Observable<DataSource> {
        return this.api.getFeederList(workflowId);
    }

    public getFeederListCsv(workflowId: number): Observable<APIResponse<DownloadCSVUrl>> {
        return this.api.getFeederListCsv(workflowId);
    }

    public getFeederMatches(workflowId: number, partialFeederId: string): Observable<APIResponse<string[]>> {
        return this.api.getFeederMatches(workflowId, partialFeederId);
    }

    // Entity
    public getEntityListByFeeder(workflowId: number, feederId: string): Observable<DataSource> {
        return this.api.getEntityListByFeeder(workflowId, feederId);
    }

    public getEntityListByFeederCsv(workflowId: number, feederId: string): Observable<APIResponse<DownloadCSVUrl>> {
        return this.api.getEntityListByFeederCsv(workflowId, feederId);
    }

    public getProfileEntitiesByFeeder(workflowId: number, feederId: string): Observable<APIResponse<ProfileEntities>> {
        return this.api.getProfileEntitiesByFeeder(workflowId, feederId);
    }
}

export interface ProfileEntities {
    nodes: any[];
    edges: any[];
}
