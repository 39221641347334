import {ModuleWithProviders, NgModule} from '@angular/core';
import {SidebarStore} from './config/sidebar.store';
import {PagesStore} from './config/pages.store';
import {AnalyzerStore} from './analyzer/analyzer.store';
import {AnalyzerPopoutStore} from './analyzer/analyzer-popout.store';
import {StudiesStore} from './common/studies.store';
import {AssetDetailsStore} from './analyzer/asset-details.store';
import {OptimizerStore} from './optimizer/optimizer.store';
import {OptimizerPopoutStore} from './optimizer/optimizer-popout.store';
import {ForecasterPopoutStore} from './forecaster/forecaster-popout-store';
import {ReportsStore} from './common/reports.store';
import {RolesStore} from './common/roles.store';
import {PlannerFilterStore} from './planner-filter.store';
import {ForecasterStore} from './forecaster/forecaster.store';
import {UsersStore} from './common/users.store';
import {AssetDataAuditStore} from './data-audit/asset-data-audit.store';
import {DataAuditPopoutStore} from './data-audit/data-audit-popout.store';
import {SupportStore} from './common/support.store';
import {DataManagementStore} from './common/data-management.store';
import {ConfigurationStore} from './config/configuration.store';
import {EvaluationDataAuditStore} from './data-audit/evaluation-data-audit.store';
import {TenantSettingsStore} from '@store/common/tenant-settings.store';

const STORES = [
    // Common
    DataManagementStore,
    ReportsStore,
    RolesStore,
    StudiesStore,
    UsersStore,
    SupportStore,
    TenantSettingsStore,

    // Config
    PagesStore,
    SidebarStore,
    ConfigurationStore,

    // Analyzer
    AnalyzerPopoutStore,
    AnalyzerStore,
    AssetDetailsStore,

    // Optimizer
    OptimizerPopoutStore,
    OptimizerStore,

    // Forecaster
    ForecasterPopoutStore,
    ForecasterStore,

    // Planner
    PlannerFilterStore,

    // Data audit
    AssetDataAuditStore,
    DataAuditPopoutStore,
    EvaluationDataAuditStore,
];

@NgModule({
    imports: [],
    exports: [],
    declarations: [],
    providers: [...STORES],
})
export class StoreModule {
    static forRoot(): ModuleWithProviders<StoreModule> {
        return {
            ngModule: StoreModule,
            providers: [],
        };
    }
}
