import {ModuleWithProviders, NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpRequest} from '@angular/common/http';
import {CommonModule} from '@angular/common';
import {
    NB_AUTH_TOKEN_INTERCEPTOR_FILTER,
    NbAuthModule,
    NbAuthOAuth2Token,
    NbOAuth2AuthStrategy,
    NbOAuth2ClientAuthMethod,
    NbOAuth2GrantType,
    NbPasswordAuthStrategy,
    NbTokenLocalStorage,
} from '@nebular/auth';
import {AuthInterceptor} from './auth.interceptor';
import {AuthGuard} from './auth.guard';
import {AuthPipe} from './auth.pipe';
import {RoleProvider} from './role.provider';
import {NbRoleProvider, NbSecurityModule} from '@nebular/security';
import {environment} from '../../environments/environment';
import {PermissionsGuard} from './permissions.guard';
import {RerouteGuard} from './reroute.guard';
import {CustomAuthInterceptor} from './auth.token.interceptor';

const GUARDS = [AuthGuard, PermissionsGuard, RerouteGuard];
const PIPES = [AuthPipe];

export function filterInterceptorRequest(req: HttpRequest<any>): boolean {
    // External APIs to avoid adding JWT
    return [
        '/auth/login',
        '/request-password-reset',
        '/reset-password',
        '/auth/verify-email',
        '.s3.',
        '/refresh-token',
        '/reporting-tool/report-window-html',
    ].some((url) => req.url.includes(url));
}

@NgModule({
    declarations: [...PIPES],
    imports: [
        CommonModule,
        NbAuthModule.forRoot({
            strategies: [
                NbOAuth2AuthStrategy.setup({
                    name: 'email',
                    clientId: environment.OAUTH_CLIENT_ID,
                    clientSecret: environment.OAUTH_CLIENT_SECRET,
                    baseEndpoint: `${environment.NODE_BASE_URL}/authorize/`,
                    clientAuthMethod: NbOAuth2ClientAuthMethod.REQUEST_BODY,
                    token: {
                        endpoint: 'token',
                        grantType: NbOAuth2GrantType.PASSWORD,
                        class: NbAuthOAuth2Token,
                    },
                    refresh: {
                        endpoint: 'refresh-token',
                        grantType: NbOAuth2GrantType.REFRESH_TOKEN,
                    },
                }),
                NbPasswordAuthStrategy.setup({
                    name: 'generalAPI',
                    baseEndpoint: `${environment.NODE_BASE_URL}/authorize/`,
                    logout: {
                        endpoint: 'logout',
                        method: 'post',
                    },
                    requestPass: {
                        endpoint: 'request-password-reset',
                        method: 'post',
                    },
                    resetPass: {
                        endpoint: 'reset-password',
                        method: 'put',
                    },
                }),
            ],
        }),
    ],
    exports: [...PIPES],
    providers: [
        NbSecurityModule.forRoot({
            accessControl: {
                user: {},
                admin: {
                    parent: 'user',
                    download: 'uploads',
                    manage: ['user', 'admin'],
                },
                superadmin: {
                    parent: 'admin',
                    manage: 'superadmin',
                },
            },
        }).providers,
        {
            provide: NbRoleProvider,
            useClass: RoleProvider,
        },
        {
            provide: NbTokenLocalStorage,
            useClass: NbTokenLocalStorage,
        },
    ],
})
export class AuthModule {
    static forRoot(): ModuleWithProviders<AuthModule> {
        return {
            ngModule: AuthModule,
            providers: [
                {provide: NB_AUTH_TOKEN_INTERCEPTOR_FILTER, useValue: filterInterceptorRequest},
                {provide: HTTP_INTERCEPTORS, useClass: CustomAuthInterceptor, multi: true},
                {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
                ...GUARDS,
            ],
        };
    }
}
