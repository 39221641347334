import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {Observable} from 'rxjs';
import {DataFile} from './data-file';
import {SimpleSeries} from '../../../pages/survival-analysis/api/pojo/curve-calibration-outputs.pojo';
import {APIResponse} from '../system/system-common';

export enum DatasetType {
    ASSET = 'ASSET',
    EVALUATION = 'EVALUATION',
    HEALTH_INDEX = 'HEALTH_INDEX',
    CONNECTIVITY = 'CONNECTIVITY',
    SURVIVAL = 'SURVIVAL',
    GRID_MODEL = 'GRID_MODEL',
    LOAD_PROFILE = 'LOAD_PROFILE',
}
export const DatasetTypeLabel = {
    [DatasetType.ASSET]: 'Asset',
    [DatasetType.EVALUATION]: 'Evaluation',
    [DatasetType.HEALTH_INDEX]: 'Health Index',
    [DatasetType.CONNECTIVITY]: 'Connectivity',
    [DatasetType.SURVIVAL]: 'Survival',
    [DatasetType.GRID_MODEL]: 'Grid Model',
    [DatasetType.LOAD_PROFILE]: 'Load Profile',
};

export interface Dataset {
    _id: string;
    id: number;
    createdOn: Date;
    updatedOn: Date;
    datasetType: DatasetType;
    version: number;
    status: 'CREATED' | 'DELETED' | 'PENDING_DELETE';
    auditStatus?: 'SUCCESS' | 'FAILED' | 'NOT_RUN';
    tag?: string;
    note?: string;
    files: DatasetFile[];
}

export interface DatasetFile {
    datasetId: number;
    uploadFileId: number;
    targetedTable: string;
    uploadFile: DataFile;
}

export interface DatasetCreator {
    datasetType: string;
    createType: string;
    sourceDatasetId: number;
    tag: string;
    note: string;
    files: DatasetFileCreator[];
}

export interface DatasetFileCreator {
    fileId: number;
    tableLabel: string;
}

// Dataset Preview
export interface DatasetPreview {
    datasetId: number;
    dataset: Dataset;
    tables?: PreviewDataTable[];
    series?: SimpleSeries[];
}
export interface PreviewDataTable {
    tableCode: string;
    tableName: string;
    totalValue: string;
    dataType: ValueType;
    dataUnit: string;
    rows: PreviewDataTableItem[][];
}
export interface PreviewDataTableItem {
    value: string;
    dataType: ValueType;
    dataUnit: string;
}
export enum ValueType {
    INTEGER = 'INTEGER',
    LONG = 'LONG',
    DECIMAL = 'DECIMAL',
    STRING = 'STRING',
}

/*export interface PreviewRequest{
    dataLoadCode: string,
    datasetType: string,
    datasetStatus: string,
}*/

export abstract class DatasetService {
    abstract findOne(id: string): Observable<APIResponse<Dataset>>;

    abstract create(data: DatasetCreator): Observable<APIResponse<Dataset>>;

    abstract update(id: string, data: Dataset): Observable<APIResponse<Dataset>>;

    abstract delete(uploadedId: string): Observable<APIResponse<boolean>>;

    abstract reversePendingDelete(datasetId: string): Observable<APIResponse<boolean>>;

    abstract listLatestDataSet(pageSize: number, schemaType: string): Observable<Dataset[]>;

    abstract listFiles(): Observable<DataSource>;

    abstract listFilesByType(dataType: DatasetType): Observable<DataSource>;

    abstract listFilesByTypeAndStatus(dataType: DatasetType, status: string): Observable<DataSource>;

    abstract getDatasetPreviewByIds(datasetIds: number[]): Observable<APIResponse<DatasetPreview[]>>;
}
