import {Injectable} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {shareReplay, switchMap, takeUntil, tap} from 'rxjs/operators';
import {StudiesStore} from '../common/studies.store';
import {
    AvailabilityData,
    ForecasterRequest,
    ForecasterResponse,
    ForecasterService,
} from '../../@core/interfaces/engin/forecaster';
import {ForecasterPopoutStore} from './forecaster-popout-store';
import {User, UsersService} from '../../@core/interfaces/common/users';
import {WorkflowItem} from '../../@core/interfaces/engin/workflow';
import {Unsubscribable} from '../../@core/interfaces/unsubscribable';
import {NbToastrService} from '@nebular/theme';

@Injectable()
export class ForecasterStore extends Unsubscribable {
    // Current endpoint is loading
    public resultsLoading = new BehaviorSubject<boolean>(false);
    public resultsLoading$ = this.resultsLoading.asObservable();

    // System forecast page
    private combineForecasterRequestInformation$ = combineLatest<
        Observable<any>,
        Observable<WorkflowItem>,
        Observable<User>
    >([this.forecasterPopoutStore.serverPopout$, this.studiesStore.activeStudy$, this.userService.getCurrentUser()]);

    readonly forecasterChartData$: Observable<ForecasterResponse[]> = this.combineForecasterRequestInformation$.pipe(
        tap(() => {
            this.resultsLoading.next(true);
        }),
        switchMap(([popoutValue, activeStudy, user]) => {
            const userId = user.id;
            const studyId = activeStudy.id.toString();
            const req = this.prepareForecasterRequest(popoutValue, activeStudy, userId);
            return this.forecasterService.getForecasterData(req, studyId);
        }),
        takeUntil(this.unsubscribe$),
        tap(() => {
            this.resultsLoading.next(false);
        }),
        shareReplay(),
    );

    readonly stationAvailabilityData$: Observable<AvailabilityData> = this.combineForecasterRequestInformation$.pipe(
        tap(() => {
            this.resultsLoading.next(true);
        }),
        switchMap(([popoutValue, activeStudy, user]) => {
            const userId = user.id;
            const req = this.prepareForecasterRequest(popoutValue, activeStudy, userId);
            return this.forecasterService.getStationAvailabilityData(req);
        }),
        takeUntil(this.unsubscribe$),
        tap(() => {
            this.resultsLoading.next(false);
        }),
        shareReplay(),
    );

    readonly lineAvailabilityData$: Observable<AvailabilityData> = this.combineForecasterRequestInformation$.pipe(
        tap(() => {
            this.resultsLoading.next(true);
        }),
        switchMap(([popoutValue, activeStudy, user]) => {
            const userId = user.id;
            const req = this.prepareForecasterRequest(popoutValue, activeStudy, userId);
            return this.forecasterService.getLineAvailabilityData(req);
        }),
        takeUntil(this.unsubscribe$),
        tap(() => {
            this.resultsLoading.next(false);
        }),
        shareReplay(),
    );

    private prepareForecasterRequest(
        popoutValue: ForecasterRequest,
        activeStudy: WorkflowItem,
        userId: string,
    ): ForecasterRequest {
        const req = popoutValue;
        req.stateChanged = false;
        req.sensitivityStudy = {
            studyId: activeStudy.id.toString(),
            userId: userId,
            sensitivityParams: null,
            // TODO:: current year calcualation
            currentYear: activeStudy.currentYear,
            evaluationPeriod: 10,
        };
        return req;
    }

    constructor(
        private forecasterService: ForecasterService,
        private studiesStore: StudiesStore,
        private forecasterPopoutStore: ForecasterPopoutStore,
        protected toastrService: NbToastrService,
        private userService: UsersService,
    ) {
        super();
    }
}
