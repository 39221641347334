import {Pipe, PipeTransform} from '@angular/core';
import {User} from '../../@core/interfaces/common/users';
import * as moment from 'moment-timezone';
import {FormatsService} from '../../@core/utils';
import {Moment} from 'moment-timezone';
import {filter} from 'rxjs/operators';
import {UsersStore} from '../../@store/common/users.store';

@Pipe({
    name: 'localizeTime',
    pure: false,
})
export class TimeLocalizePipe implements PipeTransform {
    private localTimezone: string = 'Etc/UTC'; // default utc
    constructor(private usersStore: UsersStore) {
        this.usersStore.currentUser$.pipe(filter((d) => !!d)).subscribe((user: User) => {
            this.localTimezone = user.timezone;
        });
    }

    /**
     * Convert universal timestamp (UTC) to local time; ignore any timezone in utcDateRaw in favour of user's local time
     * saved in ENGIN.
     * @param inDateRaw input string timestamp (read as UTC) or date (read timezone)
     * @param outFormat optional else DEFAULT_DATE_FORMAT = 'yyyy-MM-DD hh:mm A'
     */
    transform(inDateRaw: any, outFormat: any = FormatsService.DEFAULT_DATE_FORMAT): any {
        // moment.utc() interprets ambiguous input as UTC
        const utcMoment: Moment =
            typeof inDateRaw === 'string'
                ? moment.utc(inDateRaw)
                : typeof inDateRaw === 'number'
                ? moment.utc(inDateRaw)
                : moment.utc(inDateRaw.toUTCString());
        const dateString: string = utcMoment.tz(this.localTimezone).format(outFormat);
        return dateString;
    }
}
