import {Observable} from 'rxjs';
import {APIResponse} from '@core/interfaces/system/system-common';

export interface AnalyzerPopoutSettings {
    filters: {
        code: string;
        name: string;
        enabled: boolean;
        comparisonType: string;
        options: {
            code: string;
            name: string;
            enabled: boolean;
            selected: true;
        }[];
    }[];
    groups: {
        code: string;
        name: string;
        enabled: boolean;
        selected: boolean;
    }[];
    sensitivity: {
        code: string;
        name: string;
        enabled: boolean;
        selected: boolean;
    }[];
    inputs: {
        code: string;
        title: string;
        description: string;
        enabled: boolean;
        min: number;
        max: number;
        value: number;
    }[];
}

export interface GeospatialFilterSettings {
    filters: {
        code: string;
        name: string;
        enabled: boolean;
        options: {
            code: string;
            name: string;
            enabled: boolean;
            selected: boolean;
        }[];
    }[];
}

// Abstract settings for popout with dropdowns and sliders
export interface SliderSettingsBase {
    id: string;
    description: string;
    prefix: string;
    suffix: string;
    max: number;
    min: number;
    value: number;
    values: number[];
}

export interface DropdownSettingsBase {
    id: string;
    description: string;
    dirtyYears: number[];
    sliders: SliderSettingsBase[];
}

export interface PopoutSettingsBase {
    customPeriodType: boolean;
    years: number[];
    dropdowns: DropdownSettingsBase[];
}

// Settings for Optimizer popout with dropdowns and sliders
export interface OptimizerSliderSettings extends SliderSettingsBase {
    backlog: number;
}

export interface OptimizerDropdownSettings extends DropdownSettingsBase {
    sliders: OptimizerSliderSettings[];
    backlog: number[];
}

export interface OptimizerPopoutSettings extends PopoutSettingsBase {
    constrained: boolean;
    dropdowns: OptimizerDropdownSettings[];
}

// Settings for Forecaster popout with dropdowns and sliders
export interface ForecasterSliderSettings extends SliderSettingsBase {
    effectiveness: number;
}

export interface ForecasterDropdownSettings extends DropdownSettingsBase {
    sliders: ForecasterSliderSettings[];
}

export interface ForecasterPopoutSettings extends PopoutSettingsBase {
    dropdowns: ForecasterDropdownSettings[];
}

// Data audit popout
export interface DataAuditPopoutSettings {
    filters: {
        selected: boolean;
        code: string;
        name: string;
        enabled: boolean;
        options: {
            code: string;
            name: string;
            enabled: boolean;
            selected: true;
        }[];
        stringFlag: boolean;
    }[];
}

export abstract class PopoutService {
    abstract getAnalyzerPopoutSettings(): Observable<AnalyzerPopoutSettings>;

    abstract getOptimizerPopoutSettings(
        studyId: string,
        panelConfig: number,
    ): Observable<APIResponse<OptimizerPopoutSettings>>;

    abstract getForecasterPopoutSettings(): Observable<ForecasterPopoutSettings>;

    abstract getDataAuditToolPopoutSettings(): Observable<DataAuditPopoutSettings>;
}
