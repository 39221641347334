import {Observable} from 'rxjs';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {APIResponse} from '../system/system-common';
import {PasswordRequirements} from './tenantSettings';

export interface User {
    id: string;
    username: string;
    firstName: string;
    lastName: string;
    email: string;
    emailRegistered: boolean;
    timezone: string;

    activeTenantId: string;
    tenants?: string[];
    multipleTenants: boolean;

    role: string;
    status: string;
    lockoutUntil: Date;
    lockoutActive: boolean;

    studyStrategy: {
        latestAsDefault: boolean;
        defaultCollectionId: string;
        defaultStudyId: string;
    };
    lastLoginDetails: {
        date: any;
        sameIp: boolean;
    };
    passwordExpired: boolean;
}

export interface UserNotification {
    message: string;
    type: string;
}

export interface passwordData {
    currentPassword: string;
    newPassword: string;
    confirmPassword: string;
}

export abstract class UsersService {
    abstract get(id: string): Observable<User>;

    abstract getCurrentUser(): Observable<User>;

    abstract delete(id: string): Observable<boolean>;

    abstract update(user: User): Observable<any>;

    abstract updateRole(user: User): Observable<any>;

    abstract create(dto: any): Observable<any>;

    abstract userNameAvailable(userName: string): Observable<APIResponse<Boolean>>;

    abstract userEmailAvailable(email: string, id: string): Observable<APIResponse<Boolean>>;

    abstract updatePassword(currentPassword: string, newPassword: string, confirmPassword: string): Observable<any>;

    abstract forceUpdatePassword(userId: string, newPassword: string): Observable<any>;

    abstract requestEmailVerification(userId: string): Observable<any>;

    abstract getUsersDataSource(): Observable<DataSource>;

    abstract getUsersListData(): Observable<User[]>;

    abstract getUsersNotification(id): Observable<UserNotification[]>;

    abstract userUnlock(id): Observable<any>;

    abstract updateDefaultStudyStrategy(latestAsDefault: boolean, defaultCollectionId: string): Observable<any>;

    abstract passwordValidation(
        password: string,
        checkPasswordHistory: boolean,
    ): Observable<APIResponse<PasswordRequirements>>;

    abstract inviteUserToOrganization(tenantId: string, username: string): Observable<any>;
}
