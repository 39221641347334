import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Page, PageGroup} from '../../../@core/interfaces/common/pages';
import {FeatureItem, AboutSystemInfo} from '../../../@core/interfaces/common/support';
import {Injectable} from '@angular/core';
import {SupportStore} from '../../../@store/common/support.store';
import {PagesStore} from '../../../@store';

@Injectable()
export class HelpSupportViewModel {
    constructor(private supportStore: SupportStore, private pagesStore: PagesStore) {}

    readonly aboutSystemInfo$: Observable<AboutSystemInfo> = this.supportStore.aboutSystemInfo$;

    readonly contextList$: Observable<FeatureItem[]> = this.pagesStore.pageGroups$.pipe(
        map((pageGroups: PageGroup[]) => {
            // Iterate over the each page group, extract list of features
            const list = [];

            /*
             * Create list from each (group, feature) combination.
             * - Ignore any groups which have blank id or title.
             * - Title: group.title - feature.title
             */
            pageGroups
                .filter((pg) => pg.id !== '' && pg.title !== '')
                .forEach((pageGroup: PageGroup) => {
                    pageGroup.children.forEach((feature: Page) => {
                        list.push(new FeatureItem(feature.id, pageGroup.title + ' - ' + feature.title));
                    });
                });

            return list;
        }),
    );
}
