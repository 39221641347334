import {Injectable} from '@angular/core';
import {HttpService} from '@core/backend/common/api/http.service';
import {Observable} from 'rxjs';
import {APIResponse} from '@core/interfaces/system/system-common';
import {AuthSettings, PasswordRequirements} from '@core/interfaces/common/tenantSettings';

@Injectable()
export class TenantSettingsApi {
    private readonly apiController: string = 'tenantSettings';

    constructor(private api: HttpService) {}

    getTenantSettings(): Observable<APIResponse<AuthSettings>> {
        return this.api.get(`${this.apiController}/`);
    }
    updateTenantSettings(): Observable<any> {
        return this.api.put(`${this.apiController}/`);
    }
}
