<nb-card [class.pointer-none]="!selectedReport">
    <div class="degradation-card degradation-factors">
        <nb-card-header class="pb-1">2. Report Type </nb-card-header>
        <nb-card-body class="chart-card-body degradation-card">
            <!--        <div class="factor-item">-->
            <!--            <div class="display-flex">-->
            <!--                <ngx-icon-box class="icon" icon="checkmark-circle-2-outline">-->
            <!--                </ngx-icon-box>-->
            <!--                <label>Input Data</label>-->
            <!--            </div>-->
            <!--        </div>-->

            <div class="factor-item" *ngFor="let dataItem of dataList">
                <div class="display-flex">
                    <ngx-icon-box
                        class="icon"
                        [ngClass]="{'icon-selected': dataItem.selected}"
                        icon="checkmark-circle-2-outline"
                    >
                    </ngx-icon-box>
                    <label>{{ dataItem.name }}</label>
                </div>
                <ng-container *ngIf="this.reportRequestComposer.studySelected | async">
                    <div class="selector" *ngIf="dataItem.selected">
                        <div class="line"></div>
                        <ngx-icon-box class="arrow-right" icon="arrow-right"> </ngx-icon-box>
                    </div>
                </ng-container>
            </div>
        </nb-card-body>
    </div>
</nb-card>
