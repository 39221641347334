import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpService} from './http.service';
import {DataUpload, UploadData} from '@core/interfaces/common/legacyUploads';

/* depracated */
@Injectable()
export class LegacyUploadsApi {
    private readonly prefix = 'uploads/legacy';

    constructor(private api: HttpService) {}

    uploadFCFile(dataType: UploadData, fileData: FormData, purge: boolean, audit: boolean): Observable<DataUpload> {
        return this.api.post(
            `${this.prefix}/survival-analysis/${dataType.dataType}?purge=${purge}&audit=${audit}&comment=${dataType.comment}`,
            fileData,
        );
    }
}
