import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {Observable, of} from 'rxjs';

/**
 * Force password component
 * - Requires auth (must be logged in)
 * - User enters new password to force reset password for a different (target) account. e.g. admin password reset.
 */
@Component({
    selector: 'ngx-password-force',
    templateUrl: './password-force.component.html',
    styleUrls: ['./password-force.component.scss'],
})
export class PasswordForceComponent {
    @Input() form: FormGroup;
    @Input() validation$: Observable<any> = of([]);
    @Input() layoutCompressed: boolean = false;
    @Output() onInputChanges = new EventEmitter();

    constructor() {}

    get password(): FormControl {
        return this.form.get('password') as FormControl;
    }

    onPasswordChanged($event) {
        this.onInputChanges.emit({password: $event.target.value});
    }
}
