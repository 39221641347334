<form [formGroup]="form" class="password-form" aria-labelledby="title" *ngIf="validation$ | async as validation">
    <!-- Expanded layout; input and validation within single column -->
    <div class="row" *ngIf="!layoutCompressed">
        <div class="form-control-group">
            <!-- Current password -->
            <div class="col-12">
                <label class="section-label" for="input-current-password">Current Password</label>
                <ngx-input-password
                    controlName="currentPassword"
                    [fc]="currentPassword"
                    name="currentPassword"
                    id="input-current-password"
                    fieldSize="medium"
                >
                </ngx-input-password>
                <ngx-input-error [formGroup]="form" fieldName="currentPassword"> </ngx-input-error>
            </div>

            <!-- New password -->
            <div class="col-12">
                <label class="section-label" for="input-new-password">New Password</label>
                <ngx-input-password
                    controlName="newPassword"
                    id="input-new-password"
                    fieldSize="medium"
                    [fc]="newPassword"
                    (onInputChanges)="onPasswordChanged($event)"
                    name="newPassword"
                >
                </ngx-input-password>
                <ngx-input-error [formGroup]="form" fieldName="newPassword"> </ngx-input-error>
            </div>

            <!-- Validation requirements -->
            <div class="col-12 requirements-container">
                <div class="row">
                    <label class="section-label">Password requirements:</label>
                </div>
                <div class="row" *ngFor="let message of validation.messages">
                    <ng-container [ngSwitch]="message.validation">
                        <div *ngSwitchCase="true" class="col-1 passed">
                            <nb-icon icon="checkmark-circle-2"></nb-icon>
                        </div>
                        <div *ngSwitchCase="false" class="col-1 failed"><nb-icon icon="close-circle"></nb-icon></div>
                    </ng-container>
                    <div class="col-11 password-requirement">
                        <label>{{ message.description }}</label>
                    </div>
                </div>
            </div>

            <!-- Confirm new password -->
            <div class="col-12">
                <label class="section-label" for="input-confirm-password">Confirm Password</label>
                <ngx-input-password
                    controlName="confirmPassword"
                    [fc]="confirmPassword"
                    name="confirmPassword"
                    id="input-confirm-password"
                    fieldSize="medium"
                >
                </ngx-input-password>
                <ngx-input-error [formGroup]="form" fieldName="confirmPassword"> </ngx-input-error>
            </div>
        </div>
    </div>

    <!-- Compressed layout; side-by-side input to validation -->
    <div class="row" *ngIf="layoutCompressed">
        <div class="col-6">
            <div class="form-control-group">
                <!-- Current password -->
                <label class="section-label" for="input-current-password2">Current Password</label>
                <ngx-input-password
                    controlName="currentPassword"
                    [fc]="currentPassword"
                    name="currentPassword"
                    id="input-current-password2"
                    fieldSize="medium"
                >
                </ngx-input-password>
                <ngx-input-error [formGroup]="form" fieldName="currentPassword"> </ngx-input-error>

                <!-- New password -->
                <label class="section-label" for="input-new-password2">New Password</label>
                <ngx-input-password
                    controlName="newPassword"
                    id="input-new-password2"
                    fieldSize="medium"
                    [fc]="newPassword"
                    (onInputChanges)="onPasswordChanged($event)"
                    name="newPassword"
                >
                </ngx-input-password>
                <ngx-input-error [formGroup]="form" fieldName="newPassword"> </ngx-input-error>

                <!-- Confirm new password -->
                <label class="section-label" for="input-confirm-password2">Confirm Password</label>
                <ngx-input-password
                    controlName="confirmPassword"
                    [fc]="confirmPassword"
                    name="confirmPassword"
                    id="input-confirm-password2"
                    fieldSize="medium"
                >
                </ngx-input-password>
                <ngx-input-error [formGroup]="form" fieldName="confirmPassword"> </ngx-input-error>
            </div>
        </div>

        <div class="col-6 requirements-container">
            <div class="row">
                <label class="section-label">Password requirements:</label>
            </div>
            <div class="row" *ngFor="let message of validation.messages">
                <ng-container [ngSwitch]="message.validation">
                    <div *ngSwitchCase="true" class="col-1 passed">
                        <nb-icon icon="checkmark-circle-2"></nb-icon>
                    </div>
                    <div *ngSwitchCase="false" class="col-1 failed"><nb-icon icon="close-circle"></nb-icon></div>
                </ng-container>
                <div class="col-11 password-requirement">
                    <label>{{ message.description }}</label>
                </div>
            </div>
        </div>
    </div>
</form>
