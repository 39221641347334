import {Injectable} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {User, UsersService} from '@core/interfaces/common/users';
import {debounceTime, filter, map, share, shareReplay, switchMap, takeUntil, tap} from 'rxjs/operators';
import {UsersStore} from './users.store';
import {APIResponse} from '@core/interfaces/system/system-common';
import {Workflow, WorkflowItem, WorkflowService} from '@core/interfaces/engin/workflow';
import {Unsubscribable} from '@core/interfaces/unsubscribable';
import {NbToastrService} from '@nebular/theme';

@Injectable()
export class StudiesStore extends Unsubscribable {
    private latestAsDefault = new BehaviorSubject<boolean>(null);
    readonly latestAsDefault$: Observable<boolean> = this.latestAsDefault.asObservable();

    private defaultStudyId = new BehaviorSubject<string>(null);
    readonly defaultStudyId$: Observable<string> = this.defaultStudyId.asObservable();

    private defaultCollectionId = new BehaviorSubject<string>(null);
    readonly defaultCollectionId$: Observable<string> = this.defaultCollectionId.asObservable();

    private activeCollectionId = new BehaviorSubject<string>(null);
    readonly activeCollectionId$: Observable<string> = this.activeCollectionId.asObservable();

    readonly activeCollection$: Observable<Workflow> = this.activeCollectionId$.pipe(
        filter((d) => !!d),
        switchMap((collectionId: string) => {
            return this.workflowService.getWorkflowById(parseInt(collectionId, 10));
        }),
        map((resp: APIResponse<Workflow>) => {
            return resp.response;
        }),
        tap((collection: Workflow) => {
            const defaultStudy = collection.studies ? collection.studies.filter((study) => study.defaultItem)[0] : {};
            let studyId: string | number;
            if (defaultStudy && defaultStudy[0] && defaultStudy[0].id) {
                studyId = defaultStudy[0].id;
            } else if (collection.studies && collection.studies[0] && collection.studies[0].id) {
                studyId = collection.studies[0].id;
            }
            // Reset the activeStudyId only if it has changed; else it will trigger unnecessary change detection
            if (!(this.activeStudyId.value === studyId)) {
                this.setActiveStudyId(studyId.toString());
            }
        }),
    );

    private activeStudyId = new BehaviorSubject<string>(null);

    readonly activeStudyId$: Observable<string> = combineLatest<Observable<string>, Observable<Workflow>>([
        this.activeStudyId.asObservable(),
        this.activeCollection$,
    ]).pipe(
        map(([studyId, collection]: [string, Workflow]) => {
            // retrieval of studyId is dependent upon activeCollection, which is where studyId gets set
            return studyId;
        }),
        debounceTime(1000), // wait momentarily after study change
        shareReplay(1),
    );

    readonly activeStudy$: Observable<WorkflowItem> = this.activeStudyId$.pipe(
        switchMap((studyId: string) => {
            if (studyId) {
                return this.workflowService.getWorkflowItemById(parseInt(studyId, 10));
            }
        }),
        map((resp: APIResponse<WorkflowItem>) => {
            return resp.response;
        }),
    );

    private defaultStudyStrategy$ = this.usersStore.currentUser$.pipe(
        filter((d) => !!d),
        map((user: User) => {
            let defaultCollectionId;
            this.workflowService.getLatestCompletedWorkFlow(true).subscribe((resp) => {
                if (this.latestAsDefault.value !== user.studyStrategy.latestAsDefault) {
                    this.latestAsDefault.next(user.studyStrategy.latestAsDefault);
                }
                let defaultStudyId;
                // Get defaultCollectionId, optionally get defaultStudyId
                if (user.studyStrategy.latestAsDefault) {
                    const workflow: Workflow = resp.response;

                    defaultCollectionId = workflow ? workflow.id.toString() : 'none';
                    const defaultStudy = workflow ? workflow.studies.filter((study) => study.defaultItem) : null;
                    defaultStudyId = defaultStudy ? defaultStudy[0].id.toString() : 'none';
                } else {
                    defaultCollectionId = user.studyStrategy.defaultCollectionId;
                }

                this.setDefaultCollectionId(defaultCollectionId);
                this.initActiveCollectionId(localStorage.getItem('active_collection') || defaultCollectionId, user.id);
                this.setActiveStudyId(localStorage.getItem('active_study') || defaultStudyId);
            });
            return defaultCollectionId;
        }),
        share(),
    );

    constructor(
        private usersStore: UsersStore,
        private usersService: UsersService,
        private workflowService: WorkflowService,
        private toastrService: NbToastrService,
    ) {
        super();
        combineLatest<Observable<string>, Observable<string>, Observable<string>, Observable<any>>([
            this.activeCollectionId$,
            this.defaultStudyId$,
            this.defaultCollectionId$,
            this.defaultStudyStrategy$,
        ])
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
                ([activeCollectionId, defaultStudyId, defaultCollectionId, strategy]: [
                    string,
                    string,
                    string,
                    any,
                ]) => {
                    if (defaultCollectionId === 'none') {
                        this.toastrService.warning(
                            '',
                            'There are no studies completed. ' +
                                'Please select default study on Study History page or upload input data for start new Study.',
                        );
                    }
                },
            );
    }

    public getActiveStudyId(): string {
        return this.activeStudyId.value;
    }

    setDefaultCollectionId(collectionId: string) {
        this.defaultCollectionId.next(collectionId);
    }

    private dataLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    readonly dataLoading$: Observable<boolean> = this.dataLoading.asObservable();

    initActiveCollectionId(collectionId: string, userId: string) {
        const currentActiveCollectionId: string = localStorage.getItem('active_collection');

        // No action if current collection is defined and same as new collection
        if (currentActiveCollectionId == null || currentActiveCollectionId !== collectionId) {
            localStorage.setItem('active_collection', collectionId);
            this.dataLoading.next(true);
            this.workflowService
                .updateActiveWorkflow(parseInt(collectionId, 10), userId, true)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe((res) => {
                    this.dataLoading.next(false);
                    this.activeCollectionId.next(collectionId);
                });
        } else {
            this.activeCollectionId.next(collectionId);
        }
    }

    updateActiveCollectionId(collectionId: string) {
        const currentActiveCollectionId: string = localStorage.getItem('active_collection');
        //const jwtPayload = decodeJwtPayload(localStorage.getItem('auth_app_token'));
        const userId = this.usersStore.currentUser.value.id; //jwtPayload.id;
        localStorage.setItem('active_collection', collectionId);

        this.usersStore.currentUser.value;

        if (currentActiveCollectionId == null) {
            this.initActiveCollectionId(collectionId, userId);
        } else {
            this.dataLoading.next(true);
            this.workflowService
                .updateActiveWorkflow(parseInt(currentActiveCollectionId, 10), userId, false)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe((res) => {
                    this.workflowService
                        .updateActiveWorkflow(parseInt(collectionId, 10), userId, true)
                        .pipe(takeUntil(this.unsubscribe$))
                        .subscribe((res2) => {
                            this.dataLoading.next(false);
                            this.activeCollectionId.next(collectionId);
                        });
                });
        }
    }

    setActiveStudyId(studyId: string) {
        localStorage.setItem('active_study', studyId);
        this.activeStudyId.next(studyId);
    }

    clearActiveCollectionId() {
        const collectionId = localStorage.getItem('active_collection');
        const userId = this.usersStore.currentUser.value.id;
        localStorage.removeItem('active_collection');
        localStorage.removeItem('active_study');

        this.workflowService
            .updateActiveWorkflow(parseInt(collectionId, 10), userId, false)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((res) => {
                this.activeCollectionId.next(null);
            });
    }

    updateDefaultStudyStrategy(value: boolean) {
        const ret = this.usersService.updateDefaultStudyStrategy(value, this.defaultCollectionId.value);
        this.usersStore.updateUserDefaultStudyStrategy(value, this.defaultCollectionId.value); // force user data refresh to fetch the changes
        return ret;
    }
}
