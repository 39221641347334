<div class="container p-0 mx-1" *ngIf="nameWithIcon; else iconOnly">
    <!-- Render icon with name provided -->
    <div class="row name-with-icons">
        <div class="col name-with-icons-col">
            <ngx-icon-box icon="{{ this.iconList }}" class="icon {{ this.class }}"> </ngx-icon-box>
            <span class="text">{{ name | titlecase }}</span>
        </div>
    </div>
</div>

<!-- Render only an icon -->
<ng-template #iconOnly>
    <div class="row">
        <div class="col m-0 p-0 {{ this.class }}">
            <div class="dot"></div>
            <ngx-icon-box icon="{{ this.iconList }}" class="icons"> </ngx-icon-box>
        </div>
    </div>
</ng-template>
