import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {Unsubscribable} from '../../../@core/interfaces/unsubscribable';
import {AuthService, PasswordRequirementsWrapper} from '../../../@core/interfaces/common/auth';
import {passwordData, UsersService} from '@core/interfaces/common/users';
import {BehaviorSubject, combineLatest, Observable, of} from 'rxjs';
import {APIResponse} from '@core/interfaces/system/system-common';
import {PasswordRequirements} from '@core/interfaces/common/tenantSettings';
import {filter, map, switchMap} from 'rxjs/operators';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

/**
 * Password Reset
 * - Pre-auth (no login required)
 * - User lands here from password reset process (via email link). Automatically grabs token and resets password.
 */
@Component({
    selector: 'ngx-auth-reset-password',
    templateUrl: './reset-password.component.html',
})
export class ResetPasswordComponent extends Unsubscribable implements OnInit {
    // Success / fail messages from API
    showMessages: any = {
        success: true,
        error: true,
    };
    errors: string[] = [];
    messages: string[] = [];
    submitted: boolean = false;

    token: string;
    tokenValid: boolean = true;

    // Password data
    private passwordValidation = new BehaviorSubject<PasswordRequirements>(null);
    readonly passwordValidation$: Observable<any> = this.passwordValidation.asObservable().pipe(
        filter((item) => item !== null),
        map((config) => {
            return config;
        }),
    );
    // Password reset form
    formReady = new BehaviorSubject<boolean>(false);
    resetPasswordForm: FormGroup = this.fb.group({
        newPassword: ['', [Validators.required]],
        confirmPassword: ['', [Validators.required]],
    });

    constructor(
        protected cd: ChangeDetectorRef,
        private route: ActivatedRoute,
        private fb: FormBuilder,
        private authService: AuthService,
    ) {
        super();
    }
    ngOnInit() {
        const token: string = this.route.snapshot.queryParamMap.get('token');
        this.token = token;

        this.checkValidation('');

        combineLatest<Observable<PasswordRequirements>, Observable<any>>([
            this.passwordValidation,
            this.resetPasswordForm.statusChanges,
        ])
            .pipe(
                filter(([requirements, _]: [PasswordRequirements, any]) => requirements !== null),
                switchMap(([requirements, formValid]: [PasswordRequirements, any]): Observable<boolean> => {
                    return of(requirements.validation && formValid === 'VALID');
                }),
            )
            .subscribe((isReady: boolean) => {
                this.formReady.next(isReady);
            });
    }

    public onInputChanges(event: any) {
        this.checkValidation(event.password);
        this.cd.markForCheck();
    }

    private checkValidation(newValue) {
        this.authService
            .passwordValidationByToken(this.token, newValue)
            .subscribe((res: APIResponse<PasswordRequirementsWrapper>) => {
                this.tokenValid = res.response.found;
                this.passwordValidation.next(res.response.data);
            });
    }

    public submit() {
        this.errors = this.messages = [];
        this.submitted = true;

        let data = this.resetPasswordForm.value as passwordData;

        if (this.tokenAvailable()) {
            this.authService
                .resetPasswordWithToken(this.token, data.newPassword, data.confirmPassword)
                .subscribe((resp) => {
                    const result = resp.response;
                    if (result && result.success) {
                        this.messages = result.messages;
                    } else {
                        this.errors = result.errors;
                        this.submitted = false; // allow re-submit
                    }
                    this.cd.detectChanges();
                });
        }
    }

    private tokenAvailable() {
        return this.token != null && this.token !== '';
    }
}
