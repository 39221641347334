import {Injectable} from '@angular/core';
import {HttpService} from '../../../common/api/http.service';
import {Observable} from 'rxjs';
import {
    DataAuditChartResponse,
    DataAuditContextResponse,
    DataAuditHistoryResponse,
    DataAuditRequest,
    DataAuditStudyDataQuality,
} from '../../../../interfaces/engin/data-audit/asset-data-audit';

@Injectable()
export class AssetDataAuditApi {
    private readonly prefix = 'asset-audit';

    constructor(private api: HttpService) {}

    // Data audit tool
    getContextData(req: DataAuditRequest): Observable<DataAuditContextResponse> {
        return this.api.post(`${this.prefix}/support/context`, req);
    }

    getRecentHistory(req: DataAuditRequest): Observable<DataAuditHistoryResponse> {
        return this.api.post(`${this.prefix}/support/history`, req);
    }

    getMetricChartByLoad(req: DataAuditRequest): Observable<DataAuditChartResponse> {
        return this.api.post(`${this.prefix}/charts/load`, req);
    }

    getMetricChartByAsset(req: DataAuditRequest): Observable<DataAuditChartResponse> {
        return this.api.post(`${this.prefix}/charts/asset`, req);
    }

    getMetricChartByPoint(req: DataAuditRequest): Observable<DataAuditChartResponse> {
        return this.api.post(`${this.prefix}/charts/parameter`, req);
    }

    getMetricChartByAnalytic(req: DataAuditRequest): Observable<DataAuditChartResponse> {
        return this.api.post(`${this.prefix}/charts/analytic`, req);
    }

    // Data quality widget
    getWorkflowDataQuality(workflowId: string, req: DataAuditRequest): Observable<DataAuditStudyDataQuality> {
        return this.api.post(`${this.prefix}/support/quality/workflow/${workflowId}`, req);
    }
}
