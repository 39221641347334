<!-- Table card -->
<nb-card>
    <!-- Header -->
    <nb-card-header *ngIf="enableHeader()">
        <!-- Header part 1: title, subtitle. [Optional] Global search -->
        <div [ngClass]="!subTitle ? 'singleTitle': ''">
            <div class="primary-header">{{title}}</div>
            <div *ngIf="subTitle" class="sub-header">{{subTitle}}</div>
        </div>

        <div class="display-flex">
            <input
                *ngIf="disableGlobalSearch()"
                nbInput
                #search
                type="text"
                placeholder="Search..."
                (blur)="onSearch(search.value)"
            />

            <form class="header-simple-pager" [formGroup]="pagingFormGroup" *ngIf="enablePagePerRecord()">
                <label class="simple-pager-label">Records on page</label>
                <input
                    nbInput
                    formControlName="perPage"
                    type="number"
                    [min]="1"
                    [max]="99"
                    pattern="[0-99]*"
                    placeholder="Rows"
                    class="simple-pager-input"
                />
            </form>

            <div class="vertical-separator" *ngIf="enablePagePerRecord()"></div>
            <div class="header-side-container">
                <div class="col-1 header-refresh-button" *ngIf="enablePagePerRecord()">
                    <button nbButton (click)="refresh()">
                        <nb-icon class="icon" icon="refresh" [pack]="'eva'"></nb-icon>
                    </button>
                </div>
                <div class="col-1 header-setting-button" *ngIf="enableTableSetting()">
                    <ngx-table-settings (downloadFile)="this.downloadFile.emit(true)"> </ngx-table-settings>
                </div>
            </div>
        </div>
    </nb-card-header>

    <!-- Body -->
    <nb-card-body>
        <ngx-smart-table
            [settings]="settings"
            [source]="data"
            (custom)="onCustomEventClick($event)"
            (onFilter)="onFilter($event)"
            (userRowSelect)="onUserRowSelect($event)"
        >
        </ngx-smart-table>
    </nb-card-body>
    <!-- Footer -->
</nb-card>
