import {Component, Input, ChangeDetectionStrategy} from '@angular/core';

@Component({
    selector: 'ngx-value-box',
    templateUrl: './value-box.component.html',
    styleUrls: ['./value-box.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValueBoxComponent {
    @Input() title: string;
    @Input() valuePrefix: string;
    @Input() valueSuffix: string;
    @Input() formattedValue: string;
    @Input() value: number;

    constructor() {}
}
