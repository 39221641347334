import {Observable} from 'rxjs';
import {Report} from '@core/interfaces/common/pages';

export class ReportRequest {
    reportId: string;
    templateFlag: boolean;
    studyId: string;
    userId: string;
    sensitivityFlag: boolean;

    constructor(report: Report, studyId: string, userId: string, sensitivityFlag: boolean) {
        this.reportId = report ? report.reportId : '';
        this.templateFlag = report ? report.templateFlag : true;
        this.studyId = studyId;
        this.userId = userId;
        this.sensitivityFlag = sensitivityFlag;
    }
}

export interface OutputReportSource {
    links: [string];
}

export abstract class ReportingService {
    abstract getOutputReportGeneric(reportRequest: ReportRequest): Observable<OutputReportSource>;

    abstract getOutputReportAnalyzer(studyId: string, filters: string[]): Observable<OutputReportSource>;
}
