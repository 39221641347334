import {APP_BASE_HREF} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {ThemeModule} from './@theme/theme.module';
import {CoreModule} from './@core/core.module';
import {AuthModule} from './@auth/auth.module';
import {StoreModule} from './@store';
import {PagesComponent} from './pages/pages.component';
import {DashboardComponent} from './pages/dashboard/dashboard.component';
import {HeaderComponent, ReportFilterDialogComponent} from './@theme/components';
import {SidebarActionComponent} from './@theme/components';
import {NbCardModule, NbIconModule, NbSelectModule, NbWindowModule} from '@nebular/theme';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {ChartLibModule} from './chart-lib/chart-lib.module';
import {HttpErrorInterceptor} from './@core/error-handling/http-error.interceptor';
import {UpdatePasswordComponent} from './pages/auth/update-password/update-password.component';
import {ForgotPasswordComponent} from './pages/auth/forgot-password/forgot-password.component';
import {ResetPasswordComponent} from './pages/auth/reset-password/reset-password.component';
import {VerifyEmailComponent} from './pages/auth/verify-email/verify-email.component';
import {LoginComponent} from './pages/auth/login-form/login.component';
import {SwiperModule} from 'swiper/angular';
import {SelectOrganizationComponent} from './pages/auth/select-organization/select-organization.component';

const PAGES = [
    DashboardComponent,
    LoginComponent,
    SelectOrganizationComponent,
    UpdatePasswordComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    VerifyEmailComponent,
];

@NgModule({
    declarations: [AppComponent, PagesComponent, HeaderComponent, DashboardComponent, ...PAGES],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,
        StoreModule.forRoot(),
        ThemeModule.forRoot(),
        AuthModule.forRoot(),
        CoreModule.forRoot(),
        NbCardModule,
        NbIconModule,
        NbWindowModule.forRoot(),
        NbSelectModule,
        NgxDatatableModule,
        ChartLibModule,
        SwiperModule,
        ThemeModule,
    ],
    bootstrap: [AppComponent],
    entryComponents: [SidebarActionComponent, ReportFilterDialogComponent],
    providers: [
        {provide: APP_BASE_HREF, useValue: '/'},
        {provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true},
    ],
})
export class AppModule {}
