<!--<ng-container *ngIf="!dataset else filureCurve">-->
<div class="ngx-sc-table-container" *ngIf="!dataset[0].dataType; else datasetSnaptshotData">
    <table class="table .w-auto bg-transparent">
        <thead>
            <tr>
                <th
                    scope="col"
                    *ngFor="let column of tableSettings.columns"
                    ngxScSortable
                    [sortable]="column.dataKey"
                    (sort)="onSort($event)"
                    [ngClass]="column.bsClass"
                >
                    {{ column.title }}
                </th>
            </tr>
        </thead>
        <tbody>
            <ng-container *ngFor="let dataRow of dataset; let i = index">
                <tr>
                    <td *ngFor="let columnSetting of tableSettings.columns" [ngClass]="columnSetting.bsClass">
                        <ng-container [ngSwitch]="columnSetting.type">
                            <ng-container *ngSwitchCase="COLUMN_TYPE.DATA">
                                <ngb-highlight [result]="dataRow[columnSetting.dataKey]"></ngb-highlight>
                            </ng-container>
                            <ng-container *ngSwitchCase="COLUMN_TYPE.COMPONENT">
                                <ngx-sc-table-container
                                    [rowData]="dataRow"
                                    [rowIndex]="i"
                                    [dataKey]="columnSetting.dataKey"
                                    [value]="dataRow[columnSetting.dataKey]"
                                    [renderComponent]="columnSetting.renderComponent"
                                >
                                </ngx-sc-table-container>
                            </ng-container>
                            <!--                    <ng-container *ngSwitchCase="'expendable'">-->
                            <!--                        <button (click)="dataRow[columnSetting.dataKey].isCollapsed = !dataRow[columnSetting.dataKey].isCollapsed">-->
                            <!--                            +/- -->
                            <!--                        </button>-->
                            <!--                    </ng-container>-->
                        </ng-container>
                    </td>
                </tr>
                <!--        <tr class="d-flex">-->
                <!--            <ng-container *ngFor="let columnSettingExp of tableSettings.columns">-->
                <!--                <ng-container [ngSwitch]="columnSettingExp.type">c-->
                <!--                    <ng-container *ngSwitchCase="'expendable'">-->
                <!--                        <td id="{{'row_'+i+'_'+columnSettingExp.dataKey}}"-->
                <!--                            [ngbCollapse]="!dataRow[columnSettingExp.dataKey].isCollapsed">-->
                <!--                            <ngx-ngx-sc-table-simple></ngx-ngx-sc-table-simple>-->

                <!--                        </td>-->
                <!--                    </ng-container>-->
                <!--                </ng-container>-->

                <!--            </ng-container>-->
                <!--        </tr>-->
            </ng-container>
        </tbody>
    </table>
</div>

<ng-template #datasetSnaptshotData>
    <div class="ngx-sc-table-container">
        <table>
            <thead>
                <tr class="d-flex">
                    <th
                        scope="col"
                        *ngFor="let column of tableSettings.columns"
                        ngxScSortable
                        [sortable]="column.dataKey"
                        (sort)="onSort($event)"
                        [ngClass]="column.bsClass"
                    >
                        {{ column.title }}
                    </th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let dataRow of dataset; let i = index">
                    <tr class="d-flex" [ngClass]="{'tr-stripped': i % 2 === 0}">
                        <td *ngFor="let columnSetting of tableSettings.columns" [ngClass]="columnSetting.bsClass">
                            <ng-container [ngSwitch]="columnSetting.type">
                                <ng-container *ngSwitchCase="COLUMN_TYPE.DATA">
                                    <!--                            <ngb-highlight  [result]="dataRow[columnSetting.dataKey]"></ngb-highlight>-->
                                    <ngb-highlight
                                        [result]="
                                            renderValue(
                                                columnSetting.valuePrepareFunction,
                                                dataRow[columnSetting.dataKey]
                                            )
                                        "
                                    ></ngb-highlight>
                                </ng-container>
                                <ng-container *ngSwitchCase="COLUMN_TYPE.COMPONENT">
                                    <ngx-sc-table-container
                                        [rowData]="dataRow"
                                        [rowIndex]="i"
                                        [dataKey]="columnSetting.dataKey"
                                        [value]="dataRow[columnSetting.dataKey]"
                                        [renderComponent]="columnSetting.renderComponent"
                                    >
                                    </ngx-sc-table-container>
                                </ng-container>
                            </ng-container>
                        </td>
                    </tr>
                    <!--        <tr class="d-flex">-->
                    <!--            <ng-container *ngFor="let columnSettingExp of tableSettings.columns">-->
                    <!--                <ng-container [ngSwitch]="columnSettingExp.type">c-->
                    <!--                    <ng-container *ngSwitchCase="'expendable'">-->
                    <!--                        <td id="{{'row_'+i+'_'+columnSettingExp.dataKey}}"-->
                    <!--                            [ngbCollapse]="!dataRow[columnSettingExp.dataKey].isCollapsed">-->
                    <!--                            <ngx-ngx-sc-table-simple></ngx-ngx-sc-table-simple>-->

                    <!--                        </td>-->
                    <!--                    </ng-container>-->
                    <!--                </ng-container>-->

                    <!--            </ng-container>-->
                    <!--        </tr>-->
                </ng-container>
            </tbody>
        </table>
    </div>
</ng-template>

<!--</ng-container>-->

<!--<ng-template #filureCurve>-->
<!--<table>-->
<!--  <tbody>-->
<!--  <ng-container *ngFor="let dataRow of dataset; let i = index">-->
<!--    <tr >-->
<!--      <td *ngFor="let columnSetting of tableSettings.columns" [ngClass]="columnSetting.bsClass">-->

<!--        <ng-container [ngSwitch]="columnSetting.type">-->
<!--          <ng-container *ngSwitchCase="COLUMN_TYPE.DATA">-->
<!--            <ngb-highlight [result]="dataRow[columnSetting.dataKey]"></ngb-highlight>-->
<!--          </ng-container>-->
<!--          <ng-container *ngSwitchCase="COLUMN_TYPE.COMPONENT">-->
<!--            <ngx-sc-table-container-->
<!--              [rowData]="dataRow"-->
<!--              [rowIndex]="i"-->
<!--              [dataKey]="columnSetting.dataKey"-->
<!--              [value]="dataRow[columnSetting.dataKey]"-->
<!--              [renderComponent]="columnSetting.renderComponent"-->
<!--            >-->
<!--            </ngx-sc-table-container>-->

<!--          </ng-container>-->
<!--        </ng-container>-->
<!--      </td>-->
<!--    </tr>-->
<!--  </ng-container>-->
<!--  </tbody>-->
<!--  </table>-->
<!--</ng-template>-->
