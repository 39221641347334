import {ChangeDetectionStrategy, Component, ElementRef, forwardRef, HostListener, ViewChild} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
    selector: 'ngx-file-upload',
    template: `
        <input type="file" #input hidden />
        <button nbButton (click)="input.click()" size="small" status="primary">
            <ngx-icon-box title="New Upload" icon="upload"> </ngx-icon-box>
        </button>
    `,
    providers: [{provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => FileUploadComponent), multi: true}],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileUploadComponent implements ControlValueAccessor {
    onChange: Function;
    @ViewChild('input') fileInput: ElementRef;

    @HostListener('change', ['$event.target.files']) emitFiles(event: FileList) {
        const file = event && event.item(0);
        this.onChange(file);
    }

    writeValue(value: null) {
        if (this.fileInput && this.fileInput.nativeElement) {
            this.fileInput.nativeElement.value = '';
        }
    }

    registerOnChange(fn: Function) {
        this.onChange = fn;
    }

    registerOnTouched(fn: Function) {}
}
