import {Injectable} from '@angular/core';
import {RequestTemplate} from './request-template';
import {AnalyzerRequest} from '../../../@core/interfaces/engin/analyzer';
import {ReportDataApi} from '../api/report-data-api';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable()
export class ReportRequestComposer {
    constructor(private reportDataApi: ReportDataApi) {}

    public studySelected = new BehaviorSubject<boolean>(false);
    public requiredFlag = new BehaviorSubject<boolean>(false);
    public editableFlag = new BehaviorSubject<boolean>(false);

    retrieveDataResponse(workflowItemId, measure, group): Observable<any[]> {
        let req: any;
        let res: Observable<any[]>;

        switch (measure.code) {
            case 'condition':
                req = RequestTemplate.Analyzer;
                req.groupList = this.renderGroups(group, measure.groups);
                req.filterList = this.renderFilters(measure.filters);

                /*
                 * Prepare custom data required in backend:
                 * - pageId & tabId direct to the proper endpoint (i.e., where was this source graphic taken from in ENGIN)
                 * - graphList determines the response: one element in response for each element of request
                 */
                switch (group.code) {
                    case 'grid':
                        req.pageId = 'eolmetrics'; // custom data used in backend
                        req.tabId = 'summary'; // custom data used in backend
                        req.graphList = RequestTemplate.Analyzer_Condition_Summary;
                        res = this.reportDataApi.getAnalyzerAbstractGraphData(req, workflowItemId.toString());
                        break;
                    default:
                        req.pageId = 'eolmetrics'; // custom data used in backend
                        req.tabId = 'health'; // custom data used in backend
                        req.graphList = RequestTemplate.Analyzer_Condition_Status;
                        res = this.reportDataApi.getAnalyzerAbstractGraphData(req, workflowItemId.toString());
                        break;
                }
                break;
            default:
                req = new AnalyzerRequest(
                    this.renderGroups(group, measure.groups),
                    this.renderFilters(measure.filters),
                    RequestTemplate.Analyzer.sensitivityList,
                    RequestTemplate.Analyzer.inputList,
                );

                res = this.reportDataApi.getAnalyzerAbstractGraphData(req, workflowItemId.toString());
        }
        return res;
    }

    private renderFilters(filters: any[]) {
        const requestFilters = [];
        filters.forEach((filter) => {
            const requestFilter = {};
            requestFilter['fieldPhysical'] = filter.code.toLowerCase();
            requestFilter['options'] = {};

            filter.options.forEach((filterOptionItem) => {
                requestFilter['options'][filterOptionItem.code] = !!filterOptionItem.selected;
            });

            requestFilters.push(requestFilter);
        });
        return requestFilters;
    }

    private renderGroups(curGrouping: any, groups: any[]) {
        const requestGroups = [];
        groups.forEach((groupItem) => {
            const requestGroup = {};
            requestGroup['fieldPhysical'] = groupItem.code.toLowerCase();
            if (curGrouping.code === groupItem.code) {
                requestGroup['selected'] = !!curGrouping.selected;
            } else {
                requestGroup['selected'] = false;
            }
            requestGroups.push(requestGroup);
        });

        return requestGroups;
    }
}
