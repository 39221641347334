import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {DataUpload, LegacyUploadsService, UploadData} from '@core/interfaces/common/legacyUploads';
import {LegacyUploadsApi} from '../api/legacyUploads.api';

@Injectable()
export class LegacyUploadsServiceImpl extends LegacyUploadsService {
    constructor(private api: LegacyUploadsApi) {
        super();
    }

    uploadFCFile(dataType: UploadData, fileData: FormData, purge: boolean, audit: boolean): Observable<DataUpload> {
        return this.api.uploadFCFile(dataType, fileData, purge, audit);
    }
}
