import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'ngx-chart-simple-legend-bar',
    templateUrl: './chart-simple-legend-bar.component.html',
    styleUrls: ['./chart-simple-legend-bar.component.scss'],
})
export class ChartSimpleLegendBarComponent {
    @Input() legendColorArray; // array of {name: '', color: ''}
    constructor() {}
}
