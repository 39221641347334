import {SortColumn} from '../ngx-sc-table-directive/ngx-sc-sortable.directive';
import {SortDirection} from '@swimlane/ngx-datatable';

export class SCTableSettings {
    perPage: number;
    showItemOnPage;
    showTopPager;
    showBottomPager;
    showHeader;
    // ========
    showFullTextSearch;
    // ========
    columns: SCTableColumn[];
}

export class SCTableColumn {
    bsClass: string;

    title: string;
    iconWithName: boolean;
    dataKey: string;
    dropSort: boolean;
    titleSquared: number;
    link: string;
    triggerBeforeNavigation: any;
    disableDetailsFunction: any;

    filter: boolean;
    // ========
    type: SCTableColumnType;

    // data
    valuePrepareFunction: any;

    // customize component
    renderComponent: any;
    onComponentInitFunction: any;

    // expendable
    columns: SCTableColumnExpandable[];
}

export class SCTableColumnExpandable extends SCTableColumn {
    // expendable
    isCollapsed: boolean;
}

export enum SCTableColumnType {
    DATA = 'data',
    CUSTOM = 'custom',
    COMPONENT = 'component',
    EXPANDABLE = 'expandable',
    EXPANDABLE_CONTAINER = 'expandable_container',
    CUSTOM_CONTAINER = 'custom_container',
    EXPANDABLE_CONTAINER_WITH_CHART = 'expandable_container_with_chart',
}

export interface SearchResult {
    dataset: any[];
    total: number;
}

export interface State {
    pagesCount: number;
    pageIndex: number;
    perPage: number;
    searchTerm: string;
    searchTermMap: Map<string, string>;
    sortColumn: SortColumn;
    sortDirection: SortDirection;
}

// ========
export interface CustomizedFieldInterface {
    value: any;
    rowData: any;
    dataKey: any;
    // rowIndex: number;
    // rendered: boolean;
}
