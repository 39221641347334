import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject} from '@angular/core';
import {Router} from '@angular/router';
import {User, UsersService} from '@core/interfaces/common/users';
import {NB_AUTH_OPTIONS, NbAuthResult, NbAuthService, NbLoginComponent} from '@nebular/auth';
import {filter} from 'rxjs/operators';

/**
 * Login
 * - Pre-auth (no login required)
 * - User enters this page to start login process.
 */
@Component({
    selector: 'ngx-auth-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent extends NbLoginComponent {
    constructor(
        service: NbAuthService,
        @Inject(NB_AUTH_OPTIONS) options: {},
        cd: ChangeDetectorRef,
        router: Router,
        private userService: UsersService,
    ) {
        super(service, options, cd, router);
    }

    login(): void {
        this.errors = [];
        this.messages = [];
        this.submitted = true;

        let param = {
            email: this.user.username,
            password: this.user.password,
        };

        this.service.authenticate(this.strategy, param).subscribe((result: NbAuthResult) => {
            this.submitted = false;
            localStorage.setItem('fromLogin', JSON.stringify(true));

            if (result.isSuccess()) {
                this.messages = result.getMessages();
                this.userService
                    .getCurrentUser()
                    .pipe(filter((user: User) => user != null))
                    .subscribe((user: User) => {
                        if (user.passwordExpired) {
                            this.router.navigate(['/auth/update-password']);
                        } else if (user.tenants.length > 1) {
                            this.router.navigate(['auth/select-organization']);
                        } else if (result.getRedirect()) {
                            setTimeout(() => {
                                return this.router.navigateByUrl(result.getRedirect());
                            }, this.redirectDelay);
                        }
                    });
            } else {
                this.errors = result.getErrors();
            }

            this.cd.detectChanges();
        });
    }
}
