import {
    Component,
    ComponentFactoryResolver,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
// templateUrl: './ngx-sc-table-container.component.html',
// styleUrls: ['./ngx-sc-table-container.component.scss'],

@Component({
    selector: 'ngx-sc-table-container',
    template: ` <ng-template #dynamicTarget></ng-template> `,
})
export class NgxScTableContainerComponent implements OnInit, OnDestroy {
    @Input() rowData;
    @Input() rowIndex;
    @Input() dataKey;
    @Input() value;
    @Input() renderComponent;
    @Input() onComponentInitFunction;

    @ViewChild('dynamicTarget', {read: ViewContainerRef, static: true}) dynamicTarget: any;
    customComponent: any;

    constructor(private resolver: ComponentFactoryResolver) {}

    ngOnInit() {
        // if (this.cell && !this.customComponent) {
        this.createCustomComponent();
        this.callOnComponentInit();
        this.patchInstance();
        // }
    }

    ngOnDestroy() {
        if (this.customComponent) {
            this.customComponent.destroy();
        }
    }

    protected createCustomComponent() {
        const componentFactory = this.resolver.resolveComponentFactory(this.renderComponent);
        this.customComponent = this.dynamicTarget.createComponent(componentFactory);
        // if (this.onComponentInitFunction != null) {
        //     this.onComponentInitFunction(this.customComponent.instance);
        // }
    }

    protected callOnComponentInit() {
        const onComponentInitFunction = this.onComponentInitFunction;
        onComponentInitFunction && onComponentInitFunction(this.customComponent.instance);
    }

    protected patchInstance() {
        Object.assign(this.customComponent.instance, this.getPatch());
    }

    protected getPatch() {
        return {
            value: this.value,
            rowData: this.rowData,
            dataKey: this.dataKey,
        };
    }
}
