import {Observable} from 'rxjs';
import {DataUploadRecord} from '../../../pages/survival-analysis/api/pojo/failure-data-management.pojo';
import {SurvivalStudyRequest} from '../../../pages/survival-analysis/api/pojo/survival-study.pojo';
import {CurveCalibrationDetails} from '../../../pages/survival-analysis/api/pojo/curve-calibration-outputs.pojo';
import {DownloadLink} from '../common/legacyUploads';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';

export abstract class SurvivalAnalysisService {
    abstract getUploadHistoryData(): Observable<DataUploadRecord[]>;

    abstract getUploadDatatableHistory(): Observable<DataSource>;

    abstract getAnalysisHistoryData(): Observable<DataSource>;

    abstract getCurveCalibrationData(survivalStudyId: string): Observable<CurveCalibrationDetails[]>;

    abstract getSurvivalStudyEditAvailable(survivalStudyId: string): Observable<boolean>;

    abstract updateDataUploadComment(uploadId: string, newComment: string): Observable<boolean>;

    abstract updateCurveCalibrationComment(studyId: string, curveId: number, newComment: string): Observable<boolean>;

    abstract updateCurveCalibrationActions(
        studyId: string,
        curveId: number,
        isAccepted: boolean,
        isRejected: boolean,
    ): Observable<boolean>;

    abstract executeNewSurvivalStudy(req: SurvivalStudyRequest): Observable<any>;

    abstract submitSurvivalStudy(studyId: string): Observable<any>;

    abstract downloadFile(fileKey: string, medium: string, type: string): Observable<DownloadLink>;
}
