import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {PagesStore, SidebarStore} from '../../../@store';
import {Org, UsersStore} from '../../../@store/common/users.store';
import {environment} from '../../../../environments/environment';
import {Unsubscribable} from '@core/interfaces/unsubscribable';
import {filter, takeUntil} from 'rxjs/operators';
import {User} from '@core/interfaces/common/users';
import {BehaviorSubject, Observable} from 'rxjs';

@Component({
    selector: 'ngx-header',
    styleUrls: ['./header.component.scss'],
    templateUrl: './header.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent extends Unsubscribable implements OnInit {
    @Input() currentUser: any;

    constructor(public pagesStore: PagesStore,
                public sidebarStore: SidebarStore,
                public userStore: UsersStore) {
        super();
    }

    ngOnInit() {
        this.userStore.currentUser$
            .pipe(
                takeUntil(this.unsubscribe$),
                filter((u) => u && !!u),
            )
            .subscribe((user: User) => {
                const org: Org = this.userStore.checkCurrentOrg(user);
                this.isOrgEii.next(org == Org.EII);
                this.isOrgSwi.next(org == Org.SWI);
                this.isOrgMes.next(org == Org.MES || org == Org.BBA);
            });
    }

    staging: boolean = environment.staging;

    private isOrgEii: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    readonly isOrgEii$: Observable<boolean> = this.isOrgEii.asObservable();

    private isOrgSwi: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    readonly isOrgSwi$: Observable<boolean> = this.isOrgSwi.asObservable();

    private isOrgMes: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    readonly isOrgMes$: Observable<boolean> = this.isOrgMes.asObservable();
}
