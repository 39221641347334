import {Component, Injector, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {User, UsersService} from '@core/interfaces/common/users';
import {BehaviorSubject} from 'rxjs';
import {Unsubscribable} from '@core/interfaces/unsubscribable';
import {AuthService} from '@core/interfaces/common/auth';
import {takeUntil} from 'rxjs/operators';
import {NbAuthOAuth2Token, NbAuthToken, NbTokenService} from '@nebular/auth';

@Component({
    selector: 'ngx-select-organization',
    templateUrl: './select-organization.component.html',
    styleUrls: ['./select-organization.component.scss'],
})
export class SelectOrganizationComponent extends Unsubscribable implements OnInit {
    public organizationList = new BehaviorSubject<{value: string; label: string}[]>(null);
    public currentOrganization: string;

    constructor(
        private router: Router,
        private userService: UsersService,
        private authService: AuthService,
        private injector: Injector,
    ) {
        super();
    }

    ngOnInit() {
        this.userService
            .getCurrentUser()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((user: User) => {
                const tenantList: {value: string; label: string}[] = user.tenants.map((tenant) => {
                    return {
                        value: tenant,
                        label: tenant,
                    };
                });
                this.organizationList.next(tenantList);
                this.currentOrganization = user.activeTenantId;
            });
    }

    onOrganizationChange(value) {
        this.currentOrganization = value;
    }

    login() {
        if (this.currentOrganization) {
            this.authService
                .switchOrganization(this.currentOrganization)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe((resp: any) => {
                    // Manually reset the auth token
                    const token = new NbAuthOAuth2Token(resp, 'email');
                    this.nbTokenService.set(token);
                    // Route to dashboard
                    this.router.navigateByUrl('/dashboard');
                });
        }
    }

    protected get nbTokenService(): NbTokenService {
        return this.injector.get(NbTokenService);
    }
}
