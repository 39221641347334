import {Injectable} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {debounceTime, shareReplay, switchMap, tap, takeUntil} from 'rxjs/operators';
import {
    OptimizerService,
    OptimizerChartRequest,
    OptimizerChartResponse,
    OptimizerKpiResponse,
    OptimizerAnalysisRequest,
} from '../../@core/interfaces/engin/optimizer';
import {StudiesStore} from '../common/studies.store';
import {OptimizerPopoutStore} from './optimizer-popout.store';
import {User, UsersService} from '../../@core/interfaces/common/users';
import {DisplaySettings} from '../../@core/interfaces/common/pages';
import {PagesStore} from '../config/pages.store';
import {Unsubscribable} from '../../@core/interfaces/unsubscribable';
import {NbToastrService} from '@nebular/theme';

@Injectable()
export class OptimizerStore extends Unsubscribable {
    public resultsLoading = new BehaviorSubject<boolean>(false);
    public resultsLoading$ = this.resultsLoading.asObservable();

    private combineAnalysisRequestInfo$ = combineLatest<
        Observable<OptimizerAnalysisRequest>,
        Observable<string>,
        Observable<User>
    >([this.optimizerPopoutStore.serverPopout$, this.studiesStore.activeStudyId$, this.userService.getCurrentUser()]);

    // Check if calculations have been run before fetching any results
    readonly checkResultsReady$: Observable<boolean> = this.combineAnalysisRequestInfo$.pipe(
        tap(() => this.resultsLoading.next(true)),
        debounceTime(2000), // await cascading changes since serverPopout$ depends on activeStudyId$
        switchMap(([popoutValue, studyId, user]) => {
            const userId = user.id;
            const stateChanged = this.optimizerPopoutStore.stateChanged.value;
            this.optimizerPopoutStore.stateChanged.next({value: false, events: false});
            const req = this.prepareOptimizerCalculationRequest(popoutValue, stateChanged.value, studyId, userId);
            return this.optimizerService.checkOptimizerResultsReady(req);
        }),
        takeUntil(this.unsubscribe$),
        tap(() => {
            this.resultsLoading.next(false);
        }),
        shareReplay(1),
    );

    // TODO: get current status, filter for true; get data load, filter for false
    private combineChartRequestInfo$ = combineLatest<
        Observable<string>,
        Observable<User>,
        Observable<DisplaySettings>,
        Observable<boolean>
    >([
        this.studiesStore.activeStudyId$,
        this.userService.getCurrentUser(),
        this.pagesStore.currentDisplay$,
        this.checkResultsReady$,
    ]);

    // Investment plan
    readonly chartDataSummarySpending$: Observable<OptimizerChartResponse> = this.combineChartRequestInfo$.pipe(
        switchMap(([studyId, user, displaySettings, resultsReady]) => {
            const userId = user.id;
            const req = this.prepareOptimizerChartRequest(studyId, displaySettings, userId);
            return this.optimizerService.getChartDataSummarySpending(req);
        }),
        takeUntil(this.unsubscribe$),
    );
    readonly chartDataSummaryOutcomes$: Observable<OptimizerKpiResponse> = this.combineChartRequestInfo$.pipe(
        switchMap(([studyId, user, displaySettings, resultsReady]) => {
            const userId = user.id;
            const req = this.prepareOptimizerChartRequest(studyId, displaySettings, userId);
            return this.optimizerService.getChartDataSummaryOutcomes(req);
        }),
        takeUntil(this.unsubscribe$),
    );

    // Investment forecast breakdown
    readonly chartDataProactiveForecast$: Observable<OptimizerChartResponse> = this.combineChartRequestInfo$.pipe(
        switchMap(([studyId, user, displaySettings, resultsReady]) => {
            const userId = user.id;
            const req = this.prepareOptimizerChartRequest(studyId, displaySettings, userId);
            return this.optimizerService.getChartDataProactiveForecast(req);
        }),
        takeUntil(this.unsubscribe$),
    );
    readonly chartDataReactiveForecast$: Observable<OptimizerChartResponse> = this.combineChartRequestInfo$.pipe(
        switchMap(([studyId, user, displaySettings, resultsReady]) => {
            const userId = user.id;
            const req = this.prepareOptimizerChartRequest(studyId, displaySettings, userId);
            return this.optimizerService.getChartDataReactiveForecast(req);
        }),
        takeUntil(this.unsubscribe$),
    );

    // Failure forecast breakdown
    readonly chartDataFailureForecast$: Observable<OptimizerChartResponse> = this.combineChartRequestInfo$.pipe(
        switchMap(([studyId, user, displaySettings, resultsReady]) => {
            const userId = user.id;
            const req = this.prepareOptimizerChartRequest(studyId, displaySettings, userId);
            return this.optimizerService.getChartDataFailureForecast(req);
        }),
        takeUntil(this.unsubscribe$),
    );
    readonly chartDataOutageForecast$: Observable<OptimizerChartResponse> = this.combineChartRequestInfo$.pipe(
        switchMap(([studyId, user, displaySettings, resultsReady]) => {
            const userId = user.id;
            const req = this.prepareOptimizerChartRequest(studyId, displaySettings, userId);
            return this.optimizerService.getChartDataOutageForecast(req);
        }),
        takeUntil(this.unsubscribe$),
    );

    // Risk forecast breakdown
    readonly chartDataRiskForecastByImpact$: Observable<OptimizerChartResponse> = this.combineChartRequestInfo$.pipe(
        switchMap(([studyId, user, displaySettings, resultsReady]) => {
            const userId = user.id;
            const req = this.prepareOptimizerChartRequest(studyId, displaySettings, userId);
            return this.optimizerService.getChartDataRiskForecastByImpact(req);
        }),
        takeUntil(this.unsubscribe$),
    );
    readonly chartDataRiskForecastByCategory$: Observable<OptimizerChartResponse> = this.combineChartRequestInfo$.pipe(
        switchMap(([studyId, user, displaySettings, resultsReady]) => {
            const userId = user.id;
            const req = this.prepareOptimizerChartRequest(studyId, displaySettings, userId);
            return this.optimizerService.getChartDataRiskForecastByCategory(req);
        }),
        takeUntil(this.unsubscribe$),
    );

    private prepareOptimizerChartRequest(
        studyId: string,
        displaySettings: any,
        userId?: string,
    ): OptimizerChartRequest {
        // Create OptimizerChartRequest object
        const req = new OptimizerChartRequest();
        req.sensitivityStudy = {
            studyId: studyId,
            userId: userId,
            sensitivityParams: null,
            currentYear: new Date().getFullYear(),
            evaluationPeriod: 10,
        };
        req.graphList = displaySettings.graphList;
        return req;
    }

    private prepareOptimizerCalculationRequest(
        popoutValue: OptimizerAnalysisRequest,
        stateChanged: boolean,
        studyId: string,
        userId?: string,
    ): OptimizerAnalysisRequest {
        const req = popoutValue;
        req.sensitivityStudy = {
            studyId: studyId,
            userId: userId,
            sensitivityParams: null,
            currentYear: new Date().getFullYear(),
            evaluationPeriod: 10,
        };
        req.stateChanged = stateChanged;

        return req;
    }

    constructor(
        private optimizerService: OptimizerService,
        private studiesStore: StudiesStore,
        private optimizerPopoutStore: OptimizerPopoutStore,
        protected toastrService: NbToastrService,
        private userService: UsersService,
        private pagesStore: PagesStore,
    ) {
        super();
    }
}
