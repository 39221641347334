import {Injectable} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable, Subscription} from 'rxjs';
import {AnalyzerPopoutSettings, PopoutService} from '../../@core/interfaces/common/popout';
import {debounceTime, filter, map, shareReplay, startWith, tap} from 'rxjs/operators';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AnalyzerRequest} from '../../@core/interfaces/engin/analyzer';
import {StudiesStore} from '../common/studies.store';
import {switchMap} from 'rxjs/internal/operators/switchMap';
import {of} from 'rxjs/internal/observable/of';
import {Workflow, WorkflowItem} from '../../@core/interfaces/engin/workflow';
import {UsageAnalyticsService} from '@core/utils/usage-analytics.service';

@Injectable()
export class AnalyzerPopoutStore {
    private staticFilterSettings: any[] = [];

    public popoutFormGroup = this.fb.group({});

    private popoutChangeSubscription: Subscription;
    private searchSubscription: Subscription;

    private controlPanelCollapsed = new BehaviorSubject<boolean>(true);
    readonly controlPanelCollapsed$: Observable<boolean> = this.controlPanelCollapsed.asObservable();

    private popoutFormGroupValue = new BehaviorSubject(null);
    readonly currentPopout$: Observable<AnalyzerRequest> = this.popoutFormGroupValue.asObservable().pipe(
        filter((value) => value !== null),
        map((value) => {
            return this.mapFormGroupValue(value);
        }),
    );

    private popoutSettings = new BehaviorSubject<AnalyzerPopoutSettings>(null);
    readonly popoutSettings$: Observable<AnalyzerPopoutSettings> = this.popoutSettings.asObservable().pipe(
        filter((settings) => settings != null),
        tap((settings) => {
            this.popoutChangeSubscription && this.popoutChangeSubscription.unsubscribe();
            this.searchSubscription && this.searchSubscription.unsubscribe();
            this.initPopout(settings);
        }),
        shareReplay(1),
    );

    constructor(
        private popoutData: PopoutService,
        private fb: FormBuilder,
        protected studiesStore: StudiesStore,
        private usageAnalyticsService: UsageAnalyticsService,
    ) {}

    get filtersChanged(): boolean {
        const filtersValue = this.filtersFormGroup.value;
        const props = Object.keys(filtersValue).filter((prop) => prop.includes('_selected'));
        const item = props.find((prop) => filtersValue[prop] !== 'All');
        return item !== undefined;
    }

    get selectedGroupItem(): any {
        const group = this.popoutFormGroup.controls['groups'];
        if (group != null) {
            return group.value.group_selected;
        }
    }

    get selectedSensitivityItem(): any {
        const sensitivity = this.popoutFormGroup.controls['sensitivity'];
        if (sensitivity != null) {
            return sensitivity.value.sensitivity_selected;
        }
    }

    get approachingEolValue(): number {
        return this.approachingEolFormControl.value;
    }

    get hiDviValue(): number {
        return this.hiDviFormControl.value;
    }

    get hiDviFormControl(): FormControl {
        return this.inputsFormGroup.get('hi_dvi') as FormControl;
    }

    get approachingEolFormControl(): FormControl {
        return this.inputsFormGroup.get('eol_period') as FormControl;
    }

    get filtersFormGroup(): FormGroup {
        return this.popoutFormGroup.get('filters') as FormGroup;
    }

    get filtersFormGroupControls(): any {
        return this.filtersFormGroup.controls;
    }

    get groupsFormGroup(): FormGroup {
        return this.popoutFormGroup.get('groups') as FormGroup;
    }

    get sensitivityFormGroup(): FormGroup {
        return this.popoutFormGroup.get('sensitivity') as FormGroup;
    }

    get inputsFormGroup(): FormGroup {
        return this.popoutFormGroup.get('inputs') as FormGroup;
    }

    get searchStringFormControl(): FormControl {
        return this.popoutFormGroup.get('searchString') as FormControl;
    }

    public loadPopoutSettings() {
        // Analyzer popout panel "sensitivity" section must update based on the active study collection
        combineLatest<Observable<AnalyzerPopoutSettings>, Observable<Workflow>>([
            this.popoutData.getAnalyzerPopoutSettings(),
            this.studiesStore.activeCollection$,
        ])
            .pipe(
                switchMap(([settings, activeCollection]: [AnalyzerPopoutSettings, Workflow]) => {
                    /*
                     * Trim down the sensitivity area of the panel based on the active study collection.
                     * 1) Extract the existing information. Note: popout settings should have more or equal amount of records
                     * than are applicable for any single study collection.
                     * 2) Construct the settings for active version of the Analyzer Control Panel.
                     * 2a) Starting with the list of studies in the active study collection, try to join each to the settings
                     * and construct the final data object.
                     * 2b) Extract code, name, enabled, selected data objects.
                     * 2c) If no match is found, set name = code, selected = false, enabled = true.
                     * 3) If no items are selected, set the first element to be selected = true.
                     */
                    // Step 1
                    const fullSensitivityOptions: {code: string; name: string; enabled: boolean; selected: boolean}[] =
                        settings.sensitivity ? settings.sensitivity : null;
                    const activeStudyList: WorkflowItem[] = activeCollection.studies;

                    // Step 2
                    let newSensitivityOptions = activeStudyList.map((study) => {
                        // Step 2a
                        const matchSettings: {code: string; name: string; enabled: boolean; selected: boolean}[] =
                            fullSensitivityOptions.filter((e) => e.code === study.sensitivityCode);

                        // Step 2b
                        if (matchSettings.length > 0 && matchSettings[0]) {
                            return {
                                code: matchSettings[0].code,
                                name: matchSettings[0].name,
                                selected: matchSettings[0].selected,
                                enabled: matchSettings[0].enabled,
                            };
                        } else {
                            // Step 2c
                            return {
                                code: study.sensitivityCode,
                                name: study.itemName,
                                selected: false,
                                enabled: true,
                            };
                        }
                    });

                    // Step 3
                    if (newSensitivityOptions.filter((e) => e.selected).length === 0) {
                        newSensitivityOptions = newSensitivityOptions.map((elem, i) => {
                            if (i === 0) {
                                return {
                                    ...elem,
                                    selected: true,
                                };
                            } else {
                                return {
                                    ...elem,
                                };
                            }
                        });
                    }

                    // Send the new settings object to be constructed
                    const finalSettings: AnalyzerPopoutSettings = {
                        ...settings,
                        sensitivity: newSensitivityOptions,
                    };

                    return of(finalSettings);
                }),
            )
            .subscribe((settings: AnalyzerPopoutSettings) => {
                this.updateSettings(settings);
            });
    }

    public destroy() {
        this.popoutChangeSubscription && this.popoutChangeSubscription.unsubscribe();
        this.searchSubscription && this.searchSubscription.unsubscribe();
        this.popoutFormGroup = this.fb.group({});
    }

    public updateSettings(settings: AnalyzerPopoutSettings, settingName?: string) {
        let resultSettings: AnalyzerPopoutSettings = settings;
        if (settingName) {
            resultSettings = {
                ...this.popoutSettings.value,
                [settingName]: settings[settingName].map((item) => {
                    return {
                        ...item,
                    };
                }),
            };
        }
        this.popoutSettings.next(resultSettings);
    }

    private isEquivalent(val1: any, val2: any): boolean {
        if (val1 instanceof Object && val2 instanceof Object) {
            const val1props = Object.keys(val1);
            const val2props = Object.keys(val2);

            if (val1props.length !== val2props.length) return false;

            for (let i = 0; i < val1props.length; i++) {
                if (val1props[i] === 'enabled') continue;
                const val2Prop = val2props.find((item) => item === val1props[i]);
                if (!val2Prop) return false;

                if (!this.isEquivalent(val1[val2Prop], val2[val2Prop])) return false;
            }
            return true;
        }
        return val1 === val2;
    }

    private setFiltersFormGroup(group: FormGroup) {
        if (this.filtersFormGroup) {
            this.filtersFormGroup.reset(group.value);
            Object.keys(group.value).forEach((prop) => {
                this.filtersFormGroup.controls[prop].enable();
            });
        } else {
            this.popoutFormGroup.setControl('filters', group);
        }
    }

    private setGroupsFormGroup(groups: FormGroup) {
        if (this.groupsFormGroup) {
            this.groupsFormGroup.reset(groups.value);
        } else {
            this.popoutFormGroup.setControl('groups', groups);
        }
    }

    private setSensitivityFormGroup(sensitivity: FormGroup) {
        if (this.sensitivityFormGroup) {
            this.sensitivityFormGroup.reset(sensitivity.value);
        } else {
            this.popoutFormGroup.setControl('sensitivity', sensitivity);
        }
    }

    private setInputsFormGroup(inputs: FormGroup) {
        if (this.inputsFormGroup) {
            this.inputsFormGroup.reset(inputs.value);
        } else {
            this.popoutFormGroup.setControl('inputs', inputs);
        }
    }

    private setSearchStringFormControl(search: FormControl) {
        if (this.searchStringFormControl) {
            this.searchStringFormControl.reset(search.value);
        } else {
            this.popoutFormGroup.setControl('searchString', search);
        }
    }

    private updateTotals() {
        const properties = Object.keys(this.filtersFormGroupControls).filter(
            (propName) => !propName.includes('_selected'),
        );

        properties.forEach((prop) => {
            if (this.filtersFormGroupControls[prop].value) {
                const control = this.filtersFormGroupControls[`${prop}_selected`];
                const selected = this.filtersFormGroupControls[prop].value.filter((item) => item.selected).length;
                const all = this.filtersFormGroupControls[prop].value.length;
                control.setValue(selected === all ? 'All' : selected, {emitEvent: false});
            }
        });
    }

    private search(searchString: string) {
        const props = Object.keys(this.filtersFormGroupControls).filter((prop) => !prop.includes('_selected'));
        const filters = {};
        props.forEach((propName) => {
            if (this.filtersFormGroupControls[propName].value) {
                filters[propName] = this.filtersFormGroupControls[propName].value.map((item) => {
                    return {
                        ...item,
                        enabled: `${item.code}${item.name}`.toLowerCase().includes(searchString.toLowerCase()),
                    };
                });
            }
        });
        this.filtersFormGroup.patchValue(filters, {
            emitEvent: false,
            onlySelf: true,
        });
    }

    private subscribeOnChanges() {
        this.popoutChangeSubscription = combineLatest<
            Observable<any>,
            Observable<any>,
            Observable<any>,
            Observable<any>
        >([
            this.filtersFormGroup.valueChanges.pipe(
                startWith({...this.filtersFormGroup.value}),
                tap(() => this.updateTotals()),
            ),
            this.groupsFormGroup.valueChanges.pipe(startWith({...this.groupsFormGroup.value})),
            this.sensitivityFormGroup.valueChanges.pipe(startWith({...this.sensitivityFormGroup.value})),
            this.inputsFormGroup.valueChanges.pipe(startWith({...this.inputsFormGroup.value}), debounceTime(500)),
        ])
            .pipe(
                map(([filters, groups, sensitivity, inputs]) => {
                    return {
                        filters: filters,
                        groups: groups,
                        sensitivity: sensitivity,
                        inputs: inputs,
                    };
                }),
                tap((value) => {
                    if (this.popoutFormGroupValue.value === null) {
                        this.popoutFormGroupValue.next(value);
                    }
                }),
                filter((value) => {
                    return (
                        !this.isEquivalent(this.popoutFormGroupValue.value, value) &&
                        (this.inputsFormGroup.get('eol_period')
                            ? this.inputsFormGroup.get('eol_period').valid
                            : true) &&
                        (this.inputsFormGroup.get('hi_dvi') ? this.inputsFormGroup.get('hi_dvi').valid : true)
                    );
                }),
                debounceTime(1500),
            )
            .subscribe((value) => {
                this.updatePopoutSettingsValue(value);
                this.popoutFormGroupValue.next(value);
                this.usageAnalyticsService.logView('Analyzer Control Panel');
            });

        this.searchSubscription = this.searchStringFormControl.valueChanges.subscribe((searchString) =>
            this.search(searchString),
        );
    }

    private updatePopoutSettingsValue(next: any) {
        // Update filters
        this.popoutSettings.value.filters.forEach((item) => {
            if (next.filters[item.code] !== null && next.filters[item.code] !== undefined) {
                item.enabled = true;
                item.options = next.filters[item.code];
            } else {
                item.enabled = false;
            }
        });

        // Update groups
        this.popoutSettings.value.groups.forEach((item) => {
            item.selected = item.code === next.groups.group_selected.code;
        });

        // Update sensitivity
        this.popoutSettings.value.sensitivity.forEach((item) => {
            item.selected = item.code === next.sensitivity.sensitivity_selected.code;
        });

        // Update inputs
        this.popoutSettings.value.inputs.forEach((item) => {
            item.value = next.inputs[item.code];
        });
    }

    private mapFormGroupValue(popoutValue: any): AnalyzerRequest {
        /*
         * Filters
         * - Storing extra data outside of the control group:
         * - staticFilterSettings: [{code: string, comparisonType: string || null}]
         */
        const filterList = [];
        const filters = Object.keys(popoutValue.filters).filter(
            (prop) => !prop.includes('_selected') && popoutValue.filters[prop],
        );
        filters.forEach((item) => {
            const options = {};
            popoutValue.filters[item].forEach((option) => {
                options[option.code] = option.selected;
            });
            let f = {
                fieldPhysical: item,
                options: options,
                comparisonType: null,
            };
            if (this.staticFilterSettings) {
                const localStaticFilterSetting = this.staticFilterSettings.filter((e) => e.code === item);
                if (localStaticFilterSetting.length > 0) {
                    f = {
                        ...f,
                        comparisonType: localStaticFilterSetting[0].comparisonType,
                    };
                }
            }
            filterList.push(f);
        });

        // Groups
        const groupList = [];
        const groups = Object.keys(popoutValue.groups).filter((props) => !(props === 'group_selected'));
        const selectedGroup = popoutValue.groups['group_selected'];
        groups.forEach((group) => {
            groupList.push({
                fieldPhysical: group,
                selected: selectedGroup === popoutValue.groups[group],
            });
        });

        // Sensitivity
        const sensitivityList = [];
        const sensitivity = Object.keys(popoutValue.sensitivity).filter((props) => !(props === 'sensitivity_selected'));
        const selectedSensitivity = popoutValue.sensitivity['sensitivity_selected'];
        sensitivity.forEach((item) => {
            sensitivityList.push({
                code: item,
                selected: selectedSensitivity === popoutValue.sensitivity[item],
            });
        });

        // Inputs
        const inputList = [];
        const inputs = Object.keys(popoutValue.inputs);
        inputs.forEach((input) => {
            inputList.push({
                namePhysical: input,
                dataType: 'integer',
                value: popoutValue.inputs[input],
            });
        });

        return new AnalyzerRequest(filterList, groupList, sensitivityList, inputList);
    }

    private initPopout(settings: AnalyzerPopoutSettings) {
        this.popoutFormGroup = this.fb.group({});
        this.initFilters(settings.filters);
        this.initGroups(settings.groups);
        this.initSensitivity(settings.sensitivity);
        this.initInputs(settings.inputs);
        this.setSearchStringFormControl(this.fb.control(''));
        this.subscribeOnChanges();
    }

    private initFilters(initialFilters: any[]) {
        const filters = this.fb.group({});
        initialFilters.forEach((item) => {
            filters.setControl(
                `${item.code}_selected`,
                this.fb.control({
                    value: 0,
                    disabled: true,
                }),
            );
            filters.setControl(item.code, this.fb.control({value: item.options, disabled: !item.enabled}));
            this.staticFilterSettings.push({
                code: item.code,
                comparisonType: item.comparisonType ? item.comparisonType : null,
            });
        });
        this.setFiltersFormGroup(filters);
    }

    private initGroups(initialGroups: any[]) {
        const groups = this.fb.group({});

        initialGroups.forEach((item) => {
            if (item.selected && item.enabled) {
                groups.setControl('group_selected', this.fb.control(item));
            }
            groups.setControl(item.code, this.fb.control(item));
        });

        this.setGroupsFormGroup(groups);
    }

    private initSensitivity(initialSensitivity: any[]) {
        const sensitivity = this.fb.group({});

        initialSensitivity.forEach((item) => {
            if (item.selected && item.enabled) {
                sensitivity.setControl('sensitivity_selected', this.fb.control(item));
            }
            sensitivity.setControl(item.code, this.fb.control(item));
        });

        this.setSensitivityFormGroup(sensitivity);
    }

    private initInputs(initialInputs: any[]) {
        const inputs = this.fb.group({});

        initialInputs.forEach((item) => {
            if (item.enabled) {
                inputs.setControl(
                    item.code,
                    this.fb.control(
                        {
                            value: item.value,
                            disabled: !item.enabled,
                        },
                        [Validators.required, Validators.max(item.max), Validators.min(item.min)],
                    ),
                );
            }
            this.setInputsFormGroup(inputs);
        });
    }

    public toggleControlPanelCollapsed(collapsed: boolean) {
        if (collapsed != null) {
            this.controlPanelCollapsed.next(collapsed);
        }
    }
}
