import {Injectable} from '@angular/core';
import {SurvivalAnalysisApi} from './survival-analysis.api';
import {Observable} from 'rxjs';
import {DataUploadRecord} from './pojo/failure-data-management.pojo';
import {SurvivalStudyRequest} from './pojo/survival-study.pojo';
import {CurveCalibrationDetails} from './pojo/curve-calibration-outputs.pojo';
import {DownloadLink} from '../../../@core/interfaces/common/legacyUploads';
import {SurvivalAnalysisService} from '../../../@core/interfaces/engin/survival-analysis';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';

@Injectable()
export class SurvivalAnalysisServiceImpl extends SurvivalAnalysisService {
    constructor(private api: SurvivalAnalysisApi) {
        super();
    }

    // Data retrieval / used in store
    getUploadHistoryData(): Observable<DataUploadRecord[]> {
        return this.api.getUploadHistoryData();
    }
    getUploadDatatableHistory(): Observable<DataSource> {
        return this.api.getUploadDatatableHistory();
    }

    getAnalysisHistoryData(): Observable<DataSource> {
        return this.api.getAnalysisHistoryData();
    }

    getCurveCalibrationData(survivalStudyId: string): Observable<CurveCalibrationDetails[]> {
        return this.api.getCurveCalibrationData(survivalStudyId);
    }

    getSurvivalStudyEditAvailable(survivalStudyId: string): Observable<boolean> {
        return this.api.getSurvivalStudyEditAvailable(survivalStudyId);
    }

    // Supporting services / used directly
    updateDataUploadComment(uploadId: string, newComment: string): Observable<boolean> {
        return this.api.updateDataUploadComment(uploadId, newComment);
    }

    updateCurveCalibrationComment(studyId: string, curveId: number, newComment: string): Observable<boolean> {
        return this.api.updateCurveCalibrationComment(studyId, curveId, newComment);
    }

    updateCurveCalibrationActions(
        studyId: string,
        curveId: number,
        isAccepted: boolean,
        isRejected: boolean,
    ): Observable<boolean> {
        return this.api.updateCurveCalibrationActions(studyId, curveId, isAccepted, isRejected);
    }

    // Functional actions
    executeNewSurvivalStudy(req: SurvivalStudyRequest): Observable<any> {
        return this.api.executeNewSurvivalStudy(req);
    }

    submitSurvivalStudy(studyId: string): Observable<any> {
        return this.api.submitSurvivalAnalysis(studyId);
    }

    downloadFile(fileKey: string, medium: string, type: string): Observable<DownloadLink> {
        return this.api.downloadFile(fileKey, medium, type);
    }
}
