import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {PagesStore} from '../@store';
import {Page} from '../@core/interfaces/common/pages';

@Injectable()
export class RerouteGuard implements CanActivate {
    constructor(private pagesStore: PagesStore, private router: Router) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean> | Promise<boolean> | boolean {
        const data = this.router.getCurrentNavigation().extras.state
            ? this.router.getCurrentNavigation().extras.state
            : null;

        return this.pagesStore.pageGroups$.pipe(
            map((pageGroups: Page[]) => {
                let currentGroup = null;
                try {
                    currentGroup = []
                        .concat(...pageGroups.map((group) => group.children))
                        .find((group) => state.url.indexOf(group.link) >= 0);
                } finally {
                }

                let currentPage = null;
                try {
                    currentPage = []
                        .concat(...pageGroups.map((group) => group.children))
                        .find((group) => {
                            return state.url.indexOf(group.link) >= 0;
                        })
                        .children.find((page) => state.url.indexOf(page.link) >= 0);
                } finally {
                }

                let currentTab = null;
                try {
                    currentTab = []
                        .concat(
                            ...[]
                                .concat(...pageGroups.map((group) => group.children))
                                .find((group) => state.url.indexOf(group.link) >= 0)
                                .children.map((page) => page.children || []),
                        )
                        .find((tab) => state.url.indexOf(tab.link) >= 0);
                } finally {
                }

                // If navigating in to a page for the first time, check the default route
                // Account for extra data (e.g., study assets -> asset details)
                if (!currentPage) {
                    this.router.navigateByUrl(currentGroup.redirectTo, {state: data});
                } else if (!currentTab) {
                    this.router.navigateByUrl(currentPage.redirectTo, {state: data});
                }
                return true;
            }),
        );
    }
}
