import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {NbAuthService} from '@nebular/auth';
import {finalize, takeUntil, tap} from 'rxjs/operators';
import {BehaviorSubject, Observable} from 'rxjs';
import {
    AnalyzerPopoutStore,
    ConfigurationStore,
    ForecasterPopoutStore,
    OptimizerPopoutStore,
    PagesStore,
    ReportsStore,
    SidebarStore,
    StudiesStore,
} from '../@store';
import html2canvas from 'html2canvas';
import {DOCUMENT} from '@angular/common';
import {RolesStore} from '../@store/common/roles.store';
import {Page} from '../@core/interfaces/common/pages';
import {Router} from '@angular/router';
import {UsersStore} from '../@store/common/users.store';
import {DataAuditPopoutStore} from '../@store/data-audit/data-audit-popout.store';
import {AssetDataAuditStore} from '../@store/data-audit/asset-data-audit.store';
import {SupportStore} from '../@store/common/support.store';
import {UsageAnalyticsService} from '@core/utils/usage-analytics.service';
import {NbDialogService} from '@nebular/theme';
import {ReportFilterDialogComponent} from '@theme/components/report-filter-dialog/report-filter-dialog.component';
import {Unsubscribable} from '@core/interfaces/unsubscribable';

@Component({
    selector: 'ngx-pages',
    templateUrl: './pages.component.html',
    styleUrls: ['./pages.component.scss'],
    providers: [
        StudiesStore,
        PagesStore,
        SidebarStore,
        AnalyzerPopoutStore,
        OptimizerPopoutStore,
        ForecasterPopoutStore,
        DataAuditPopoutStore,
        ReportsStore,
        RolesStore,
        UsersStore,
        ConfigurationStore,
        AssetDataAuditStore,
        SupportStore,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PagesComponent extends Unsubscribable {
    public readonly isAuthenticated$: Observable<boolean> = this.authService.onAuthenticationChange().pipe(
        tap((isAuthenticated) => {
            if (isAuthenticated) {
                // not in Analyzer module for storing filter state after changing
                this.analyzerPopoutStore.loadPopoutSettings();
                this.optimizerFilterStore.loadPopoutSettings();
            }
        }),
        finalize(() => {
            this.analyzerPopoutStore.destroy();
            this.optimizerFilterStore.destroy();
            this.forecasterFilterStore.destroy();
        }),
    );

    private assets$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
    constructor(
        public pagesStore: PagesStore,
        public authService: NbAuthService,
        public sidebarStore: SidebarStore,
        private reportsStore: ReportsStore,
        private analyzerPopoutStore: AnalyzerPopoutStore,
        private optimizerFilterStore: OptimizerPopoutStore,
        private forecasterFilterStore: ForecasterPopoutStore,
        private router: Router,
        private usageAnalyticsService: UsageAnalyticsService,
        private dialogService: NbDialogService,
        @Inject(DOCUMENT) private document: Document,
    ) {
        super();
        this.reportsStore.assetClassList$.pipe(takeUntil(this.unsubscribe$)).subscribe((assets: string[]) => {
            this.assets$.next(assets);
        });
    }

    getCapture() {
        // event

        const pageContainer = this.document.getElementsByClassName('layout').item(0) as HTMLElement;
        if (pageContainer) {
            html2canvas(pageContainer).then((canvas) => {
                const data = canvas.toDataURL('image/jpeg', 1);
                const downloadLink = this.document.createElement('a');
                downloadLink['href'] = encodeURI(data);
                downloadLink['download'] = 'screenshot.jpg';
                downloadLink.click();
            });
            this.usageAnalyticsService.logDownload('screenshot', 'Screen Capture');
        }
    }

    getOutputReport(module: Page) {
        // Analyzer report has optional filter criteria by asset class
        if (module.id.toLowerCase().includes('analyzer')) {
            const reportInfo: any = {
                module: module.report,
                sensitivityItem: this.analyzerPopoutStore.selectedSensitivityItem,
                options: this.assets$.value,
            };

            this.dialogService
                .open(ReportFilterDialogComponent, {
                    context: reportInfo,
                })
                .onClose.subscribe((c) => {
                    if (c.action == 'confirm') {
                        this.reportsStore.downloadOutputReportAnalyzer(c.data.module, c.data.studyId, c.data.filters);
                    }
                });
        } else {
            this.reportsStore.downloadOutputReportGeneric(
                module.report,
                this.analyzerPopoutStore.selectedSensitivityItem,
            );
        }
    }

    ReportingTool() {
        this.router.navigate(['/reporting-tool/report-config'], {
            // queryParams: { userId: this.userId, userName: this.userName }
        });
    }

    showReportConfigShortcut() {
        return !this.router.url.includes('reporting-tool');
    }
}
