export class RequestTemplate {
    static Analyzer = {
        pageId: '',
        tabId: '',
        filterList: [],
        groupList: [],
        sensitivityList: [],
        inputList: [
            {
                namePhysical: 'eol_period',
                dataType: 'integer',
                value: 10,
            },
        ],
        graphList: [],
    };

    static Analyzer_Condition_Summary = [
        {
            graphId: 'health_summary',
            graphTitle: 'Health Summary',
            unit: 'currency',
        },
    ];

    static Analyzer_Condition_Status = [
        {
            graphId: 'health_status',
            graphTitle: 'Health Status',
            unit: 'currency',
        },
    ];
}
