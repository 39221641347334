import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {debounceTime} from 'rxjs/operators';
import {Subject, Subscription} from 'rxjs';
import {CrossSelectorService} from './cross-selector-service';
import {ReportRequestComposer} from '../../../pages/reporting-tool/report-services/report-request-composer.service';

export interface FilterItem {
    code: string;
    name: string;
    enabled: boolean;
    selected: boolean;
    selectedBeforeApp: boolean;
    options: FilterItem[];
    curSelectedNumber: number;
}

@Component({
    selector: 'ngx-dropdown-selector',
    templateUrl: './dropdown-selector.component.html',
    styleUrls: ['./dropdown-selector.component.scss'],
    // changeDetection: ChangeDetectionStrategy.OnPush,
})
/* tslint:disable */
export class DropdownSelectorComponent implements OnInit, OnDestroy {
    // Component inputs / outputs
    @Input() tag;
    @Input() is2Level;
    @Input() filterData;
    @Input() disabled;
    @Input() listen2CrossSelector: string;
    @Input() status: any;
    @Input() type: any;
    @Input() headerType: string;
    @Output() onChangeApplied = new EventEmitter<FilterItem>();

    // UI Layer
    shMain = false;
    shL1 = true;
    shL2 = false;
    // Search
    public term$ = new Subject<string>();
    private searchSubscription: Subscription;
    // Other
    curEnteredL1: FilterItem;
    curSelectedNumber = 0;
    curSelectedSummary = 'None';
    requiredState;
    editableState;
    isFirstTimeSetup = true;

    constructor(
        private crossSelectorService: CrossSelectorService,
        private ref: ChangeDetectorRef,
        private reportRequestComposer: ReportRequestComposer,
    ) {
        this.searchSubscriptionInit();
    }

    ngOnInit() {
        // For the requiredState and editableState
        if (this.status == undefined) {
            this.editableState = false;
            this.requiredState = false;
        } else {
            let selectedList = [];
            this.filterData.map((data) => {
                if (data.selected) {
                    selectedList.push(data);
                }
            });

            // Required overrides editable as status for colour-coding to user
            this.requiredState = this.status.required;
            this.editableState = this.status.enabled && !this.status.required;
            /*            if (selectedList.length === 0) {
                this.requiredState = true;
            } else {
                this.editableState = true;
            }*/
        }

        // This sets table header to include labels for user; reset values to true, but do not reset back to false if another measure is true
        this.reportRequestComposer.requiredFlag.next(
            this.requiredState || this.reportRequestComposer.requiredFlag.value,
        );
        this.reportRequestComposer.editableFlag.next(
            this.editableState || this.reportRequestComposer.editableFlag.value,
        );

        if (this.listen2CrossSelector !== null && this.listen2CrossSelector !== '') {
            if (this.is2Level) {
                this.crossSelectorService
                    .getCDS(this.listen2CrossSelector)
                    .asObservable()
                    .subscribe((crossFilterData) => {
                        if (crossFilterData != null) {
                            crossFilterData.forEach((cfdL1) => {
                                const L1 = DropdownSelectorComponent.findObjByCode(this.filterData, cfdL1.code);
                                if (L1 != null) {
                                    cfdL1.options.forEach((cfdL2) => {
                                        DropdownSelectorComponent.findObjByCode(L1.options, cfdL2.code)['selected'] =
                                            cfdL2.selected;
                                    });
                                }
                            });
                            this.commitChanges(true);
                        }
                    });
            } else {
                this.crossSelectorService
                    .getCDS(this.listen2CrossSelector)
                    .asObservable()
                    .subscribe((crossFilterData) => {
                        if (crossFilterData != null) {
                            crossFilterData.forEach((cfdL1) => {
                                DropdownSelectorComponent.findObjByCode(this.filterData, cfdL1.code)['selected'] =
                                    cfdL1.selected;
                            });
                        }
                        this.commitChanges(true);
                    });
            }
        }
        this.commitChanges(false);
    }

    ngOnDestroy() {
        this.searchSubscriptionDest();
    }

    public toggleMainMenu() {
        if (!this.disabled) {
            this.shMain = !this.shMain;
            if (this.shMain !== true) {
                this.l2ExpandController(null, false);
            }
        }
    }

    public l2ExpandController(itemIndex, openFlag) {
        if (this.filterData != null) {
            const item = this.filterData[itemIndex];
            if (openFlag) {
                item['expand'] = openFlag;
                this.curEnteredL1 = item;
            } else {
                if (this.curEnteredL1 != null) {
                    this.curEnteredL1['expand'] = false;
                }
                this.curEnteredL1 = null;
            }
            this.shL1 = !openFlag;
            this.shL2 = openFlag;
            //
            this.currentSelectedTemp = {};
        }
    }

    /*private commitChanges(emitChanges) {
        this.curSelectedNumber = this.countSelection(this.filterData);
        this.curSelectedSummary = this.genSummary(this.filterData);

        this.ref.markForCheck();
        if (emitChanges) {
            if (this.isFirstTimeSetup) {
                this.isFirstTimeSetup = false;
            } else {
                this.onChangeApplied.emit(this.filterData);
            }

        }
    }*/

    public currentSelectedTemp = {};

    public onSelected(isSelected, l2Item) {
        l2Item.selectedBeforeApp = isSelected;
        this.currentSelectedTemp[l2Item.name] = l2Item;
    }

    public applySelection(selectTemp) {
        for (const [key, L2Item] of Object.entries(selectTemp)) {
            if (L2Item['selectedBeforeApp']) {
                L2Item['selected'] = true;
            } else {
                L2Item['selected'] = false;
            }
        }
        this.commitChanges(true);
        // ===
        selectTemp = {}; // clean temp store
        if (this.is2Level) {
            this.l2ExpandController(null, false);
        } else {
            this.toggleMainMenu();
        }
    }

    public cleanAllSelected(data: FilterItem[]) {
        data.forEach((filterItem) => {
            if ('selected' in filterItem) {
                filterItem.selected = false;
            }
            if ('options' in filterItem) {
                this.cleanAllSelected(filterItem.options);
                filterItem.curSelectedNumber = 0;
            }
        });
        this.curSelectedNumber = 0;
        this.currentSelectedTemp = {};
        this.curSelectedSummary = 'None';
        this.onChangeApplied.emit(this.filterData);
    }

    // Broadcast filter selection
    private static findObjByCode(arr, propertyValue) {
        const objFound = arr.filter((obj) => {
            return obj.code === propertyValue;
        })[0];
        return objFound;
    }

    private searchSubscriptionInit() {
        this.searchSubscription = this.term$.pipe(debounceTime(100)).subscribe((term) => {
            if (term != null) {
                this.search(this.filterData, term);
            }
        });
    }

    private searchSubscriptionDest() {
        if (this.searchSubscription) {
            this.searchSubscription.unsubscribe();
            this.searchSubscription = null;
        }
    }

    private search(data: FilterItem[], searchKW) {
        data.forEach((filterItem) => {
            if ('name' in filterItem && 'enabled' in filterItem) {
                if (filterItem.name.includes(searchKW)) {
                    filterItem.enabled = true;
                } else {
                    filterItem.enabled = false;
                }
            }
            if ('options' in filterItem) {
                this.search(filterItem.options, searchKW);
            }
        });
    }

    private commitChanges(emitChanges) {
        this.curSelectedNumber = this.countSelection(this.filterData);
        this.curSelectedSummary = this.genSummary(this.filterData);
        this.ref.markForCheck();
        if (emitChanges) {
            if (this.isFirstTimeSetup) {
                this.isFirstTimeSetup = false;
            } else {
                this.onChangeApplied.emit(this.filterData);
            }
        }
        if (this.listen2CrossSelector === 'filter' && !this.isFirstTimeSetup) {
            this.onChangeApplied.emit(this.filterData);
        }
    }

    private countSelection(filterData: any[]) {
        let curTotalSelectedNumber = 0;
        filterData.forEach((L1Item) => {
            L1Item.curSelectedNumber = 0;
            if (this.is2Level) {
                L1Item.options.forEach((L2Item) => {
                    if (L2Item.selected) {
                        L1Item.curSelectedNumber++;
                        curTotalSelectedNumber++;
                    }
                });
            } else {
                if (L1Item.selected) {
                    curTotalSelectedNumber++;
                }
            }
        });
        return curTotalSelectedNumber;
    }

    private genSummary(filterData: FilterItem[]) {
        const summaryStringArr = [];
        let summaryString = '';
        filterData.forEach((filterItem) => {
            if (this.is2Level) {
                if ('curSelectedNumber' in filterItem && filterItem.curSelectedNumber > 0) {
                    summaryStringArr.push(`${filterItem.name} - ${filterItem.curSelectedNumber}`);
                }
            } else {
                if (filterItem.selected) {
                    summaryStringArr.push(`${filterItem.name}`);
                }
            }
        });

        if (summaryStringArr.length > 0) {
            summaryString = summaryStringArr.join(', ');
        } else {
            if (this.headerType) {
                summaryString = this.headerType;
            } else {
                summaryString = 'None';
            }
        }
        return summaryString;
    }
}
