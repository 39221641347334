import {Observable} from 'rxjs';
import {SimpleUnit} from '@core/interfaces/system/system-common';

export interface PageGroup {
    id: string;
    title: string;
    icon?: IconDetails;
    order: number;
    children?: Page[];
}

export interface Page extends PageGroup {
    // Context
    subTitle?: string;

    // Navigation
    link?: string;
    pathMatch: string;
    redirectTo?: string;
    hideMenu: boolean;

    // Authorization
    enabled?: boolean;
    roleLevel: string;

    // Report
    report?: Report;

    // Other
    menuActions?: any;
    filterDisabled?: boolean;
    dataAuditWidgetHidden?: boolean;
    controlPanel?: ControlPanelSettings;
    popoutPanelConfiguration?: number;
    graphList?: {
        graphId: string;
        unit: string;
        label?: string;
    }[];
    tableColumns?: {
        code: string;
        title: string;
        width: string;
        sort: boolean;
    }[];
    headerConfig?: {
        subtitle: string;
        code: string;
    }[];
    displayAction?: boolean;
    body?: DashboardCardBody;
}

export interface ControlPanelSettings {
    disableEntirePanel: boolean;
    disableSectionFilter?: boolean;
    disableSectionGroup?: boolean;
    disableSectionSensitivity?: boolean;
    disableSectionInput?: boolean;
}

export interface IconDetails {
    icon: string;
    pack: string;
}

export interface GraphLabel {
    graphId: string;
    options: GraphLabelOption[];
}

export interface GraphLabelOption {
    label: string;
    value: string;
    selected: boolean;
}

export interface Report {
    templateFlag: boolean;
    reportId: string;
}

// TODO: refactor as general "custom implementation" or "reroute"
export interface PageWithLogi {
    title: string;
    link: string;
    logiUrl: string;
    useLogi?: boolean;
}

export interface SummaryValue {
    title: string;
    value: string;
}

export interface DisplaySettings {
    id: string;
    groupId: string;
    featureId: string;
    pageId: string;
    tabId: string;
    graphList?: {
        graphId: string;
        unit: string;
        label?: string;
    }[];
    dataAuditWidgetHidden?: boolean;
}

export enum DashboardBodyType {
    Empty = 'empty',
    Number = 'number',
    Text = 'text',
    Table = 'table',
}

export interface DashboardCardBody {
    id: string;
    type: DashboardBodyType;
}

export interface DashboardCardBodyNumber extends DashboardCardBody {
    value: number;
    unit: SimpleUnit;
    label: string;
    valueInteger: boolean;
}

export interface DashboardCardBodyText extends DashboardCardBody {
    description: string;
}

export interface DashboardCardBodyTable extends DashboardCardBody {
    tableType: TableValueType;
    rows: {
        label: string;
        value: string | number;
        unit?: SimpleUnit;
        valueInteger: boolean;
    }[];
}

export enum TableValueType {
    NumberValues = 'number',
    StringValues = 'string',
}

export abstract class PagesService {
    abstract getPages(): Observable<PageGroup[]>;

    abstract getDashboardCardBodyData(studyId?: string): Observable<DashboardCardBody[]>;
}
