import {ChangeDetectionStrategy, Component} from '@angular/core';
import {UsersStore} from '@store/common/users.store';

@Component({
    selector: 'ngx-header-settings',
    templateUrl: './header-settings.component.html',
    styleUrls: ['./header-settings.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [UsersStore],
})
export class HeaderSettingsComponent {
    constructor(private usersStore: UsersStore) {}

    readonly currentUser$ = this.usersStore.currentUser$;
}
