import {Injectable} from '@angular/core';
import {ReportingApi} from '../api/reporting.api';
import {OutputReportSource, ReportingService} from '@core/interfaces/engin/reporting';
import {Observable} from 'rxjs';

@Injectable()
export class ReportingServiceImpl extends ReportingService {
    constructor(private api: ReportingApi) {
        super();
    }

    getOutputReportGeneric(reportRequest): Observable<OutputReportSource> {
        return this.api.getOutputReportGeneric(reportRequest);
    }

    getOutputReportAnalyzer(studyId: string, filters: string[]): Observable<OutputReportSource> {
        return this.api.getOutputReportAnalyzer(studyId, filters);
    }
}
