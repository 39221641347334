import {Directive, HostListener, Input} from '@angular/core';

@Directive({
    selector: '[ngxOnlyNumber]',
})
export class OnlyNumberDirective {
    @Input() integer: boolean = true;

    @HostListener('keydown', ['$event']) onKeyDown(event) {
        //Allow Arrows, delete, backspace, tab, enter
        if ([8, 9, 13, 37, 38, 39, 40, 46].includes(event.keyCode)) return;
        if ((this.integer && !/\d/.test(event.key)) || (!this.integer && !/[0-9,\.]/.test(event.key))) {
            event.preventDefault();
        }
    }
}
