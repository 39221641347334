import {Component, Input, OnDestroy, OnInit, TemplateRef} from '@angular/core';
import {PagesStore} from '../../../@store';
import {combineLatest, Observable, Subject} from 'rxjs';
import {DisplaySettings, IconDetails, Page} from '../../../@core/interfaces/common/pages';
import {debounceTime, takeUntil} from 'rxjs/operators';
import {Router} from '@angular/router';

export class Breadcrumb {
    order: number;
    label: string;
    url?: any[];
    extras?: any;
}

@Component({
    selector: 'ngx-page-header-breadcrumb',
    templateUrl: './page-header-breadcrumb.component.html',
    styleUrls: ['./page-header-breadcrumb.component.scss'],
})
export class PageHeaderBreadcrumbComponent implements OnInit {
    @Input('breadcrumbs') _breadcrumbs: Breadcrumb[];

    public breadcrumbs: Breadcrumb[];

    constructor(public router: Router) {}

    ngOnInit() {
        this.breadcrumbs = this._breadcrumbs.sort((a, b) => (a.order > b.order ? 1 : b.order > a.order ? -1 : 0));
    }

    public onBreadcrumbClick(breadcrumb: any) {
        if (breadcrumb) {
            this.router.navigate(breadcrumb.url, {state: breadcrumb.extras});
        }
    }
}
