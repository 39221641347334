<div class="fluid-container multi-organization-container" *ngIf="organizationList | async as organizations">
    <div class="row justify-content-center">
        <div class="col main-title">Select Organization</div>
    </div>
    <div class="row justify-content-center">
        <div class="col sub-title">Select Organization you want to log in.</div>
    </div>
    <div class="row justify-content-center">
        <div class="col selector">
            <p>Organization</p>
            <nb-select [(selected)]="currentOrganization" fullWidth (selectedChange)="onOrganizationChange($event)">
                <nb-option *ngFor="let organization of organizations" [value]="organization.value">
                    {{ organization.label }}
                </nb-option>
            </nb-select>
        </div>
    </div>
    <div class="row justify-content-center">
        <div class="col login-button">
            <button (click)="login()" nbButton fullWidth status="primary" size="large">Log In</button>
        </div>
    </div>
</div>
