import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UsersStore} from '@store/common/users.store';

export enum MessageType {
    ALERT = 'alert',
    INFO = 'info',
}

@Component({
    selector: 'ngx-user-notification',
    templateUrl: './user-notification.component.html',
    styleUrls: ['./user-notification.component.scss'],
})
export class UserNotificationComponent implements OnInit {
    @Input() firstTime;
    @Input() messageList;
    @Input() clickEvent: boolean = true;
    @Input() height: string;

    constructor(private router: Router, private usersStore: UsersStore) {}

    ngOnInit(): void {
        this.messageList.map((item) => {
            item.open = true;
        });
        // this.closeTheMessage();
    }

    messageAction(item) {
        switch (item.type) {
            case MessageType.ALERT:
                this.router.navigate(['/user-management/user/profile-settings']);
                item.open = false;

                break;
            case MessageType.INFO:
                item.open = false;
                break;
        }
    }

    getIcon(type) {
        let result;
        switch (type) {
            case MessageType.ALERT:
                result = 'alert-circle';
                break;
            case MessageType.INFO:
                result = 'alert-triangle';
                break;
        }
        return result;
    }
}
