import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';
import {Observable, of} from 'rxjs';

/**
 Reset password component
 - Pre-auth (no login required)
 - Enter new, confirm password and reset via token (from password reset email, sent over URL). Automatically grabs token and resets password.
 */
@Component({
    selector: 'ngx-password-reset',
    templateUrl: './password-reset.component.html',
    styleUrls: ['./password-reset.component.scss'],
})
export class PasswordResetComponent implements OnInit {
    @Input() form: FormGroup;
    @Input() validation$: Observable<any> = of([]);
    @Output() onInputChanges = new EventEmitter();

    constructor() {}

    ngOnInit(): void {
        this.form.addValidators(this.mustMatch('newPassword', 'confirmPassword'));
    }

    get newPassword(): FormControl {
        return this.form.get('newPassword') as FormControl;
    }

    get confirmPassword(): FormControl {
        return this.form.get('confirmPassword') as FormControl;
    }

    onPasswordChanged($event) {
        this.onInputChanges.emit({password: $event.target.value});
    }

    private mustMatch(controlName: string, matchingControlName: string): ValidatorFn {
        return (formGroup: FormGroup): ValidationErrors => {
            const matchingControl = formGroup.controls[matchingControlName];
            const currentControl = formGroup.controls[controlName];
            if (matchingControl.errors && !matchingControl.errors.mustMatch) {
                return;
            }
            if (currentControl.value !== matchingControl.value) {
                matchingControl.setErrors({mustMatch: true});
            } else {
                matchingControl.setErrors(null);
            }
        };
    }
}
