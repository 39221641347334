import {Component, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {DefaultFilter} from 'ng2-smart-table';
import {FormControl} from '@angular/forms';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';

@Component({
    template: `
        <nb-select multiple [placeholder]="column.filter.config.selectText" [formControl]="inputControl">
            <nb-option *ngFor="let item of column.filter.config.list" [value]="item.value">{{ item.title }}</nb-option>
        </nb-select>
    `,
    styleUrls: ['./multi-select-filter.scss'],
})
export class MultiSelectFilterComponent extends DefaultFilter implements OnInit, OnChanges {
    constructor() {
        super();
    }

    inputControl = new FormControl([]);

    ngOnInit() {
        this.inputControl.valueChanges.pipe(distinctUntilChanged(), debounceTime(1000)).subscribe((value: string) => {
            this.query = value;
            this.setFilter();
        });
    }
    ngOnChanges(changes: SimpleChanges) {
        if (changes.query) {
            this.query = changes.query.currentValue;
            this.inputControl.setValue(this.inputControl.value);
        }
    }
}
