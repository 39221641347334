import {Directive, EventEmitter, Input, Output} from '@angular/core';

export interface FilterEvent {
    column: string;
    term: string;
}

@Directive({
    selector: '[ngxScFilterable]',
    host: {
        '(keyup)': 'submitTerm($event.target.value)',
    },
})
export class NgxScFilterableDirective {
    @Input() filterable: string;
    @Input() term: string = '';
    @Output() filter = new EventEmitter<FilterEvent>();

    submitTerm(value) {
        this.term = value;
        this.filter.emit({column: this.filterable, term: this.term});
    }
}
