import {SortColumn} from './ngx-sc-table-directive/ngx-sc-sortable.directive';
import {SortDirection} from '@swimlane/ngx-datatable';
import {PipeTransform} from '@angular/core';

export class NgxScTableFunction {
    // ======== Sorting
    static compare = (v1: string | number, v2: string | number) => (v1 < v2 ? -1 : v1 > v2 ? 1 : 0);

    // ===
    static sort(dataset, column: SortColumn, direction: string) {
        if (direction === '' || column === '') {
            return dataset;
        } else {
            return [...dataset].sort((a, b) => {
                const res = this.compare(a[column], b[column]);
                return direction === SortDirection.asc ? res : -res;
            });
        }
    }

    // ======== Filtering
    static checkMatch(dataItem: any, term: string, pipe: PipeTransform) {
        let matchResult = false;
        switch (typeof dataItem) {
            case 'string':
                if (dataItem.includes(term)) {
                    matchResult = true;
                }
                break;
            case 'number':
                if (pipe.transform(dataItem).includes(term)) {
                    matchResult = true;
                }

                break;

            default:
                break;
        }
        return matchResult;
    }

    // ===
    static matches(dataRow, term: string, pipe: PipeTransform) {
        let matchResult = false;
        // ======== start filtering
        if (term === '') {
            matchResult = true;
        } else {
            Object.entries(dataRow).forEach(([key, value]) => {
                if (!matchResult) {
                    matchResult = this.checkMatch(value, term, pipe);
                }
            });
        }
        return matchResult;
    }

    static matchesByColumn(dataRow, termMap: Map<string, string>, pipe: PipeTransform) {
        let matchResult = false;
        // ======== start filtering
        if (termMap.size === 0) {
            matchResult = true;
        } else {
            Object.entries(dataRow).forEach(([key, value]) => {
                if (!matchResult && termMap.get(key) !== '') {
                    matchResult = this.checkMatch(value, termMap.get(key), pipe);
                }
            });
        }
        return matchResult;
    }
}
