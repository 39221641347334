import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AuthApi} from '../api/auth.api';
import {AuthService, PasswordRequirementsWrapper} from '../../../interfaces/common/auth';
import {APIResponse} from '@core/interfaces/system/system-common';
import {PasswordRequirements} from '@core/interfaces/common/tenantSettings';

@Injectable()
export class AuthServiceImpl extends AuthService {
    constructor(private api: AuthApi) {
        super();
    }

    public resetPasswordWithToken(token: string, newPassword: string, confirmPassword: string): Observable<any> {
        return this.api.resetPasswordWithToken(token, newPassword, confirmPassword);
    }

    public verifyEmailWithToken(token: any): Observable<any> {
        return this.api.verifyEmailWithToken(token);
    }

    public passwordValidationByToken(
        token: string,
        password: string,
    ): Observable<APIResponse<PasswordRequirementsWrapper>> {
        return this.api.passwordValidationByToken(token, password);
    }

    public requestPasswordResetEmail(username: string): Observable<any> {
        return this.api.requestPasswordResetEmail(username);
    }

    public switchOrganization(tenantId: string): Observable<any> {
        return this.api.switchOrganization(tenantId);
    }
}
