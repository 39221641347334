import {Observable} from 'rxjs';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {APIResponse} from '../system/system-common';
import {SnapshotDto} from '../common/snapshot';
import {PagingResponse} from '../system/system-common';

export interface Workflow {
    id: number;
    createdOn: string;
    updatedOn: string;
    name: string;
    description: string;
    snapshotId: number;
    workflowType: WorkflowType;
    snapshotVersion: number;
    status: string;
    startTime: string;
    endTime: string;
    tag: string;
    note: string;
    createdBy: string;
    createdByName: string;
    studies?: WorkflowItem[];
    duration: string;
}
export enum WorkflowType {
    STUDY = 'STUDY',
    CURVE_CALIBRATION = 'CURVE_CALIBRATION',
    CONNECTIVITY_ANALYSIS = 'CONNECTIVITY_ANALYSIS',
}
export const WorkflowTypeLabel = {
    [WorkflowType.STUDY]: 'Study',
    [WorkflowType.CURVE_CALIBRATION]: 'Curve Calibration',
    [WorkflowType.CONNECTIVITY_ANALYSIS]: 'Connectivity Analysis',
};

export interface WorkflowItemParam {
    id: number;
    createdOn: string;
    updatedOn?: any;
    workflowItemId: number;
    paramKey: string;
    value: string;
}

export interface WorkflowItem {
    id: number;
    createdOn: string;
    updatedOn?: any;
    itemName: string;
    sensitivityCode: string;
    status: string;
    defaultItem: boolean;
    executionOrder: number;
    startTime?: any;
    endTime?: any;
    currentYear: number;
    evaluationPeriod: number;
    workflowId: number;
    itemParams: WorkflowItemParam[];
}

export abstract class WorkflowService {
    abstract getWorkflowDataSource(type: WorkflowType): Observable<DataSource>;

    abstract getWorkflowById(workflowId: number): Observable<APIResponse<Workflow>>;

    abstract getWorkflowItemById(workflowItemId: number): Observable<APIResponse<WorkflowItem>>;

    abstract getLatestCompletedWorkFlow(istrue: boolean): Observable<APIResponse<Workflow>>;

    abstract updateWorkflowNoteTag(workflowId: number, Workflow): Observable<APIResponse<Workflow>>;

    abstract executeNewWorkflowFromSnapshot(
        snapshotId: number,
        workflowType: string,
        automated: boolean,
        date?: string,
    ): Observable<any>;

    abstract findBySnapshotId(snapshotId: number): Observable<APIResponse<Workflow>>;

    abstract findAllBySnapshotId(snapshotId: number): Observable<APIResponse<Array<Workflow>>>;

    abstract findBySnapshotDtoByWorkflowId(workflowId: number): Observable<APIResponse<SnapshotDto>>;

    abstract delete(workflowId: number): Observable<APIResponse<boolean>>;

    abstract reversePendingDelete(workflowId: number): Observable<APIResponse<boolean>>;

    abstract updateActiveWorkflow(
        workflowId: number,
        userId: string,
        active: boolean,
    ): Observable<APIResponse<boolean>>;

    abstract listLatestWorkflows(pageSize: number, workflowType: string): Observable<PagingResponse<Workflow>>;
}
