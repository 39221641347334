<div *ngIf="reportFile; else defaultGraph" class="report-card">
    <div class="height-100" *ngIf="chartData">
        <nb-card class="status-card">
            <nb-card-body class="chart-card-report">
                <ngx-chart [options]="chartData.options"></ngx-chart>
            </nb-card-body>
        </nb-card>
    </div>
</div>

<ng-template #defaultGraph>
    <div class="height-100" *ngIf="chartData">
        <ngx-chart [options]="chartData.options"></ngx-chart>
    </div>
</ng-template>
