import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {shareReplay, takeUntil} from 'rxjs/operators';
import {Unsubscribable} from '../../@core/interfaces/unsubscribable';
import {DatasetType} from '../../@core/interfaces/common/dataset';
import {ConfigurationStore} from '../config/configuration.store';
import {FormBuilder} from '@angular/forms';

@Injectable()
export class DataManagementStore extends Unsubscribable {
    readonly datasetList$ = this.configurationStore.datasetList$.pipe(takeUntil(this.unsubscribe$));

    private snapshot = new BehaviorSubject(null);
    readonly snapshot$ = this.snapshot.asObservable();

    private currentDatasetType = new BehaviorSubject<DatasetType>(null);
    readonly currentDatasetType$: Observable<DatasetType> = this.currentDatasetType.asObservable();

    setCurrentDatasetType(value: DatasetType) {
        this.currentDatasetType.next(value);
    }

    hasCurrentDatasetType = (): boolean => {
        return this.currentDatasetType.value !== null;
    };

    private previewDatasetIds: BehaviorSubject<number[]> = new BehaviorSubject<number[]>([]);
    readonly previewDatasetIds$: Observable<number[]> = this.previewDatasetIds.asObservable().pipe(shareReplay(1));

    public getPreviewDatasetIds(): number[] {
        return this.previewDatasetIds.getValue();
    }
    public setPreviewDatasetIds(selectedIds: number[]) {
        this.previewDatasetIds.next(selectedIds);
    }

    constructor(private configurationStore: ConfigurationStore, private fb: FormBuilder) {
        super();
    }
}
