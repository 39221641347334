import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ViewCell} from '@mominsamir/ngx-smart-table';
import {StudiesStore} from '../../../@store';
import {Workflow} from '../../../@core/interfaces/engin/workflow';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {Unsubscribable} from '../../../@core/interfaces/unsubscribable';
import {takeUntil} from 'rxjs/operators';
import {NbToastrService} from '@nebular/theme';

@Component({
    selector: 'ngx-active-study-selector',
    template: `
        <ng-container
            *ngIf="{
                activeWorkflowId: activeWorkflowId$ | async,
                dataLoading: this.studiesStore.dataLoading$ | async
            } as data"
        >
            <nb-icon
                icon="power-outline"
                class="not-active-study-icon"
                [ngClass]="{
                    'active-study-icon': data.activeWorkflowId === currentWorkflowId,
                    'not-active-study-icon': data.activeWorkflowId !== currentWorkflowId
                }"
                (click)="setActiveCollection(rowData, data.dataLoading)"
            >
            </nb-icon>
        </ng-container>
    `,
    styleUrls: ['./study-selector.component.scss'],
})
export class ActiveStudySelectorComponent extends Unsubscribable implements ViewCell, OnInit, OnDestroy {
    @Input() rowData: Workflow;
    @Input() value: string | number;

    private activeWorkflowId: BehaviorSubject<number> = new BehaviorSubject<number>(-1);
    readonly activeWorkflowId$: Observable<number> = this.activeWorkflowId.asObservable();
    currentWorkflowId: number;

    constructor(public studiesStore: StudiesStore, private toastrService: NbToastrService) {
        super();
    }

    private activeStudySubscription: Subscription;

    ngOnInit(): void {
        this.activeStudySubscription = this.studiesStore.activeCollectionId$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((activeCollectionId) => {
                this.activeWorkflowId.next(parseInt(activeCollectionId, 10));
            });
        this.currentWorkflowId = this.rowData.id;
    }

    setActiveCollection(rowData, dataLoading) {
        if (dataLoading) {
            this.toastrService.info(
                'Active study is already changing. Please wait for this to finish.',
                'Please wait',
                {duration: 10000},
            );
        } else {
            if (rowData.status !== 'SUCCESS' && rowData.status !== 'SUCCESS_WITH_WARNING') {
                this.toastrService.danger(
                    'Study collection is incomplete and cannot be set to active collection. Please ' +
                        'select a study collection which has been successfully completed.',
                    'Active study collection not updated!',
                    {duration: 10000},
                );
            } else {
                this.studiesStore.updateActiveCollectionId(rowData.id);
            }
        }
    }

    ngOnDestroy() {
        this.activeStudySubscription && this.activeStudySubscription.unsubscribe();
    }
}
