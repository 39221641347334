import {Component, ChangeDetectionStrategy} from '@angular/core';
import {NbRouteTabsetComponent} from '@nebular/theme';

@Component({
    selector: 'ngx-route-tabset',
    styleUrls: ['./route-tabset.component.scss'],
    template: `
        <ul class="route-tabset">
            <ng-container *ngFor="let tab of tabs">
                <li class="route-tab-container">
                    <ngx-icon-box
                        (click)="selectTab(tab)"
                        class=" route-tab -container"
                        [routerLink]="tab.route"
                        [routerLinkActiveOptions]="{exact: true}"
                        routerLinkActive="active "
                        [title]="tab.title"
                        [icon]="tab.evaIcon"
                    >
                    </ngx-icon-box>
                    <div class="route-tab-pointer"></div>
                </li>
            </ng-container>
        </ul>
        <div class="tabset-content">
            <router-outlet></router-outlet>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RouteTabsetComponent extends NbRouteTabsetComponent {}
