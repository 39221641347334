<!--{{shMain}}{{shL1}}{{shL2}}-->
<div class="dropdown-component" [ngClass]="{selected: shMain, editable: editableState, required: requiredState}">
    <div class="main-box list-group-item">
        <ng-container *ngIf="!shMain">
            <div class="labelBox" (click)="toggleMainMenu()">{{ curSelectedSummary }}</div>
        </ng-container>

        <ng-container *ngIf="shMain">
            <input class="searchBox" (keyup)="term$.next($event.target.value)" placeholder="Search" />
        </ng-container>

        <nb-icon
            class="float-right text-secondary arrow-icon"
            [icon]="this.shMain ? 'arrow-ios-upward-outline' : 'arrow-ios-downward-outline'"
            (click)="toggleMainMenu()"
        ></nb-icon>
        <!--    [ngClass]="{'float-right':!shMain}"-->
    </div>

    <div class="dropdown-box" [ngbCollapse]="!shMain">
        <!---->
        <div
            class="list-group-item font-weight-bold display-flex justify-content-sm-between"
            *ngIf="curSelectedNumber && shL1 && is2Level"
        >
            <span>{{ curSelectedNumber }} Selected</span>
            <span class="text-primary font-weight-bold clickable" (click)="cleanAllSelected(filterData)">
                x Clear
            </span>
        </div>

        <!-- Level 1 -->
        <ng-container *ngIf="shL1 && is2Level">
            <ng-template *ngTemplateOutlet="tplLevel1; context: {L1Array: filterData}"></ng-template>
        </ng-container>
        <ng-container *ngIf="shL1 && !is2Level">
            <ng-template *ngTemplateOutlet="tplLevel2; context: {L1Selected: {options: filterData}}"></ng-template>
        </ng-container>

        <!-- Level 2 -->
        <ng-container *ngIf="shL2 && is2Level">
            <ng-template *ngTemplateOutlet="tplLevel2; context: {L1Selected: curEnteredL1}"></ng-template>
        </ng-container>
    </div>
</div>
<!--Template Level 1-->
<ng-template #tplLevel1 let-L1Array="L1Array">
    <div
        class="list-group-item menu-item display-flex justify-content-around"
        *ngFor="let l1Item of L1Array; let i = index"
        (click)="l2ExpandController(i, true)"
    >
        <span style="flex: 2 1 auto">
            <span class="font-weight-bold">{{ l1Item.name }}</span>

            <span class="font-weight-bold numberBox" *ngIf="l1Item.curSelectedNumber">
                {{ l1Item.curSelectedNumber }}
            </span>
        </span>
        <nb-icon class="text-secondary arrow-icon" icon="chevron-right-outline"></nb-icon>
    </div>
</ng-template>

<!--Template CheckBox-->
<ng-template #tplLevel2 let-L1Selected="L1Selected">
    <div class="level2-box">
        <ng-container *ngIf="is2Level">
            <div class="list-group-item menu-item" (click)="l2ExpandController(null, false)">
                <nb-icon icon="chevron-left-outline" class="text-secondary"></nb-icon>
                <span class="font-weight-bold">{{ L1Selected.name }}</span>
            </div>
        </ng-container>

        <ng-container *ngFor="let l2Item of L1Selected.options">
            <div class="list-group-item menu-item">
                <nb-checkbox
                    [class.pointer-none]="l2Item.selected === true && !l2Item.selectedBeforeApp"
                    [id]="l2Item.code"
                    [checked]="l2Item.selected"
                    (checkedChange)="onSelected($event, l2Item)"
                    [disabled]="l2Item.disabled"
                >
                    <span class="font-weight-bold">{{ l2Item.name }}</span>
                </nb-checkbox>
            </div>
        </ng-container>
        <div class="btn-container">
            <button nbButton status="primary" size="small" (click)="applySelection(this.currentSelectedTemp)" fullWidth>
                Apply {{ listen2CrossSelector | uppercase }}ing
            </button>
        </div>
    </div>
</ng-template>

<!--<div ngbDropdown container="body">-->
<!--    <button class="btn btn-outline-primary btn-sm" ngbDropdownToggle>Actions</button>-->
<!--    <div ngbDropdownMenu>-->
<!--        <div ngbDropdownItem *ngIf="numSelected>0">-->
<!--            {{numSelected}} Selected-->
<!--            <span> x Clear </span>-->
<!--        </div>-->
<!--        <div *ngIf="numSelected>0" class="dropdown-divider"></div>-->

<!--        <button ngbDropdownItem>Duplicate</button>-->
<!--        <button ngbDropdownItem>Move</button>-->
<!--        <div class="dropdown-divider"></div>-->
<!--        <button ngbDropdownItem>Delete</button>-->
<!--    </div>-->
<!--</div>-->
