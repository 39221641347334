import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'ngx-button-group',
    template: ` <div *ngFor="let btn of btnTitles; let i = index" class="btn-container">
        <button
            class="buttons group-btn"
            nbButton
            (click)="buttonClicked(btn)"
            [ngClass]="{'first-btn': i === 0, 'last-btn': i === btnTitles.length - 1}"
        >
            {{ btn }}
        </button>
    </div>`,
    styleUrls: ['./button-group.component.scss'],
})
export class ButtonGroupComponent {
    @Input() btnTitles;
    @Output() btnValue = new EventEmitter<string>();
    buttonClicked(value) {
        this.btnValue.emit(value);
    }
}
