import {ReportType} from './report-type';

export class ReportList {
    reportTypes: ReportType[];
    reportCustomizations: ReportCustomization[];
}

// ========
export class ReportCustomization {
    code: string;
    name: string;
    enabled: boolean;
    // ===
    userDefined: boolean;
    typeCode: string; // ---> ReportType.code
    config: ReportConfig[];
}

export class ReportConfig {
    code: string;
    name: string;
    measureCode: string; // ---> RTMeasure.code
    // Status
    status: ReportStatus;
    selected: boolean;
    // Grouping and filtering
    groupStatus: ReportStatus[];
    groups: ReportGroup[];
    filterStatus: ReportStatus[];
    filters: ReportFilter[];
    // Optional submeasures and components
    submeasures: any;
    components: any;
}

// ===
export class ReportGroup {
    code: string; // ---> RTGroup.code
    enabled: boolean;
}

// ===
export class ReportFilter {
    code: string; // ---> RTFilter.code
    options: ReportOption[];
}

export class ReportOption {
    code: string; // ---> RTFilterValue.code
    enabled: boolean;
}

// ===
export class ReportStatus {
    selected: boolean;
    enabled: boolean;
    required: boolean;
}
