import {Injectable, OnDestroy} from '@angular/core';
import {StudiesStore} from './studies.store';
import {combineLatest, Observable, Subscription} from 'rxjs';
import {exhaustMap, switchMap, take, takeUntil} from 'rxjs/operators';
import {Report} from '@core/interfaces/common/pages';
import {User, UsersService} from '@core/interfaces/common/users';
import {Workflow} from '@core/interfaces/engin/workflow';
import {UsageAnalyticsService} from '@core/utils/usage-analytics.service';
import {AnalyzerService} from '@core/interfaces/engin/analyzer';
import {Unsubscribable} from '@core/interfaces/unsubscribable';
import {OutputReportSource, ReportingService, ReportRequest} from '@core/interfaces/engin/reporting';

@Injectable()
export class ReportsStore extends Unsubscribable implements OnDestroy {
    private subscription: Subscription;

    constructor(
        private analyzerService: AnalyzerService,
        private reportingService: ReportingService,
        private studiesStore: StudiesStore,
        private userService: UsersService,
        private usageAnalyticsService: UsageAnalyticsService,
    ) {
        super();
    }

    readonly assetClassList$: Observable<string[]> = this.studiesStore.activeStudyId$.pipe(
        takeUntil(this.unsubscribe$),
        switchMap((activeStudyId) => {
            return this.analyzerService.getAssetClassList(activeStudyId);
        }),
    );

    downloadOutputReportGeneric(report: Report, sensitivityItem: any) {
        this.subscription = combineLatest<Observable<string>, Observable<Workflow>, Observable<User>>([
            this.studiesStore.activeStudyId$,
            this.studiesStore.activeCollection$,
            this.userService.getCurrentUser(),
        ])
            .pipe(
                exhaustMap(([studyId, activeCollection, user]: [string, Workflow, User]) => {
                    const req = this.prepareReportRequest(report, studyId, user, activeCollection, sensitivityItem);
                    return this.reportingService.getOutputReportGeneric(req);
                }),
                take(1),
            )
            .subscribe((out: OutputReportSource) => {
                out.links.forEach((item) => {
                    if (item && item !== 'none') {
                        window.open(item, '_blank');
                    }
                });
            });
        this.usageAnalyticsService.logDownload('Unknown', 'Generic Report - ' + report.reportId);
    }

    downloadOutputReportAnalyzer(report: Report, sensitivityItem: any, filters: string[]) {
        combineLatest<Observable<string>, Observable<Workflow>>([
            this.studiesStore.activeStudyId$,
            this.studiesStore.activeCollection$,
        ])
            .pipe(
                takeUntil(this.unsubscribe$),
                switchMap(([studyId, activeCollection]: [string, Workflow]) => {
                    const finalStudyId = sensitivityItem
                        ? this.getActiveStudyId(sensitivityItem, activeCollection)
                        : studyId;
                    return this.reportingService.getOutputReportAnalyzer(finalStudyId, filters);
                }),
                take(1),
            )
            .subscribe((out: OutputReportSource) => {
                out.links.forEach((item) => {
                    if (item && item !== 'none') {
                        window.open(item, '_blank');
                    }
                });
            });
        this.usageAnalyticsService.logDownload('Unknown', 'Analyzer Report');
    }

    private prepareReportRequest(
        report: Report,
        studyId: string,
        user: User,
        activeCollection: Workflow,
        sensitivityItem: any,
    ): ReportRequest {
        // In the Analyzer module the user may toggle between active studies using the popout panel
        let finalStudyId = studyId;
        if (report.reportId === 'analyzer') {
            finalStudyId = sensitivityItem ? this.getActiveStudyId(sensitivityItem, activeCollection) : studyId;
        }

        // The Optimizer and Forecaster modules perform sensitivity analysis on top of the active studyId
        let sensitivityFlag = false;
        if (report.reportId === 'optimizer' || report.reportId === 'forecaster') {
            sensitivityFlag = true;
        }

        return new ReportRequest(report, finalStudyId, user.id, sensitivityFlag);
    }

    private getActiveStudyId(sensitivityItem: any, activeCollection: Workflow): string {
        const relatedStudy = activeCollection.studies.filter((study) => study.sensitivityCode === sensitivityItem.code);
        if (relatedStudy.length > 0 && relatedStudy[0].id) {
            return relatedStudy[0].id.toString();
        } else {
            return null;
        }
    }

    ngOnDestroy(): void {
        this.subscription && this.subscription.unsubscribe();
    }
}
