import {ModuleWithProviders, NgModule} from '@angular/core';
import {OptimizerService} from '../../interfaces/engin/optimizer';
import {OptimizerServiceImpl} from './services/optimizer.service';
import {AssetsApi} from './api/assets.api';
import {AnalyzerApi} from './api/analyzer.api';
import {OptimizerApi} from './api/optimizer.api';
import {AssetsServiceImpl} from './services/assets.service';
import {AssetsService} from '../../interfaces/engin/assets';
import {AnalyzerService} from '../../interfaces/engin/analyzer';
import {AnalyzerServiceImpl} from './services/analyzer.service';
import {CommonModule} from '@angular/common';
import {CommonBackendModule} from '../common/common-backend.module';
import {ForecasterApi} from './api/forecaster.api';
import {ForecasterService} from '../../interfaces/engin/forecaster';
import {ForecasterServiceImpl} from './services/forecaster.service';
import {PlannerApi} from './api/planner.api';
import {AssetDataAuditApi} from './api/data-audit/asset-data-audit.api';
import {AssetDataAuditService} from '../../interfaces/engin/data-audit/asset-data-audit';
import {AssetDataAuditServiceImpl} from './services/data-audit/asset-data-audit.service';
import {WorkflowApi} from './api/workflow.api';
import {WorkflowService} from '@core/interfaces/engin/workflow';
import {WorkflowServiceImpl} from './services/workflow.service';
import {WorkflowValidationService} from '../../interfaces/engin/workflow-validation';
import {WorkflowValidationServiceImpl} from './services/workflow-validation.service';
import {WorkflowValidationApi} from './api/workflow-validation.api';
import {EvaluationDataAuditApi} from './api/data-audit/evaluation-data-audit.api';
import {EvaluationDataAuditServiceImpl} from './services/data-audit/evaluation-data-audit.service';
import {EvaluationDataAuditService} from '../../interfaces/engin/data-audit/evaluation-data-audit';
import {ReportingApi} from '@core/backend/engin/api/reporting.api';
import {ReportingService} from '@core/interfaces/engin/reporting';
import {ReportingServiceImpl} from '@core/backend/engin/services/reporting.service';
import {ConnectivityApi} from '@core/backend/engin/api/connectivity.api';
import {ConnectivityService} from '@core/interfaces/engin/connectivity';
import {ConnectivityServiceImpl} from '@core/backend/engin/services/connectivity.service';
import {ProgramManagementApi} from '@core/backend/engin/api/program-management.api';
import {ProgramManagementService} from '@core/interfaces/engin/program-management';
import {ProgramManagementServiceImpl} from '@core/backend/engin/services/program-management.service';

const API = [
    AnalyzerApi,
    AssetsApi,
    OptimizerApi,
    ForecasterApi,
    WorkflowApi,
    WorkflowValidationApi,
    AssetDataAuditApi,
    EvaluationDataAuditApi,
    ConnectivityApi,
    ReportingApi,
    PlannerApi,
    ProgramManagementApi,
];

const SERVICES = [
    {provide: AnalyzerService, useClass: AnalyzerServiceImpl},
    {provide: AssetsService, useClass: AssetsServiceImpl},
    {provide: OptimizerService, useClass: OptimizerServiceImpl},
    {provide: ForecasterService, useClass: ForecasterServiceImpl},
    {provide: WorkflowService, useClass: WorkflowServiceImpl},
    {provide: WorkflowValidationService, useClass: WorkflowValidationServiceImpl},
    {provide: AssetDataAuditService, useClass: AssetDataAuditServiceImpl},
    {provide: EvaluationDataAuditService, useClass: EvaluationDataAuditServiceImpl},
    {provide: ConnectivityService, useClass: ConnectivityServiceImpl},
    {provide: ReportingService, useClass: ReportingServiceImpl},
    {provide: ProgramManagementService, useClass: ProgramManagementServiceImpl},
];

@NgModule({
    imports: [CommonModule, CommonBackendModule],
    exports: [CommonBackendModule],
})
export class EnginBackendModule {
    static forRoot(): ModuleWithProviders<EnginBackendModule> {
        return {
            ngModule: EnginBackendModule,
            providers: [...API, ...SERVICES],
        };
    }
}
