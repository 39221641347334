export class ReportType {
    // implements SimpleCodeNameSelection
    code: string; // ID
    name: string;
    enabled: boolean;
    rtConfig: RTConfig[];
    // ===
    selected: boolean;
}

export class RTConfig {
    rtMeasures: RTMeasure;
    rtGroups: RTGroup;
    rtFilters: RTFilter;
}

export class RTMeasure {
    code: string;
    name: string;
    enabled: boolean;
    subMeasures: string[];
    components: string[];
}

// ===

export class RTGroup {
    code: string;
    name: string;
    enabled: boolean;
    // ========
    index: number;
}

// ===
export class RTFilter {
    code: string;
    name: string;
    enabled: boolean;
    rtOptions: ReportOption[];
}

export class ReportOption {
    code: string;
    name: string;
}
