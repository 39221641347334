import {Injectable} from '@angular/core';
import {HttpService} from '../../common/api/http.service';
import {Observable} from 'rxjs';
import {OutputReportSource} from '@core/interfaces/engin/reporting';
import {ReportRequest} from '@core/interfaces/engin/reporting';

@Injectable()
export class ReportingApi {
    private readonly prefix = 'reporting/data-extract';

    constructor(private api: HttpService) {}

    getOutputReportGeneric(reportRequest: ReportRequest): Observable<OutputReportSource> {
        return this.api.post(`${this.prefix}/generic`, reportRequest);
    }

    getOutputReportAnalyzer(studyId: string, filters: string[]): Observable<OutputReportSource> {
        let params = filters.length > 0 ? '?' + filters.map((o) => 'assetClass=' + o).join('&') : '';
        return this.api.get(`${this.prefix}/analyzer-filter/${studyId}${params}`);
    }
}
