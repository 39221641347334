import {
    AccessibilityChartColors,
    AccessibilityGradientCategoryColors,
    AccessibilityGradientNumericColors,
    AccessibilityNamedColors,
} from './theme-colors';

export const DEFAULT_ACCESSIBILITY_THEME = {
    name: 'default_accessibility',
    base: null,
    variables: {
        chartColors: [
            AccessibilityChartColors.color1, // 0 (figma color 11)
            AccessibilityChartColors.color2, // 1 (figma color 9)
            AccessibilityChartColors.color3, // 2 (figma color 2)
            AccessibilityChartColors.color4, // 3 (figma color 8)
            AccessibilityChartColors.color5, // 4 (figma color 1)
            AccessibilityChartColors.color11, // 5 (figma color 4) // waring color
            AccessibilityChartColors.color8, // 6 (figma color 3)
            AccessibilityChartColors.color12, // 7 (figma color 12)
            AccessibilityChartColors.color9, // 8 (figma color 7)
            '#424d69', // 9
            AccessibilityChartColors.color10, // 10 (figma color 13)
            AccessibilityChartColors.color7, // 11 (figma color 14)
            AccessibilityChartColors.color13, // 12 (figma color 15)
            AccessibilityChartColors.color6, // 13 (figma color 6)
            '#ffd5de', // 14
            '#b4e9db', // 15
            '#fef3d5', // 16
            '#eabeff', // 17
            '#cae5ff', // 18
            '#e9f4b1', // 19
            '#ffe6b3', // 20
            '#d6d3ff', // 21
            '#c1eef2', // 22
            '#e4e6eb', // 23
            '#424d69', // 24
            '#879ac3', // 25
            AccessibilityChartColors.color14, // 26 (figma color 5),
            AccessibilityChartColors.color15, // 27 (figma color 10),
        ],
        namedColors: {
            empty: AccessibilityNamedColors.empty,
            gradientCategory: {
                color1: AccessibilityGradientCategoryColors.color1,
                color2: AccessibilityGradientCategoryColors.color2,
                color3: AccessibilityGradientCategoryColors.color3,
                color4: AccessibilityGradientCategoryColors.color4,
                color5: AccessibilityGradientCategoryColors.color5,
            },
            gradientNumeric: {
                color1: AccessibilityGradientNumericColors.color1,
                color2: AccessibilityGradientNumericColors.color2,
                color3: AccessibilityGradientNumericColors.color3,
                color4: AccessibilityGradientNumericColors.color4,
                color5: AccessibilityGradientNumericColors.color5,
            },
        },

        primary_default: '#fe9339',
        secondary_default: '#0c8ac7',
        warning_default: '#f9bf4c',

        fontColor: '#202840',
        fontHintColor: '#9fa9bd',
        tooltipBg: '#ffffff',
        backgroundChartColor: '#fff',

        axisSliderBackground: '#ffffff', // color-basic-100
        axisSliderShadow: '#c5ccdb', // color-basic-500
        axisSliderHandle: '#9fa9bd', // color-basic-600
    },
};
