import {ChangeDetectorRef, Component, Input} from '@angular/core';
import {SidebarStore} from '../../../@store';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import {UsageAnalyticsService} from '@core/utils/usage-analytics.service';

@Component({
    selector: 'ngx-report-navbar',
    templateUrl: './report-navbar.component.html',
    styleUrls: ['./report-navbar.component.scss'],
})
/* tslint:disable */
export class ReportNavbarComponent {
    @Input() reportContainer;

    constructor(
        private sidebarStore: SidebarStore,
        private cd: ChangeDetectorRef,
        private usageAnalyticsService: UsageAnalyticsService,
    ) {
        sidebarStore.setMainPage(false);
    }

    printPage() {
        window.print();
    }

    savePDF(ele: HTMLElement) {
        const pdf = new jsPDF();
        const h = ele.clientHeight;
        const w = ele.clientWidth;
        html2canvas(ele).then((canvas) => {
            const contentWidth = canvas.width;
            const contentHeight = canvas.height;
            const pageHeight = (contentWidth / 592.28) * 841.89;
            let leftHeight = contentHeight;
            let position = 0;
            const imgWidth = 595.28;
            const imgHeight = (592.28 / contentWidth) * contentHeight;
            const pageData = canvas.toDataURL('image/jpeg', 1.0);
            const pdf = new jsPDF('p', 'pt', 'a4');
            let totalPage = 0;
            if (leftHeight < pageHeight) {
                pdf.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight);
            } else {
                while (leftHeight > 0) {
                    pdf.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight);
                    leftHeight -= pageHeight;
                    position -= 841.89;
                    totalPage += 1;
                    if (leftHeight > 0) {
                        pdf.addPage();
                    }
                }
            }
            pdf.deletePage(totalPage);
            pdf.save('ENGIN_PDF_Report.pdf');
            this.usageAnalyticsService.logDownload('Unknown', 'PDF Report > Navbar');
        });
    }
}
