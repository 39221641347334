import {ChartColors, GradientCategoryColors, GradientNumericColors, NamedColors} from './theme-colors';

export const DARK_THEME = {
    name: 'dark',
    base: 'default',
    variables: {
        chartColors: [
            ChartColors.color1, // 0 (figma color 11)
            ChartColors.color2, // 1 (figma color 9)
            ChartColors.color3, // 2 (figma color 2)
            ChartColors.color4, // 3 (figma color 8)
            ChartColors.color5, // 4 (figma color 1)
            ChartColors.color11, // 5 (figma color 4)
            ChartColors.color8, // 6 (figma color 3)
            ChartColors.color12, // 7 (figma color 12)
            ChartColors.color9, // 8 (figma color 7)
            '#424d69', // 9
            ChartColors.color10, // 10 (figma color 13)
            ChartColors.color7, // 11 (figma color 14)
            ChartColors.color13, // 12 (figma color 15)
            ChartColors.color6, // 13 (figma color 6)
            '#ffd5de', // 14
            '#b4e9db', // 15
            '#fef3d5', // 16
            '#eabeff', // 17
            '#cae5ff', // 18
            '#e9f4b1', // 19
            '#ffe6b3', // 20
            '#d6d3ff', // 21
            '#c1eef2', // 22
            '#e4e6eb', // 23
            '#424d69', // 24
            '#879ac3', // 25,
            ChartColors.color14, // 26 (figma color 5),
            ChartColors.color15, // 27 (figma color 10),
        ],
        namedColors: {
            empty: NamedColors.empty,
            gradientCategory: {
                color1: GradientCategoryColors.color1,
                color2: GradientCategoryColors.color2,
                color3: GradientCategoryColors.color3,
                color4: GradientCategoryColors.color4,
                color5: GradientCategoryColors.color5,
            },
            gradientNumeric: {
                color1: GradientNumericColors.color1,
                color2: GradientNumericColors.color2,
                color3: GradientNumericColors.color3,
                color4: GradientNumericColors.color4,
                color5: GradientNumericColors.color5,
            },
        },

        primary_default: '#fe9339',
        secondary_default: '#0c8ac7',
        warning_default: '#f9bf4c',
        warn_default: '#f0735b',

        fontColor: '#fff',
        fontHintColor: '#9fa9bd',
        backgroundChartColor: '#202840',

        tooltipBg: '#202840',

        axisSliderBackground: '#202840', // color-basic-900
        axisSliderShadow: '#424d69', // color-basic-700
        axisSliderHandle: '#131729', // color-basic-1100
    },
};
