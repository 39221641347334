import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {Router} from '@angular/router';
import {StudiesStore} from '../../../@store';
import {SupportService} from '../../../@core/interfaces/common/support';
import {takeUntil} from 'rxjs/operators';
import {Unsubscribable} from '../../../@core/interfaces/unsubscribable';
import {GeospatialViewerService} from '../../../pages/geospatial-viewer/api/geospatial-viewer.service';
import {GeospatialViewerApi} from '../../../pages/geospatial-viewer/api/geospatial-viewer.api';
import {User, UsersService} from '@core/interfaces/common/users';

@Component({
    selector: 'ngx-header-settings-dialog',
    templateUrl: './header-settings-dialog.component.html',
    styleUrls: ['./header-settings.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [GeospatialViewerService, GeospatialViewerApi],
})
export class HeaderSettingsDialogComponent extends Unsubscribable {
    @Input() user: User;
    showOrganizationList = false;
    hoveringOrganizationList = false;
    constructor(private router: Router, private studiesStore: StudiesStore, private supportService: SupportService) {
        super();
    }

    logout() {
        this.router.navigate(['/auth/logout']);
        this.studiesStore.clearActiveCollectionId();
        localStorage.removeItem('auth_app_token');
    }

    // Generate URL and reroute user
    public navigateUrl() {
        this.supportService
            .accessKnowledgeBase()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((res) => {
                // Open URL in new window
                if (res) {
                    window.open(res);
                }
            });
    }
}
