import {Observable} from 'rxjs';
import {APIResponse} from '@core/interfaces/system/system-common';
import {PasswordRequirements} from '@core/interfaces/common/tenantSettings';

export interface PasswordRequirementsWrapper {
    found: boolean;
    data: PasswordRequirements;
}

export abstract class AuthService {
    abstract resetPasswordWithToken(token: string, newPassword: string, confirmPassword: string): Observable<any>;

    abstract verifyEmailWithToken(token: string): Observable<any>;

    abstract passwordValidationByToken(
        token: string,
        password: string,
    ): Observable<APIResponse<PasswordRequirementsWrapper>>;

    abstract requestPasswordResetEmail(username: string): Observable<any>;

    abstract switchOrganization(tenantId: string): Observable<any>;
}
