<div class="help-support-container">
    <nb-card [formGroup]="helpDeskInfoFormGroup" class="help-support-container">
        <!-- Header -->
        <nb-card-header>
            <div class="container-fluid">
                <div class="row justify-content-between">
                    <div class="col-11">
                        {{ dialogTitle }}
                        <p>{{ dialogSubtitle }}</p>
                    </div>
                    <div class="col-1" (click)="closeMe()">
                        <nb-icon icon="close" style="color: #a6aebd; cursor: pointer"></nb-icon>
                    </div>
                </div>
            </div>
        </nb-card-header>

        <!-- Body, collects support information -->
        <nb-card-body>
            <div class="container-fluid">
                <div class="row">
                    <div class="col-6">
                        <div class="container">
                            <div class="row">
                                <div class="col p-0">
                                    <div>
                                        <!-- User name -->
                                        <p class="labels">{{ dialogFieldName }}</p>
                                        <input
                                            class="input-boxes"
                                            nbInput
                                            fullWidth
                                            fieldSize="large"
                                            formControlName="name"
                                            [status]="
                                                (nameFormControl.touched && nameFormControl?.hasError('required')) ||
                                                nameFormControl?.hasError('pattern')
                                                    ? 'danger'
                                                    : ''
                                            "
                                            placeholder="User Name"
                                        />
                                        <ngx-validation-message
                                            label="{{ dialogValidationName }}"
                                            [showPattern]="
                                                nameFormControl.touched && nameFormControl?.hasError('pattern')
                                            "
                                            [showRequired]="
                                                nameFormControl.touched && nameFormControl?.hasError('required')
                                            "
                                        >
                                        </ngx-validation-message>
                                    </div>

                                    <!-- Email -->
                                    <div>
                                        <p class="labels">{{ dialogFieldEmail }}</p>
                                        <input
                                            class="input-boxes"
                                            nbInput
                                            fullWidth
                                            fieldSize="large"
                                            formControlName="email"
                                            [status]="
                                                (emailFormControl.touched && emailFormControl?.hasError('required')) ||
                                                emailFormControl?.hasError('pattern')
                                                    ? 'danger'
                                                    : ''
                                            "
                                            placeholder="Email"
                                        />
                                        <ngx-validation-message
                                            label="{{ dialogValidationEmail }}"
                                            [showPattern]="
                                                emailFormControl.touched && emailFormControl?.hasError('pattern')
                                            "
                                            [showRequired]="
                                                emailFormControl.touched && emailFormControl?.hasError('required')
                                            "
                                        >
                                        </ngx-validation-message>
                                    </div>

                                    <!-- Context -->
                                    <div *ngIf="vm.contextList$ | async as contextList">
                                        <p class="labels">{{ dialogFieldContext }}</p>
                                        <nb-select fullWidth formControlName="context" placeholder="Select Context">
                                            <nb-option
                                                *ngFor="let contextItem of contextList"
                                                [value]="contextItem.code"
                                            >
                                                {{ contextItem.name }}
                                            </nb-option>
                                        </nb-select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-6">
                        <div class="container">
                            <div class="row">
                                <div class="col p-0">
                                    <!-- Subject -->
                                    <div style="padding-bottom: 5px">
                                        <label class="m-0">
                                            <p class="labels">{{ dialogFieldSubject }}</p>
                                            <textarea
                                                nbInput
                                                fullWidth
                                                cols="40"
                                                rows="5"
                                                formControlName="subject"
                                                placeholder="Subject"
                                                [status]="
                                                    subjectFormControl?.touched &&
                                                    (subjectFormControl?.hasError('minlength') ||
                                                        subjectFormControl?.hasError('maxlength'))
                                                        ? 'danger'
                                                        : ''
                                                "
                                                class="input-subject input-boxes"
                                            >
                                            </textarea>
                                        </label>
                                        <ngx-validation-message
                                            label="{{ dialogValidationSubject }}"
                                            [showMinLength]="
                                                subjectFormControl?.hasError('minlength') && subjectFormControl.touched
                                            "
                                            [showMaxLength]="
                                                subjectFormControl?.hasError('maxlength') && subjectFormControl.touched
                                            "
                                            [showRequired]="
                                                !submitted ||
                                                (subjectFormControl.touched && subjectFormControl?.hasError('required'))
                                            "
                                            [minLength]="3"
                                            [maxLength]="1000"
                                        >
                                        </ngx-validation-message>
                                    </div>

                                    <!-- Description -->
                                    <div>
                                        <label class="m-0">
                                            <p class="labels">{{ dialogFieldDescription }}</p>
                                            <textarea
                                                nbInput
                                                fullWidth
                                                cols="40"
                                                rows="5"
                                                formControlName="description"
                                                placeholder="Description of issue"
                                                [status]="
                                                    descriptionFormControl?.touched &&
                                                    (descriptionFormControl?.hasError('minlength') ||
                                                        descriptionFormControl?.hasError('maxlength'))
                                                        ? 'danger'
                                                        : ''
                                                "
                                                class="input-description input-boxes"
                                            >
                                            </textarea>
                                        </label>
                                        <ngx-validation-message
                                            label="{{ dialogValidationDescription }}"
                                            [showMinLength]="
                                                descriptionFormControl?.hasError('minlength') &&
                                                descriptionFormControl.touched
                                            "
                                            [showMaxLength]="
                                                descriptionFormControl?.hasError('maxlength') &&
                                                descriptionFormControl.touched
                                            "
                                            [showRequired]="
                                                !submitted ||
                                                (descriptionFormControl.touched &&
                                                    descriptionFormControl?.hasError('required'))
                                            "
                                            [minLength]="3"
                                            [maxLength]="10000"
                                        >
                                        </ngx-validation-message>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nb-card-body>

        <nb-card-footer>
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col">
                        <button
                            nbButton
                            (click)="closeMe()"
                            size="small"
                            status="basic"
                            class="m-2"
                            fullWidth
                            style="border-color: transparent; float: right"
                        >
                            <ngx-icon-box title="Cancel" icon="close-outline"></ngx-icon-box>
                        </button>
                    </div>
                    <div class="col">
                        <button
                            [disabled]="disableSubmitButton.asObservable() | async"
                            nbButton
                            status="success"
                            (click)="save()"
                            fullWidth
                            type="submit"
                            size="small"
                            class="m-2"
                        >
                            <ngx-icon-box title="Submit" icon="arrow-circle-right"></ngx-icon-box>
                        </button>
                    </div>
                </div>
            </div>
        </nb-card-footer>
    </nb-card>
</div>
