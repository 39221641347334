<nb-card>
    <nb-card-header>
        Filter Output Report by Asset Class
        <button ghost status="basic" nbButton (click)="cancel()">
            <nb-icon icon="close" status="basic"></nb-icon>
        </button>
    </nb-card-header>

    <nb-card-body>
        <div class="col-12">
            <div class="row">
                <span>
                    Select one or more asset classes to include in the Output Report. By default all data will be
                    included.
                </span>
            </div>

            <div class="row">
                <nb-select fullWidth multiple [formControl]="selectControl" [placeholder]="'Select...'">
                    <nb-select-label>
                        {{ isAllSelected ? "All" : getLabels(selectControl.value) }}
                    </nb-select-label>
                    <nb-option
                        *ngIf="options.length && options.length === (options$ | async).length"
                        [value]="0"
                        (click)="toggleSelectAll()"
                    >
                        Select All
                    </nb-option>
                    <nb-option *ngFor="let option of options$ | async" [value]="option.value">
                        {{ option.label }}
                    </nb-option>
                </nb-select>
            </div>
        </div>
    </nb-card-body>

    <nb-card-footer>
        <button nbButton fullWidth status="basic" (click)="cancel()">Cancel</button>

        <button nbButton fullWidth status="primary" class="ml-3" (click)="confirm()">Proceed</button>
    </nb-card-footer>
</nb-card>
