import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {GeospatialViewerApi} from './geospatial-viewer.api';
import {
    GeoJSONGeometry,
    GeospatialAssetPopupRequest,
    GeospatialAssetPopupResponse,
    GeospatialGroupPopupRequest,
    GeospatialGroupPopupResponse,
    GeospatialRequest,
    GeospatialResponse,
    SpatialCustomLayerResponse,
} from '../model/api';
import {Project, ProjectCreate, ProjectDto, ProjectPopupRequest, ProjectUpdate} from '../model/project';
import {APIResponse} from '@core/interfaces/system/system-common';
import {LayerType, MapLayerDetails} from '../model/layers';

@Injectable()
export class GeospatialViewerService {
    constructor(private api: GeospatialViewerApi) {}

    // Core map data
    public getMapGeoData(studyId: string, request: GeospatialRequest): Observable<GeospatialResponse> {
        return this.api.getMapGeoData(studyId, request);
    }

    public getAssetPopupInfo(
        studyId: string,
        req: GeospatialAssetPopupRequest,
    ): Observable<GeospatialAssetPopupResponse> {
        return this.api.getAssetPopupInfo(studyId, req);
    }

    public getAssetGroupPopupInfo(
        studyId: string,
        req: GeospatialGroupPopupRequest,
    ): Observable<GeospatialGroupPopupResponse> {
        return this.api.getAssetGroupPopupInfo(studyId, req);
    }

    // Additional layers
    getCustomMapLayer(code: string): Observable<SpatialCustomLayerResponse> {
        return this.api.getCustomMapLayer(code);
    }

    public getCustomMapLayerDetails(type: LayerType): Observable<MapLayerDetails[]> {
        return this.api.getCustomMapLayerDetails(type);
    }

    // Project APIs
    public getProject(id: number): Observable<Project> {
        return this.api.getProject(id);
    }
    public updateProject(req: ProjectUpdate): Observable<APIResponse<Project>> {
        return this.api.updateProject(req);
    }
    public createProject(req: ProjectCreate): Observable<APIResponse<Project>> {
        return this.api.createProject(req);
    }
    public deleteProject(id): Observable<Project> {
        return this.api.deleteProject(id);
    }
    public listProjects(filterQuery: string, pageSize?: number): Observable<Project[]> {
        return this.api.listProjects(filterQuery, pageSize);
    }
    public getProjectListDto(studyId: string, filterQuery: string, pageSize?: number): Observable<ProjectDto[]> {
        return this.api.getProjectListDto(studyId, filterQuery, pageSize);
    }
    public getProjectPopupInfo(req: ProjectPopupRequest): Observable<ProjectDto> {
        return this.api.getProjectPopupInfo(req);
    }
    public downloadProjectDataCsv(projectId: number, studyId: string): Observable<Record<string, string>> {
        return this.api.downloadProjectDataCsv(projectId, studyId);
    }

    // Project support
    public queryAssetsInPolygon(studyId: string, geoJsonStr: string): Observable<{count: number}> {
        return this.api.queryAssetsInPolygon(studyId, geoJsonStr);
    }
    public getSearchAssetList(req: string): Observable<any> {
        return this.api.getSearchAssetList(req);
    }
    public getSearchAssetInfo(req: string): Observable<GeoJSONGeometry> {
        return this.api.getSearchAssetInfo(req);
    }
}
