import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {ViewCell} from '@mominsamir/ngx-smart-table';
import {StudiesStore} from '../../../@store';
import {StudySelectorViewModel} from './study-selector.vm';
import {Unsubscribable} from '../../../@core/interfaces/unsubscribable';
import {takeUntil} from 'rxjs/operators';
import {ChartColors} from '../../styles/theme-colors';
import {NbToastrService} from '@nebular/theme';
import {UsersService} from '@core/interfaces/common/users';

@Component({
    selector: 'ngx-default-study-selector',
    template: `
        <ng-container *ngIf="studiesStore.defaultCollectionId$ | async as defaultCollectionId">
            <nb-icon
                [icon]="defaultWorkflowId === rowWorkflowId ? 'radio-button-on' : 'radio-button-off'"
                #icon
                [ngClass]="{
                    'default-study-icon': defaultWorkflowId === rowWorkflowId,
                    'not-default-study-icon': defaultWorkflowId !== rowWorkflowId,
                    default: defaultValue
                }"
                (click)="setDefaultCollection(rowData)"
            >
            </nb-icon>
        </ng-container>
    `,
    styleUrls: ['./study-selector.component.scss'],
})
export class DefaultStudySelectorComponent extends Unsubscribable implements ViewCell, OnInit {
    @ViewChild('icon') icon: ElementRef;
    @Input() rowData: any;
    @Input() value: string | number;

    defaultWorkflowId: number;
    rowWorkflowId: number;

    defaultValue;

    constructor(
        public studiesStore: StudiesStore,
        private usersService: UsersService,
        private studySelectorViewModel: StudySelectorViewModel,
        private toastrService: NbToastrService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.studiesStore.latestAsDefault$.pipe(takeUntil(this.unsubscribe$)).subscribe((value) => {
            this.defaultValue = value;
        });

        this.studiesStore.defaultCollectionId$.pipe(takeUntil(this.unsubscribe$)).subscribe((defaultId) => {
            this.defaultWorkflowId = parseInt(defaultId, 10);
        });

        this.studySelectorViewModel.onChange.valueChanges.subscribe((value) => {
            this.onChangeActionForCheckBox(value);
        });

        this.rowWorkflowId = this.rowData.id;
    }

    onChangeActionForCheckBox(value: any) {
        const iconTag = this.icon['el'].nativeElement.style;
        if (value) {
            iconTag.pointerEvents = 'none';
            iconTag.color = ChartColors.color6;
        } else {
            iconTag.pointerEvents = 'auto';
            iconTag.color = '';
        }
    }

    setDefaultCollection(rowData) {
        if (rowData.status !== 'SUCCESS' && rowData.status !== 'SUCCESS_WITH_WARNING') {
            this.toastrService.danger(
                'Study collection is incomplete and cannot be set to default collection. Please ' +
                    'select a study collection which has been successfully completed.',
                'Default study collection not updated!',
                {duration: 10000},
            );
        } else {
            this.studiesStore.setDefaultCollectionId(this.rowData.id.toString());
            this.usersService.updateDefaultStudyStrategy(false, this.rowData.id.toString()).subscribe();
        }
    }
}
