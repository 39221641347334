<nb-card class="dashboard-card">
    <nb-card-header>
        <div class="title-block">
            <nb-icon [style.background-color]="iconColor" [icon]="icon?.icon" [pack]="icon?.pack"></nb-icon>

            <div *ngIf="title">
                <p
                    #titleText
                    [ngxTruncateText]="title"
                    class="title-block-title"
                    [nbTooltip]="title"
                    [nbTooltipTrigger]="titleText.innerText !== title ? NbTrigger.HOVER : NbTrigger.NOOP"
                    [style.max-height.rem]="subtitle ? 1.5 : 3"
                >
                    {{ title }}
                </p>
                <p
                    #subtitleText
                    [ngxTruncateText]="subtitle"
                    class="title-block-subtitle"
                    [nbTooltip]="subtitle"
                    [nbTooltipTrigger]="subtitleText.innerText !== subtitle ? NbTrigger.HOVER : NbTrigger.NOOP"
                    *ngIf="subtitle"
                >
                    {{ subtitle }}
                </p>
            </div>
        </div>

        <nb-icon class="arrow-forward" icon="arrow-forward" *ngIf="displayAction"></nb-icon>
    </nb-card-header>
    <nb-card-body
        [nbSpinner]="!body || body?.type === DashboardBodyType.Empty"
        nbSpinnerSize="medium"
        nbSpinnerStatus="primary"
    >
        <nb-icon class="watermark" [icon]="icon?.icon" [pack]="icon?.pack"></nb-icon>

        <ng-container [ngSwitch]="body?.type">
            <ngx-dashboard-card-text *ngSwitchCase="DashboardBodyType.Text" [body]="body"></ngx-dashboard-card-text>

            <ngx-dashboard-card-number
                *ngSwitchCase="DashboardBodyType.Number"
                [body]="body"
            ></ngx-dashboard-card-number>

            <ngx-dashboard-card-table *ngSwitchCase="DashboardBodyType.Table" [body]="body"></ngx-dashboard-card-table>
        </ng-container>
    </nb-card-body>
</nb-card>
