export class SimpleSeriesScatter {
    code: string;
    legend: string;
    data: [any: any];
    unit: SimpleUnit;
}

export class SimpleTrendData {
    status: string;
    value: number;
    unit: SimpleUnit;
}

export class SimpleUnit {
    prefix: string;
    suffix: string;
    symbol: string;

    constructor() {
        this.prefix = '';
        this.suffix = '';
        this.symbol = '';
    }
}

export class PagingResponse<T> {
    items: T[];
    pageNumber: number;
    pageSize: number;
    startRecordNumber: number;
    totalCount: number;
    totalFilteredRecords: number;
}

// Series, data
export interface SimpleData<T> {
    code?: string;
    legend: string;
    data: T[];
    unit?: SimpleUnit;
}
export interface SimpleSeries<X, Y> {
    code?: string;
    legend: string;
    labels: X[];
    data: Y[];
    unit?: SimpleUnit;
}
export interface SimpleMultiSeries<X> {
    labels: X[];
    series: SimpleData<any>[];
}

// Series, thresholding
export interface ThresholdStatic {
    code?: string;
    legend: string;
    value: number;
    unit?: SimpleUnit;
}
export interface ThresholdStep {
    code?: string;
    legend: string;
    steps: {start: any; value: any}[];
    unit?: SimpleUnit;
}
export interface ThresholdLinear {
    code?: string;
    legend: string;
    values: {x: any; y: any}[];
    unit?: SimpleUnit;
}

export interface APIResponse<T> {
    [x: string]: any;

    response: T;
    status: number;
    message: string;
}
