import {ChangeDetectionStrategy, Component} from '@angular/core';
import {PagesStore, SidebarStore} from '../../@store';
import {filter, shareReplay, tap} from 'rxjs/operators';
import {NbToastrService} from '@nebular/theme';
import {Observable} from 'rxjs';
import {User} from '@core/interfaces/common/users';
import {DatePipe} from '@angular/common';
import {UsersStore} from '@store/common/users.store';
import {ColorsService} from '@core/utils';
import {DashboardBodyType, DashboardCardBody} from '@core/interfaces/common/pages';

@Component({
    selector: 'ngx-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [ColorsService],
})
export class DashboardComponent {
    private afterLogin: boolean = true;

    readonly currentUser$: Observable<User> = this.usersStore.currentUser$.pipe(
        filter((u) => u && !!u),
        tap((user: User) => {
            // First time accessing dashboard after login, show toastr with last access (unless very first login)
            if (this.afterLogin && user.lastLoginDetails && user.lastLoginDetails.date) {
                this.afterLogin = false;
                const dateString = this.datePipe.transform(user.lastLoginDetails.date, 'dd/MM/yyyy hh:mm a');
                const sourceString = user.lastLoginDetails.sameIp ? ' (same device)' : ' (different device)';
                this.toastrService.info(dateString + ' from address ' + sourceString, 'Last Login', {duration: 10000});
            }
        }),
    );
    readonly userNotifications$ = this.usersStore.getUserNotifications$;

    readonly pageGroups$ = this.pagesStore.pageGroups$;

    readonly colors = this.colorsService.colorsMap$.pipe(shareReplay(1));

    constructor(
        private sidebarStore: SidebarStore,
        public pagesStore: PagesStore,
        private usersStore: UsersStore,
        private toastrService: NbToastrService,
        private datePipe: DatePipe,
        private colorsService: ColorsService,
    ) {
        this.checkFromLoginPage();
        //change sidebar expanded state to false
        sidebarStore.toggleSidebarState(false);
    }

    private checkFromLoginPage() {
        const fromLoginPage = JSON.parse(localStorage.getItem('fromLogin'));
        if (fromLoginPage) {
            this.usersStore.fromLogin.next(fromLoginPage);
            localStorage.removeItem('fromLogin');
        } else {
            this.usersStore.fromLogin.next(false);
        }
    }

    public getDashboardBody(id: string, body: DashboardCardBody): DashboardCardBody {
        if (body && !!body) {
            return body;
        }
        return this.getEmptyBody(id);
    }

    public getEmptyBody(id: string): DashboardCardBody {
        return {
            id: id,
            type: DashboardBodyType.Empty,
        };
    }
}
