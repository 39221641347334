import {Injectable} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {AnalyzerRequest, AnalyzerResponse, AnalyzerService} from '../../@core/interfaces/engin/analyzer';
import {switchMap, tap} from 'rxjs/operators';
import {StudiesStore} from '../common/studies.store';
import {AnalyzerPopoutStore} from './analyzer-popout.store';
import {PagesStore} from '../config/pages.store';
import {DisplaySettings} from '@core/interfaces/common/pages';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {EnginErrorHandlerService} from '@core/error-handling/engin-error-handler.service';
import {of} from 'rxjs/internal/observable/of';
import {APIResponse} from '@core/interfaces/system/system-common';
import {APIException} from '@core/error-handling/exception.dto';
import {Workflow} from '@core/interfaces/engin/workflow';
import {AssetsService} from '@core/interfaces/engin/assets';

@Injectable()
export class AnalyzerStore {
    public resultsLoading = new BehaviorSubject<boolean>(false);
    public resultsLoading$ = this.resultsLoading.asObservable();

    constructor(
        private analyzerService: AnalyzerService,
        private assetsService: AssetsService,
        private studiesStore: StudiesStore,
        private popoutStore: AnalyzerPopoutStore,
        private pagesStore: PagesStore,
        private errorService: EnginErrorHandlerService,
    ) {}

    readonly combineAnalyzerRequestInformation$ = combineLatest<
        Observable<AnalyzerRequest>,
        Observable<Workflow>,
        Observable<DisplaySettings>
    >([this.popoutStore.currentPopout$, this.studiesStore.activeCollection$, this.pagesStore.currentDisplay$]);

    // EOL metrics, Demographics
    readonly abstractChartData$: Observable<AnalyzerResponse[]> = this.combineAnalyzerRequestInformation$.pipe(
        tap(() => this.resultsLoading.next(true)),
        // Send request to endpoint
        switchMap(([popoutValue, activeCollection, displaySettings]) => {
            const req = this.analyzerService.prepareAnalyzerRequest(popoutValue, displaySettings, activeCollection);
            const activeStudyId = this.analyzerService.getActiveStudyId(popoutValue, activeCollection);
            return this.analyzerService.getAnalyzerDataAbstract(req, activeStudyId);
        }),
        switchMap((res: APIResponse<AnalyzerResponse[] | APIException>) => {
            if (res.status === 200) {
                const resp = res.response as AnalyzerResponse[];
                return of(resp);
            }
            // Else handle exception
            const exception = res.response as APIException;
            this.errorService.handleCustomException(exception);
            return of([]);
        }),
        tap(() => {
            this.resultsLoading.next(false);
        }),
    );

    // Study assets
    readonly studyAssetsDataSource$: Observable<DataSource> = this.combineAnalyzerRequestInformation$.pipe(
        tap(() => this.resultsLoading.next(true)),
        switchMap(([popoutValue, activeCollection, displaySettings]) => {
            // This call joins active study collection to popout settings
            this.analyzerService.prepareAnalyzerRequest(popoutValue, displaySettings, activeCollection);
            const activeStudyId = this.analyzerService.getActiveStudyId(popoutValue, activeCollection);
            return this.assetsService.getStudyAssetsDataSource(activeStudyId);
        }),
        tap(() => this.resultsLoading.next(false)),
    );
}
