import {Component, Input} from '@angular/core';
import {ReportCustomization} from '../../../pages/reporting-tool/model/report-config';
import {ReportRequestComposer} from '../../../pages/reporting-tool/report-services/report-request-composer.service';

export interface SimpleCodeNameSelection {
    code: string;
    name: string;
    selected: boolean;
}

@Component({
    selector: 'ngx-report-type-indicator',
    templateUrl: './report-type-indicator.component.html',
    styleUrls: ['./report-type-indicator.component.scss'],
})
export class ReportTypeIndicatorComponent {
    @Input() selectedReport: ReportCustomization;
    @Input() dataList: SimpleCodeNameSelection[];

    constructor(public reportRequestComposer: ReportRequestComposer) {}
}
