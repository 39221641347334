import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {environment} from '../../../../../environments/environment';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {ServerDataSource} from '@mominsamir/ngx-smart-table';

@Injectable()
export class HttpService {
    constructor(private http: HttpClient) {}

    get apiUrl(): string {
        return environment.NODE_API_URL;
    }

    get(endpoint: string, options?): Observable<any> {
        return this.http.get(`${this.apiUrl}/${endpoint}`, options);
    }

    post(endpoint: string, data?, options?): Observable<any> {
        return this.http.post(`${this.apiUrl}/${endpoint}`, data, options);
    }

    put(endpoint: string, data?, options?): Observable<any> {
        return this.http.put(`${this.apiUrl}/${endpoint}`, data, options);
    }

    delete(endpoint: string, options?): Observable<any> {
        return this.http.delete(`${this.apiUrl}/${endpoint}`, options);
    }

    uploadS3(endpoint: string, data?: any, options?: any): Observable<any> {
        return this.http.put(`${endpoint}`, data, options);
    }

    getServerDataSource(endpoint: string): Observable<DataSource> {
        return of(
            new ServerDataSource(this.http, {
                endPoint: `${this.apiUrl}/${endpoint}`,
                totalKey: 'totalCount',
                dataKey: 'items',
                pagerPageKey: 'pageNumber',
                pagerLimitKey: 'pageSize',
                filterFieldKey: 'filterBy#field#',
                sortFieldKey: 'sortBy',
                sortDirKey: 'orderBy',
            }),
        );
    }
}
