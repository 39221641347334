import {Component, OnDestroy, OnInit, Optional} from '@angular/core';
import {Report} from '@core/interfaces/common/pages';
import {NbDialogRef} from '@nebular/theme';
import {FormControl} from '@angular/forms';
import {ReplaySubject, Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, map} from 'rxjs/operators';

export interface ReportData {
    module?: Report;
    sensitivityItem?: string;
    filters?: any;
    options?: string[];
}

@Component({
    selector: 'ngx-report-filter-dialog',
    templateUrl: './report-filter-dialog.component.html',
    styleUrls: ['./report-filter-dialog.component.scss'],
})
export class ReportFilterDialogComponent implements OnInit, OnDestroy {
    private reportData: ReportData;

    // TODO: Implementation is a basic multi-select dropdown. Can we use any existing component?
    options: {value: string; label: string}[];
    options$: ReplaySubject<{value: string; label: string}[]> = new ReplaySubject(1);

    selectControl: FormControl = new FormControl([]);
    isAllSelected: boolean = false;
    _onDestroy = new Subject();

    constructor(@Optional() private dialogRef: NbDialogRef<ReportData>) {}

    ngOnInit() {
        this.reportData = this.dialogRef.componentRef.instance;

        this.selectControl.valueChanges.pipe(
            distinctUntilChanged(),
            map((value: any[]) => {
                if (value && value.length === this.options.length && !value.includes(0)) {
                    let _value = [...value, 0];
                    this.isAllSelected = true;
                    this.selectControl.setValue(_value, {emitEvent: false});
                    return _value;
                } else if (value && value.length !== this.options.length + 1) {
                    let _value = value.filter((item) => item !== 0);
                    this.isAllSelected = false;
                    this.selectControl.setValue(_value, {emitEvent: false});
                    return _value;
                }
            }),
            debounceTime(100),
        );
        this.options = this.reportData.options.map((o) => {
            return {
                value: o,
                label: o,
            };
        });

        this.selectControl.valueChanges.subscribe((item) => {
            if (item.length === this.options.length) {
                const index = this.selectControl.value.indexOf(0);
                if (index > -1) this.selectControl.value.splice(index, 1);
                this.isAllSelected = false;
                this.selectControl.setValue(this.selectControl.value, {emitEvent: false});
            }
        });

        this.options$.next(this.options);
    }

    ngOnDestroy() {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    toggleSelectAll() {
        if (this.selectControl.value.length >= this.options.length) {
            this.selectControl.reset([]);
            this.isAllSelected = false;
        } else {
            let _options = this.options.reduce((acc, curr) => {
                return [...acc, curr.value];
            }, []);
            this.selectControl.setValue([..._options, 0]);
            this.isAllSelected = true;
        }
    }

    getLabels(values: string[]) {
        let _labels = values?.reduce((acc, curr) => {
            if (acc !== '') acc += ', ';
            return (acc += this.options.find((option) => option.value === curr)?.label);
        }, '');
        return _labels || '';
    }

    confirm = () => {
        const retData = {
            module: this.reportData.module,
            sensitivityItem: this.reportData.sensitivityItem,
            filters: this.isAllSelected ? [] : [...this.selectControl.value],
        };
        this.dialogRef.close({action: 'confirm', data: retData});
    };

    cancel = () => {
        this.dialogRef.close({action: 'cancel'});
    };
}
