import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpService} from './http.service';
import {DashboardCardBody, PageGroup} from '@core/interfaces/common/pages';

@Injectable()
export class PagesApi {
    private readonly apiController: string = 'pages';

    constructor(private api: HttpService) {}

    getPages(): Observable<PageGroup[]> {
        return this.api.get(`${this.apiController}/`);
    }

    getDashboardCardBodyData(studyId: string = '-1'): Observable<DashboardCardBody[]> {
        return this.api.get(`${this.apiController}/dashboard/${studyId}`);
    }
}
