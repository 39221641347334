import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {SCTableColumnType} from '../../ngx-sc-table-package/pojo/ngx-sc-pojo';
import {NgxCheckMarkFieldComponent} from '../ngx-check-mark-field/ngx-check-mark-field.component';

export class ComparedResult {
    matchedTableList;
    unmatchedTableList;
    isFullyMatched;

    constructor(matchedTableList, unmatchedTableList, isFullyMatched) {
        this.matchedTableList = matchedTableList;
        this.unmatchedTableList = unmatchedTableList;
        this.isFullyMatched = isFullyMatched;
    }
}

@Component({
    selector: 'ngx-sc-compare-list',
    templateUrl: './ngx-sc-compare-list.component.html',
    styleUrls: ['./ngx-sc-compare-list.component.scss'],
})
export class NgxScCompareListComponent implements OnChanges {
    @Input() list1: any[]; // must full match
    @Input() list2: any[];
    @Input() sortList: boolean = true;
    @Output() compareResult = new EventEmitter();

    dataset: any[];
    tableSettings = {
        columns: [
            {
                title: '',
                dataKey: 'list1CheckMark',
                type: SCTableColumnType.COMPONENT,
                renderComponent: NgxCheckMarkFieldComponent,
            },
            {
                title: 'Table Name',
                dataKey: 'list1Item',
                type: SCTableColumnType.DATA,
            },
            {
                title: 'Sheet Name in Upload File',
                dataKey: 'list2Item',
                type: SCTableColumnType.DATA,
            },
        ],
    };

    ngOnChanges(changes: SimpleChanges): void {
        const displayList = [];
        const matchedList = [];
        const unmatchedList1 = [];
        const unmatchedList2 = [];

        if (this.list1 && this.list2) {
            if (this.sortList) {
                this.list1.sort();
                this.list2.sort();
            }

            this.list1.forEach((list1Item) => {
                if (this.list2.includes(list1Item)) {
                    matchedList.push({list1CheckMark: true, list1Item: list1Item, list2Item: list1Item});
                    displayList.push({list1CheckMark: true, list1Item: list1Item, list2Item: list1Item});
                } else {
                    unmatchedList1.push(list1Item);
                }
            });
            this.list2.forEach((list2Item) => {
                if (!this.list1.includes(list2Item)) {
                    unmatchedList2.push(list2Item);
                }
            });
            const maxLength =
                unmatchedList1.length > unmatchedList2.length ? unmatchedList1.length : unmatchedList2.length;
            for (let i = 0; i < maxLength; i++) {
                let list1Item = '';
                let list2Item = '';
                if (i < unmatchedList1.length) {
                    list1Item = unmatchedList1[i];
                }
                if (i < unmatchedList2.length) {
                    list2Item = unmatchedList2[i];
                }
                // matchedList.push({list1CheckMark: false, list1Item: list1Item, list2Item: list2Item});
                displayList.push({list1CheckMark: false, list1Item: list1Item, list2Item: list2Item});
            }
            this.dataset = displayList;
        }
        const compareResult = new ComparedResult(
            matchedList,
            unmatchedList1,
            this.list1 && this.list2 && unmatchedList1.length === 0,
        );
        this.compareResult.emit(compareResult);
    }
}
