import {Component, Input} from '@angular/core';
import {SortEvent} from '../ngx-sc-table-directive/ngx-sc-sortable.directive';
import {NgxScTableFunction} from '../ngx-sc-table-function';
import {SCTableColumnType, SCTableSettings} from '../pojo/ngx-sc-pojo';

@Component({
    selector: 'ngx-sc-table-simple',
    templateUrl: './ngx-sc-table-simple.component.html',
    styleUrls: ['./ngx-sc-table-simple.component.scss'],
})
export class NgxScTableSimpleComponent {
    @Input() dataset: any[];
    @Input() tableSettings: SCTableSettings;

    COLUMN_TYPE = SCTableColumnType;

    renderValue(valuePrepareFunction, value) {
        if (valuePrepareFunction != null) {
            return valuePrepareFunction(value);
        } else {
            return value;
        }
    }

    onSort({column, direction}: SortEvent) {
        this.dataset = NgxScTableFunction.sort(this.dataset, column, direction);
    }
}
