import {Component, Input} from '@angular/core';
import {FormGroup} from '@angular/forms';

@Component({
    selector: 'ngx-input-error',
    template: `<div
        *ngIf="
            formGroup.get(fieldName).invalid &&
            formGroup.get(fieldName).errors &&
            (formGroup.get(fieldName).dirty || formGroup.get(fieldName).touched)
        "
    >
        <small class="text-danger" *ngIf="formGroup.get(fieldName).hasError('required')">
            This {{ fieldName }} is required.
        </small>

        <small class="text-danger" *ngIf="formGroup.get(fieldName).hasError('minlength')">
            The minimum length for this {{ fieldName }} is
            {{ formGroup.get(fieldName).errors.minlength.requiredLength }}
            characters.
        </small>

        <small class="text-danger" *ngIf="formGroup.get(fieldName).hasError('maxlength')">
            The maximum length for this {{ fieldName }} is
            {{ formGroup.get(fieldName).errors.maxlength.requiredLength }}
            characters.
        </small>

        <small class="text-danger" *ngIf="formGroup.get(fieldName).hasError('mustMatch')">
            Confirm password does not match.
        </small>

        <small class="text-danger" *ngIf="formGroup.get(fieldName).hasError('usernameAlreadyExists')">
            User name already taken.
        </small>

        <small class="text-danger" *ngIf="formGroup.get(fieldName).hasError('email')">
            Entered email is not valid.
        </small>

        <small class="text-danger" *ngIf="formGroup.get(fieldName).hasError('pattern')">
            Input does not match with provided pattern
        </small>

        <small class="text-danger" *ngIf="formGroup.get(fieldName).hasError('emailAlreadyExists')">
            Email already taken.
        </small>
    </div>`,
})
export class InputErrorComponent {
    @Input() formGroup: FormGroup;
    @Input() fieldName: string;
}
