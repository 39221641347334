<ng-container *ngIf="dataset && dataset.length > 0">
    <div>
        <ngx-sc-table-simple [dataset]="dataset" [tableSettings]="tableSettings">
            <!--               [perPage]="dataset.length"
                            [showItemOnPage]="false" [showTopPager]="false" [showFilter]="false" [showFullTextSearch]="false" [showBottomPager]="false"-->
        </ngx-sc-table-simple>
    </div>
</ng-container>
<!--<table>-->
<!--    <tr *ngIf="dataset && dataset.length>0">-->
<!--        <td></td>-->
<!--        <td>Table Name</td>-->
<!--        <td>Sheet Name in Upload File</td>-->
<!--    </tr>-->
<!--    <tr *ngFor="let dataRow of dataset; let i = index">-->
<!--        <td>-->
<!--            <nb-icon *ngIf="dataRow['list1CheckMark']; else unmatchedIcon"-->
<!--                     icon="checkmark-outline"-->
<!--                     status="success">-->
<!--            </nb-icon>-->
<!--            <ng-template #unmatchedIcon>-->
<!--                <nb-icon icon="close-outline"></nb-icon>-->
<!--            </ng-template>-->

<!--        </td>-->
<!--        <td>-->
<!--            {{dataRow['list1Item']}}-->
<!--        </td>-->
<!--        <td>-->
<!--            {{dataRow['list2Item']}}-->
<!--        </td>-->
<!--    </tr>-->
<!--</table>-->
