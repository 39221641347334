<ng-container *ngIf="pieChart$ | async as pieChart">
    <div *ngIf="!ignoreFormControl" [formGroup]="graphsFormGroup">
        <nb-card class="chart-card">
            <nb-card-body class="chart-card-body">
                <ngx-chart-settings
                    *ngIf="chartInstance"
                    [chartInstance]="chartInstance"
                    [unitOptions]="unitOptions"
                    [labelOptions]="labelOptions"
                    [graphId]="graphId"
                    [formControlName]="pieChart?.metadata.graphId"
                    [chartOptions]="pieChart?.options"
                    [chartType]="EC_TYPE_LOCAL"
                >
                </ngx-chart-settings>
                <div class="chart-container">
                    <ngx-chart
                        [options]="pieChart?.options"
                        (onChartInstanceInit)="chartInstance = $event"
                        height="100%"
                    ></ngx-chart>
                </div>
            </nb-card-body>

            <nb-card-footer class="chart-card-footer">
                <!-- Chart items -->
                <ng-container *ngFor="let item of pieChart?.metadata.values">
                    <div class="chart-footer-item">
                        <ngx-icon-box
                            class="footer-label-title"
                            [ngClass]="getChartFooterIconBackground(item.name)"
                            [title]="item.name"
                            [icon]="getChartFooterIconName(item.name)"
                        >
                        </ngx-icon-box>
                        <label class="footer-label-value" *ngIf="legendValuesRounded">
                            {{ item.formattedValue }}
                        </label>
                        <label class="footer-label-value" *ngIf="!legendValuesRounded">
                            {{ formatRawValue(item) }}
                        </label>
                    </div>
                </ng-container>

                <!-- Total -->
                <div class="chart-footer-item">
                    <ngx-icon-box
                        class="footer-label-title"
                        [ngClass]="getChartFooterIconBackground('Total')"
                        [icon]="getChartFooterIconName('Total')"
                        title="Total"
                    >
                    </ngx-icon-box>
                    <label class="footer-label-value">
                        {{ getFormattedTotal(pieChart?.metadata.values, legendValuesRounded) }}
                    </label>
                </div>
            </nb-card-footer>
        </nb-card>
    </div>

    <div *ngIf="ignoreFormControl">
        <nb-card class="chart-card">
            <nb-card-body class="chart-card-body">
                <div class="chart-container">
                    <ngx-chart [options]="pieChart?.options" height="100%"></ngx-chart>
                </div>
            </nb-card-body>

            <nb-card-footer class="chart-card-footer">
                <!-- Chart items -->
                <ng-container *ngFor="let item of pieChart?.metadata.values">
                    <div class="chart-footer-item">
                        <ngx-icon-box
                            class="footer-label-title"
                            [ngClass]="getChartFooterIconBackground(item.name)"
                            [title]="item.name"
                            [icon]="getChartFooterIconName(item.name)"
                        >
                        </ngx-icon-box>
                        <label class="footer-label-value" *ngIf="legendValuesRounded">
                            {{ item.formattedValue }}
                        </label>
                        <label class="footer-label-value" *ngIf="!legendValuesRounded">
                            {{ formatRawValue(item) }}
                        </label>
                    </div>
                </ng-container>

                <!-- Total -->
                <div class="chart-footer-item">
                    <ngx-icon-box
                        class="footer-label-title"
                        [ngClass]="getChartFooterIconBackground('Total')"
                        [icon]="getChartFooterIconName('Total')"
                        title="Total"
                    >
                    </ngx-icon-box>
                    <label class="footer-label-value">
                        {{ getFormattedTotal(pieChart?.metadata.values, legendValuesRounded) }}
                    </label>
                </div>
            </nb-card-footer>
        </nb-card>
    </div>
</ng-container>
