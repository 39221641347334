import {Component, Inject, Input, OnInit, QueryList, ViewChildren} from '@angular/core';
import {NgxScTableStore} from '../ngx-sc-table.store';
import {BehaviorSubject, Observable} from 'rxjs';
import {NgxScSortableDirective, SortEvent} from '../ngx-sc-table-directive/ngx-sc-sortable.directive';
import {FilterEvent} from '../ngx-sc-table-directive/ngx-sc-filterable.directive';
import {SCTableColumnType, SCTableSettings} from '../pojo/ngx-sc-pojo';
import {NB_WINDOW, NbMenuService, NbToastrService} from '@nebular/theme';
import {filter, map} from 'rxjs/operators';
import {Router} from '@angular/router';
import {DownloadLink} from '../../../../@core/interfaces/common/legacyUploads';
import {SurvivalAnalysisService} from '../../../../@core/interfaces/engin/survival-analysis';

@Component({
    selector: 'ngx-sc-table',
    templateUrl: './ngx-sc-table.component.html',
    styleUrls: ['./ngx-sc-table.component.scss'],
})
export class NgxScTableComponent implements OnInit {
    @Input() dataset: any[];
    @Input() patchData: BehaviorSubject<any>;
    @Input() patchFunction: any;
    @Input() tableSettings: SCTableSettings;
    @Input() typeOfTable: any;

    // =========
    @Input() isCollapsedAllDefault = true;
    isToggledAllCurrent;
    isCollapsedAllCurrent;

    // ========
    @Input() perPage = 10;
    @Input() showFullTextSearch = true;
    @Input() expectStyle = false;
    @Input() showItemOnPage = true;
    @Input() showTopPager = true;
    @Input() dropDownBox = [];
    @Input() title: string;
    @Input() description: string;
    @Input() showBottomPager = true;
    @Input() showFilter = true;
    @Input() iconsForAction = [];
    @Input() namesForAction = [];

    // ========
    dataset$: Observable<any[]>;
    dataItemCount$: Observable<number>;

    // ========
    COLUMN_TYPE = SCTableColumnType;

    constructor(
        public service: NgxScTableStore,
        private router: Router,
        private nbMenuService: NbMenuService,
        private toastrService: NbToastrService,
        private survivalAnalysisService: SurvivalAnalysisService,
        @Inject(NB_WINDOW) private window,
    ) {
        this.dataset$ = service.dataset$;
        this.dataItemCount$ = service.total$;
    }

    getValue(value, defaultValue) {
        if (value === undefined) {
            return defaultValue;
        } else {
            return value;
        }
    }

    onOptionsSelected(dataset, option) {
        // const selectedOption = option.split(' ')[0].toLowerCase();
        // this.failureCurveApi.selectedUpdate(dataset, selectedOption);
    }

    // ========

    ngOnInit() {
        this.perPage = this.getValue(this.tableSettings.perPage, this.perPage);
        this.showFullTextSearch = this.getValue(this.tableSettings.showFullTextSearch, this.showFullTextSearch);
        this.showItemOnPage = this.getValue(this.tableSettings.showItemOnPage, this.showItemOnPage);
        this.showTopPager = this.getValue(this.tableSettings.showTopPager, this.showTopPager);
        this.showBottomPager = this.getValue(this.tableSettings.showBottomPager, this.showBottomPager);

        this.service.perPage = this.perPage;
        // Set/save original dataset incase it is needed, as well as working dataset for rendering table
        this.service._dataset_original.next(this.dataset);
        this.service._workingDataset.next(this.dataset);

        this.dataset$.subscribe((dataset) => {
            this.isCollapsedAllCurrent = this.isCollapsedAllDefault;
            this.isToggledAllCurrent = true;
            this.tableSettings.columns
                .filter((columnSetting) => {
                    if (columnSetting.dropSort) {
                        // this.drop.push(columnSetting);
                        this.dropDataKey = columnSetting.dataKey;
                    }
                    if (columnSetting.link) {
                        this.linkDataKey = columnSetting.link;
                        this.triggerBeforeNavigation = columnSetting.triggerBeforeNavigation;
                        this.disableDetailsFunction = columnSetting.disableDetailsFunction;
                    }
                    return (
                        columnSetting.type === SCTableColumnType.EXPANDABLE ||
                        columnSetting.type === SCTableColumnType.EXPANDABLE_CONTAINER ||
                        columnSetting.type === SCTableColumnType.EXPANDABLE_CONTAINER_WITH_CHART
                    );
                })
                .forEach((columnSetting) => {
                    dataset.forEach((dataItem, index) => {
                        if (dataItem[columnSetting.dataKey]) {
                            dataItem[columnSetting.dataKey].id = index;
                        } else {
                            dataItem[columnSetting.dataKey] = {isCollapsed: true};
                        }

                        //// for sorting in status in analysis history page

                        // dropArray.push(dataItem[this.dropDataKey]);
                        // const uniqueAges = dropArray.filter((x, i, a) => a.indexOf(x) === i);
                        // this.items.push({title: dataItem[this.dropDataKey]});

                        // If patching the data, we want to retain the currently expanded rows
                        if (dataItem[columnSetting.dataKey] != null) {
                            dataItem[columnSetting.dataKey].isCollapsed =
                                dataItem[columnSetting.dataKey].isCollapsed !== undefined
                                    ? dataItem[columnSetting.dataKey].isCollapsed
                                    : this.isCollapsedAllCurrent;
                            dataItem[columnSetting.dataKey].isToggled = this.isToggledAllCurrent;
                        } else {
                            dataItem[columnSetting.dataKey] = {isCollapsed: true};
                            dataItem[columnSetting.dataKey] = {isToggled: true};
                        }
                    });
                });
        });

        this.nbMenuService
            .onItemClick()
            .pipe(
                filter(({tag}) => tag === 'my-context-menu'),
                map(({item: {title}}) => {
                    return title;
                }),
            )
            .subscribe((title) => this.window.alert(`${title} was clicked!`));

        // Monitor (optional) input observable to patch values to original dataset
        this.service.patchFunction = this.patchFunction;
        if (this.patchData) {
            this.patchData.asObservable().subscribe((dataset) => {
                this.service.propagateDataPatch(dataset);
            });
        }
    }

    items = [''];
    drop = [];
    dropDataKey;
    linkDataKey;
    triggerBeforeNavigation;
    disableDetailsFunction;

    // Use a custom-defined function to identify if navigation is disabled for this table row, if applicable
    disableDetailsNav(val) {
        if (this.disableDetailsFunction) {
            return this.disableDetailsFunction(val);
        } else {
            return false;
        }
    }

    // ========
    renderValue(valuePrepareFunction, value) {
        if (valuePrepareFunction != null) {
            return valuePrepareFunction(value);
        } else {
            return value;
        }
    }

    // ========
    @ViewChildren(NgxScSortableDirective) headers: QueryList<NgxScSortableDirective>;

    onSort({column, direction}: SortEvent) {
        // check if there is any column need dropdown sorting
        if (column !== this.dropDataKey) {
            this.headers.forEach((header) => {
                if (header.sortable !== column) {
                    header.direction = '';
                }
            });

            this.service.sortColumn = column;
            this.service.sortDirection = direction;
        } else {
            return null;
        }
    }

    // ========
    onFilter({column, term}: FilterEvent) {
        if (term === '') {
            this.service.searchTermMap.delete(column);
        } else {
            this.service.searchTermMap.set(column, term);
        }
        this.service.triggerProcess();
    }

    // ========
    toggleDetailAll(dataset, dataKey) {
        this.isCollapsedAllCurrent = !this.isCollapsedAllCurrent;
        dataset.forEach((dataItem) => {
            dataItem[dataKey].isCollapsed = this.isCollapsedAllCurrent;
        });
        this.service.triggerProcess();
    }

    toggleDetail(dataRowDetailItem, value?, icon?) {
        const detailClicked = 'arrow-forward-outline';
        const fileOptionClicked = 'more-vertical-outline';
        if (value.linkDataKey && icon === detailClicked) {
            dataRowDetailItem.isCollapsed = false;
            // Trigger pre-navigation function
            value.triggerBeforeNavigation(dataRowDetailItem);
            // Navigate to link
            this.router.navigate([`${value.linkDataKey}`]);
            dataRowDetailItem.isCollapsed = !dataRowDetailItem.isCollapsed;
        } else if (icon === fileOptionClicked) {
            this.toggleFileExtract(dataRowDetailItem);
        } else {
            dataRowDetailItem.isCollapsed = !dataRowDetailItem.isCollapsed;
        }
    }

    toggleFileExtract(dataRowFileData) {
        dataRowFileData.isToggled = !dataRowFileData.isToggled;
    }

    toggleFileBoxClose(dataRowFileData) {
        dataRowFileData.isToggled = true;
    }

    fileDownload(data, type) {
        const fileKey = data.fileKey;
        this.survivalAnalysisService.downloadFile(fileKey, 'file', type).subscribe((result: DownloadLink) => {
            if (!!result) {
                this.toastrService.danger('', 'File not found.');
            }
            window.open(result.link, '_blank');
        });
    }

    fileEmail(data, type) {
        // this.failureCurveApi.downloadOrEmailFile(data, type);
    }

    // ========
    pageSelected(selectedPageIndex) {
        if (selectedPageIndex <= this.service.pagesCount) {
            this.service.pageIndex = selectedPageIndex;
        }
    }
}
