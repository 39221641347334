export const environment = {
    APP_VERSION_MAJOR: 2,
    APP_VERSION_MINOR: 5,
    APP_VERSION_PATCH: 0,

    production: false,
    staging: false,

    NODE_BASE_URL: 'https://api-node.demo.engincloud.com',
    NODE_API_URL: 'https://api-node.demo.engincloud.com/api',
    OAUTH_CLIENT_ID: 'HVcbui9ZSfTGyQ61VfRfBSAHSVn5hr8pwDM6JRGG',
    OAUTH_CLIENT_SECRET: 'qmXLu1aCQseMJ2jihok6Pc4lI4NIAHMuBmLrW7lk',
    PYTHON_API_URL: 'https://python.demo.engincloud.com/engin/v2/python',
    LOGI_URI: '',
    LOGI_SECURE_KEY: '',
};
