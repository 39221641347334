import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {APIResponse} from '../../../interfaces/system/system-common';
import {
    PreSingedURL,
    DataFile,
    UploadFileCreate,
    DataFileService,
    FILE_FORMAT,
} from '../../../interfaces/common/data-file';
import {DataFileApi} from '../api/data-file-api.service';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {DatasetType} from '../../../interfaces/common/dataset';

@Injectable()
export class DataFileServiceImpl extends DataFileService {
    constructor(private api: DataFileApi) {
        super();
    }

    findOne(id: string): Observable<APIResponse<DataFile>> {
        return this.api.findOne(id);
    }

    create(data: UploadFileCreate): Observable<APIResponse<DataFile>> {
        return this.api.create(data);
    }

    process(uploadedId: string): Observable<APIResponse<DataFile>> {
        return this.api.processFile(uploadedId);
    }

    uploadToPresignedUrl(url: string, fileData: File): Observable<any> {
        return this.api.upload(`${url}`, fileData);
    }

    processFile(uploadId: string): Observable<APIResponse<DataFile>> {
        return this.api.processFile(uploadId);
    }

    getPresignedUrl(fileName: string, fileFormat: FILE_FORMAT): Observable<APIResponse<PreSingedURL>> {
        return this.api.getPresignedUrl(fileName, fileFormat);
    }

    verifyUpload(fileName: string): Observable<APIResponse<any>> {
        return this.api.verifyUpload(fileName);
    }

    listFiles(): Observable<DataSource> {
        return this.api.listFiles();
    }

    listFilesBySchema(schemaType: DatasetType, status: string): Observable<DataSource> {
        return this.api.listFilesBySchema(schemaType, status);
    }

    downloadFileByIds(id: number[]): Observable<APIResponse<string[]>> {
        return this.api.downloadFileByIds(id);
    }
}
