import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpService} from '../../common/api/http.service';
import {
    OptimizerAnalysisRequest,
    OptimizerChartRequest,
    OptimizerChartResponse,
    OptimizerKpiResponse,
} from '../../../interfaces/engin/optimizer';

@Injectable()
export class OptimizerApi {
    private readonly prefix = 'optimizer';

    constructor(private api: HttpService) {}

    checkOptimizerResultsReady(request: OptimizerAnalysisRequest): Observable<boolean> {
        return this.api.post(`${this.prefix}/analysis/execute`, request);
    }

    getChartDataSummarySpending(request: OptimizerChartRequest): Observable<OptimizerChartResponse> {
        return this.api.post(`${this.prefix}/charts/investment-plan/spending`, request);
    }

    getChartDataSummaryOutcomes(request: OptimizerChartRequest): Observable<OptimizerKpiResponse> {
        return this.api.post(`${this.prefix}/charts/investment-plan/outcomes`, request);
    }

    getChartDataProactiveForecast(request: OptimizerChartRequest): Observable<OptimizerChartResponse> {
        return this.api.post(`${this.prefix}/charts/investment-forecast/proactive`, request);
    }

    getChartDataReactiveForecast(request: OptimizerChartRequest): Observable<OptimizerChartResponse> {
        return this.api.post(`${this.prefix}/charts/investment-forecast/reactive`, request);
    }

    getChartDataFailureForecast(request: OptimizerChartRequest): Observable<OptimizerChartResponse> {
        return this.api.post(`${this.prefix}/charts/failure-forecast/failure`, request);
    }

    getChartDataOutageForecast(request: OptimizerChartRequest): Observable<OptimizerChartResponse> {
        return this.api.post(`${this.prefix}/charts/failure-forecast/outage`, request);
    }

    getChartDataRiskForecastByImpact(request: OptimizerChartRequest): Observable<OptimizerChartResponse> {
        return this.api.post(`${this.prefix}/charts/risk-forecast/impact`, request);
    }

    getChartDataRiskForecastByCategory(request: OptimizerChartRequest): Observable<OptimizerChartResponse> {
        return this.api.post(`${this.prefix}/charts/risk-forecast/category`, request);
    }
}
