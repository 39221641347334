import {Observable} from 'rxjs';

export enum UploadFailureDataType {
    file = 'file',
    record = 'record',
}

export interface DataUpload {
    id: string;
    uploadDate: string;
    status: {
        dataLoaded: boolean;
        status: string;
        info: string;
    };
    type: {
        uploadType: string;
        dataType: string;
    };
    archive: {
        fileName: string;
        fileKey: string;
    };
    info: any;
}

export interface DownloadLink {
    link: string;
}
export interface UploadData {
    dataType: string;
    comment: string;
}

export abstract class LegacyUploadsService {
    abstract uploadFCFile(
        dataType: UploadData,
        fileData: FormData,
        purge: boolean,
        audit: boolean,
    ): Observable<DataUpload>;
}
