import {Component} from '@angular/core';
import {CustomizedFieldInterface} from '../../ngx-sc-table-package/pojo/ngx-sc-pojo';

@Component({
    selector: 'ngx-ngx-check-mark-field',
    templateUrl: './ngx-check-mark-field.component.html',
    styleUrls: ['./ngx-check-mark-field.component.scss'],
})
export class NgxCheckMarkFieldComponent implements CustomizedFieldInterface {
    dataKey: any;
    rowData: any;
    value: any;
}
