<div class="password-container">
    <input
        nbInput
        [formControlName]="controlName"
        [id]="id"
        [name]="name"
        [type]="inputMode"
        placeholder=""
        fullWidth
        (input)="onInputChangedEvent($event)"
        [status]="control.dirty ? (control.invalid ? 'danger' : 'success') : 'basic'"
        [attr.aria-invalid]="control.invalid && control.touched ? true : null"
        [fieldSize]="fieldSize"
    />
    <ng-container [ngSwitch]="inputMode">
        <nb-icon
            *ngSwitchCase="'PASSWORD'"
            class="eye-icon"
            icon="eye-off-outline"
            (click)="setInputMode('TEXT')"
        ></nb-icon>
        <nb-icon *ngSwitchDefault class="eye-icon" icon="eye-outline" (click)="setInputMode('PASSWORD')"></nb-icon>
    </ng-container>
</div>
