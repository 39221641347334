<ngx-icon-box
    class="sort-selector abs-top-right"
    [iconDetails]="iconDetails"
    [nbPopover]="sortComponentTemplate"
    nbPopoverTrigger="hover"
    nbPopoverPlacement="bottom"
>
</ngx-icon-box>

<ng-template #sortComponentTemplate>
    <ngx-chart-data-sort-popup
        [options]="_options"
        [selection]="_selection.uniqueCode"
        (selectionChanged)="changeSortSelection($event)"
    >
    </ngx-chart-data-sort-popup>
</ng-template>
