import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpService} from '../../../@core/backend/common/api/http.service';
import {AnalyzerRequest, AnalyzerResponse} from '../../../@core/interfaces/engin/analyzer';

@Injectable()
export class ReportDataApi {
    private prefix = 'reporting/data';

    constructor(private api: HttpService) {}

    getAnalyzerAbstractGraphData(req: AnalyzerRequest, workflowItemId: string): Observable<AnalyzerResponse[]> {
        return this.api.post(`${this.prefix}/analyzer/${workflowItemId}`, req);
    }
}
