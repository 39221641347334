import {ChangeDetectionStrategy, Component, Input, OnChanges, OnInit} from '@angular/core';
import {ECHARTS_TYPE} from '@theme/components/chart-settings/chart-data-download-service';
import {ChartsService} from '@core/utils';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {AnalyzerResponse} from '@core/interfaces/engin/analyzer';
import {Chart} from '@core/utils/charts.service';
import {filter, map, share} from 'rxjs/operators';
import {FormGroup} from '@angular/forms';
import {GraphLabel} from '@core/interfaces/common/pages';
import {GraphLabelService} from '../../graph-label.service';
import {ECharts} from 'echarts';

@Component({
    selector: 'ngx-eol-metric-status',
    templateUrl: './eol-metric-status.component.html',
    styleUrls: ['./eol-metric-status.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EolMetricStatusComponent implements OnInit, OnChanges {
    // Component inputs
    @Input('statusChartData') _statusChartData: AnalyzerResponse; // raw chart data
    @Input('unitOptions') _unitOptions: string[]; // unit selector options
    @Input('labelOptions') _labelOptions: GraphLabel[]; // label selector options
    @Input('graphId') graphId: string;
    @Input('graphsFormGroup') graphsFormGroup: FormGroup; // active group selection control
    @Input() interactive: boolean = true;
    @Input() ignoreFormControl: boolean = false;

    // Static component data
    EC_TYPE_LOCAL = ECHARTS_TYPE.CUSTOM_BAR_STACKED;
    private statusChartData: BehaviorSubject<AnalyzerResponse> = new BehaviorSubject<AnalyzerResponse>(null);
    readonly statusChartData$: Observable<AnalyzerResponse> = this.statusChartData.asObservable().pipe(
        filter((d) => !!d),
        share(),
    );
    public unitOptions: string[];
    public labelOptions: BehaviorSubject<GraphLabel[]> = new BehaviorSubject<GraphLabel[]>(null);
    public labelOptions$: Observable<any> = this.labelOptions.asObservable();

    public chartInstance: ECharts;

    constructor(private chartsService: ChartsService) {}

    ngOnInit(): void {
        this.statusChartData.next(this._statusChartData);
        this.unitOptions = this._unitOptions;
        if (this._labelOptions != null) {
            this.labelOptions.next(this._labelOptions);
        }
    }

    // Propagate external updates of main data source throughout this component
    ngOnChanges(changes: any) {
        if (changes._statusChartData != null && changes._statusChartData.currentValue != null) {
            this.statusChartData.next(changes._statusChartData.currentValue);
        }
    }

    readonly statusChart$ = combineLatest<Observable<AnalyzerResponse>, Observable<Chart>, Observable<GraphLabel[]>>([
        this.statusChartData$,
        this.chartsService.barChartPercentStacked$,
        this.labelOptions$,
    ]).pipe(
        map(([statusChartData, barChartTemplate, graphLabels]: [AnalyzerResponse, Chart, GraphLabel[]]) => {
            const selectedLabel = GraphLabelService.extractGraphLabel(graphLabels, statusChartData.graphId);
            const statusData = this.chartsService.prepareStackedPercentageChartData(
                statusChartData,
                barChartTemplate.colorsMap,
            );
            return this.prepareStatusChartOptions(statusData, barChartTemplate, selectedLabel);
        }),
    );

    private prepareStatusChartOptions(metadata: any, barChart: Chart, selectedLabel: string) {
        if (!metadata.values) return {};

        try {
            const sum = metadata.values
                .map((item) => item.origValue)
                .reduce((t, e) => t.concat(e))
                .reduce((t, e) => t + e);
            if (sum === 0) throw new Error();
        } catch (e) {
            return this.chartsService.getEmptyChartWithMessage(metadata, barChart);
        }

        let dataZoom = {};
        if (this.interactive) {
            dataZoom = {
                id: 'dataZoomY',
                type: 'slider',
                yAxisIndex: [0],
                filterMode: 'filter',
                startValue: metadata.values[0].value.length > 7 ? metadata.values[0].value.length - 1 : 0,
                endValue:
                    metadata.values[0].value.length > 7
                        ? metadata.values[0].value.length - 9
                        : metadata.values[0].value.length - 1,
                show: metadata.values[0].value.length > 7,
                fillerColor: barChart.colorsMap['axisSliderHandle'],
                backgroundColor: barChart.colorsMap['axisSliderBackground'],
                handleSize: '0%',
                showDataShadow: true,
                dataBackground: {
                    areaStyle: {
                        color: barChart.colorsMap['axisSliderShadow'],
                    },
                },
                textStyle: {
                    color: barChart.colorsMap['primaryFontColor'],
                },
            };
        }

        return {
            metadata: metadata,
            options: {
                ...barChart.options,
                title: {
                    x: 'center',
                    text: metadata.name,
                    textStyle: {
                        fontSize: 14,
                        color: barChart.colorsMap['primaryFontColor'],
                    },
                },
                grid: {
                    top: 30,
                    right: 50,
                    bottom: 30,
                    left: 50,
                    containLabel: true,
                },
                legend: {
                    ...barChart.options.legend,
                    data: metadata.legend,
                },
                yAxis: [
                    {
                        ...barChart.options.yAxis[0],
                        data: metadata.labels,
                    },
                ],
                dataZoom: this.interactive ? dataZoom : null,
                tooltip: {
                    ...barChart.options.tooltip,
                    formatter: (params) => {
                        return `${params.data.value ? Math.round(params.data.value) : ''}%<br/>
                        ${params.data.name}: ${params.data.formattedValue}`;
                    },
                },
                series: metadata.values.map((item) => {
                    return {
                        name: item.name,
                        type: 'bar',
                        stack: '1',
                        avoidLabelOverlap: true,
                        label: {
                            ...item.itemStyle.label,
                            color: 'white',
                            textStyle: {
                                fontSize:
                                    metadata.values[0].value.length > 7
                                        ? item.itemStyle.label.textStyle.fontSize - 2
                                        : item.itemStyle.label.textStyle.fontSize,
                            },
                            formatter: function (params) {
                                if (params.data.value) {
                                    if (selectedLabel === 'value-percent') {
                                        // data.value is percentage; below 5% labels are squished
                                        return params.data.value > 3
                                            ? `${params.data.formattedValue}\n(${Math.round(params.data.value)}%)`
                                            : '';
                                    } else {
                                        let percent = Math.round(params.data.value);
                                        return percent < 3 ? '' : `${percent}%`;
                                    }
                                } else {
                                    return '';
                                }
                            },
                        },
                        itemStyle: {
                            ...item.itemStyle,
                        },
                        data: item.value.map((value, i) => {
                            return {
                                value: value,
                                name: item.name,
                                origValue: item.origValue[i],
                                formattedValue: item.formattedValue[i],
                            };
                        }),
                    };
                }),
            },
        };
    }
}
