<!--<span class="ml-3" *ngIf="service.loading$ | async">Loading...</span>-->
<div [ngClass]="!expectStyle ? 'ngx-sc-table-container' : 'ngx-sc-table-container-sub'">
    <div class="d-flex justify-content-between">
        <div class="p-3">
            <ng-container *ngIf="title && description">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col">
                            <h5>{{ title }}</h5>
                            <p>{{ description }}</p>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>

        <div class="p-3">
            <ng-container *ngIf="dropDownBox.length >= 1">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col">
                            <nb-select
                                placeholder="Select Showcase"
                                style="height: 50px"
                                (selectedChange)="onOptionsSelected(this.dataset, $event)"
                            >
                                <nb-option
                                    *ngFor="let drop of dropDownBox"
                                    value="{{ drop.value }}"
                                    class="dropdown-box"
                                >
                                    <ngx-icon-box class="{{ drop.icon }}" icon="{{ drop.icon }}"></ngx-icon-box>
                                    {{ drop.value }}
                                </nb-option>
                            </nb-select>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>

        <ng-container *ngIf="showItemOnPage">
            <div class="form-group input-group">
                <input
                    type="text"
                    class="form-control input-sm input-box"
                    [ngClass]="showItemOnPage ? 'display-block' : 'display-none'"
                    name="perPage"
                    [(ngModel)]="service.perPage"
                />
                <div class="input-group-append">
                    <span class="input-group-text">item(s) on page</span>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="showTopPager">
            <ngx-table-pager
                [ngClass]="showTopPager ? 'display-block' : 'display-none'"
                class="ngx-sc-table-pager"
                (onPageSelected)="pageSelected($event)"
                [selectedPage]="this.service.pageIndex"
                [pagesCount]="this.service.pagesCount"
            >
            </ngx-table-pager>
        </ng-container>

        <ng-container *ngIf="showFullTextSearch">
            <div
                class="form-group input-group w-25 p-3"
                [ngClass]="showFullTextSearch ? 'display-block' : 'display-none'"
            >
                <div class="input-group-prepend">
                    <span class="input-group-text">Full text search</span>
                </div>
                <input class="form-control input-sm" type="text" name="searchTerm" [(ngModel)]="service.searchTerm" />
            </div>
        </ng-container>
    </div>
    <table class="table table-responsive table-hover ngx-sc-table">
        <!--      table-bordered  table-striped-->

        <!-- TABLE-HEADER -->
        <thead>
            <tr class="d-flex">
                <th
                    scope="col"
                    *ngFor="let column of tableSettings.columns"
                    ngxScSortable
                    [sortable]="column.dataKey"
                    (sort)="onSort($event)"
                    [ngClass]="column.bsClass"
                >
                    <div *ngIf="!column.dropSort; else dropSorting">
                        <span *ngIf="column.titleSquared; else default">
                            {{ column.title }} <sup>{{ column.titleSquared }}</sup>
                        </span>
                        <ng-template #default>
                            <span>{{ column.title }}</span>
                        </ng-template>
                    </div>

                    <ng-template #dropSorting>
                        <span [nbContextMenu]="items" nbContextMenuTrigger="click" nbContextMenuTag="my-context-menu">
                            {{ column.title }}
                        </span>
                    </ng-template>
                </th>
            </tr>

            <tr *ngIf="showFilter" class="d-flex">
                <th scope="col" *ngFor="let column of tableSettings.columns" [ngClass]="column.bsClass">
                    <span *ngIf="column.type === COLUMN_TYPE.EXPANDABLE; else filterBlock">
                        <ng-container *ngIf="dataset$ | async as dataRow">
                            <button
                                class="btn btn-ssm btn-secondary"
                                (click)="toggleDetailAll(dataRow, column.dataKey)"
                            >
                                <ng-container *ngIf="isCollapsedAllCurrent; else cTagNHeader">+</ng-container>
                                <ng-template #cTagNHeader>-</ng-template>
                            </button>
                        </ng-container>
                    </span>
                    <ng-template #filterBlock>
                        <input
                            type="text"
                            style="width: 90%"
                            [filterable]="column.dataKey"
                            ngxScFilterable
                            (filter)="onFilter($event)"
                            [disabled]="!column.filter"
                        />
                    </ng-template>
                </th>
            </tr>
        </thead>
        <tbody>
            <!-- TABLE-BODY -->
            <ng-container *ngFor="let dataRow of dataset$ | async; let i = index" class="table-body">
                <tr class="d-flex" [ngClass]="{'tr-stripped': i % 2 === 0}">
                    <!--            -->
                    <td
                        *ngFor="let columnSetting of tableSettings.columns"
                        [ngClass]="columnSetting.bsClass"
                        [scope]="i === 0 ? 'row' : ''"
                    >
                        <ng-container [ngSwitch]="columnSetting.type">
                            <!-- Data -->
                            <div *ngSwitchCase="COLUMN_TYPE.DATA" class="mt-2">
                                <ngb-highlight
                                    [result]="
                                        renderValue(columnSetting.valuePrepareFunction, dataRow[columnSetting.dataKey])
                                    "
                                    [term]="service.searchTerm"
                                ></ngb-highlight>
                            </div>
                            <!-- HTML -->

                            <ng-container *ngSwitchCase="COLUMN_TYPE.CUSTOM">
                                <div class="mt-2">
                                    <ngx-column-with-icon
                                        [rowData]="dataRow[columnSetting.dataKey]"
                                        [colTitle]="columnSetting.title"
                                        [tableIcons]="tableSettings.columns"
                                        [dataHasName]="columnSetting.iconWithName"
                                        [preparedData]="
                                            renderValue(
                                                columnSetting.valuePrepareFunction,
                                                dataRow[columnSetting.dataKey]
                                            )
                                        "
                                    >
                                    </ngx-column-with-icon>
                                </div>
                            </ng-container>

                            <!-- Customized Component -->
                            <ng-container *ngSwitchCase="COLUMN_TYPE.COMPONENT">
                                <ngx-sc-table-container
                                    [rowData]="dataRow"
                                    [rowIndex]="i"
                                    [dataKey]="columnSetting.dataKey"
                                    [value]="dataRow[columnSetting.dataKey]"
                                    [renderComponent]="columnSetting.renderComponent"
                                    [onComponentInitFunction]="columnSetting.onComponentInitFunction"
                                >
                                </ngx-sc-table-container>
                            </ng-container>

                            <!-- Expandable Switch -->
                            <ng-container *ngIf="typeOfTable; else defaultTable">
                                <ng-container *ngSwitchCase="COLUMN_TYPE.EXPANDABLE">
                                    <button
                                        nbButton
                                        status="basic"
                                        size="tiny"
                                        [ngClass]="
                                            typeOfTable === 'analysisHistory'
                                                ? 'analysis-history-btn'
                                                : 'non-analysis-history-btn'
                                        "
                                        (click)="toggleDetail(dataRow[columnSetting.dataKey], this, icons)"
                                        *ngFor="let icons of iconsForAction"
                                    >
                                        <ng-container
                                            *ngIf="
                                                dataRow[columnSetting.dataKey] &&
                                                    dataRow[columnSetting.dataKey].isCollapsed;
                                                else cTagN
                                            "
                                        >
                                            <ngx-icon-box icon="{{ icons }}"></ngx-icon-box>
                                        </ng-container>
                                        <ng-template #cTagN>
                                            <ngx-icon-box icon="{{ icons }}"></ngx-icon-box>
                                        </ng-template>
                                    </button>
                                </ng-container>

                                <!-- Expandable Switch -->
                                <ng-container *ngSwitchCase="COLUMN_TYPE.EXPANDABLE_CONTAINER">
                                    <div *ngIf="iconsForAction.length >= 1; else iconsName">
                                        <button
                                            nbButton
                                            status="basic"
                                            size="tiny"
                                            class="details-btn"
                                            [ngClass]="
                                                typeOfTable === 'analysisHistory'
                                                    ? 'analysis-history-btn'
                                                    : 'non-analysis-history-btn'
                                            "
                                            *ngFor="let icons of iconsForAction"
                                            [disabled]="disableDetailsNav(dataRow[columnSetting.dataKey])"
                                            (click)="toggleDetail(dataRow[columnSetting.dataKey], this, icons)"
                                        >
                                            <ng-container
                                                *ngIf="
                                                    dataRow[columnSetting.dataKey] &&
                                                        dataRow[columnSetting.dataKey].isCollapsed;
                                                    else cTagN
                                                "
                                            >
                                                <div
                                                    class="icons-container"
                                                    *ngIf="columnSetting.valuePrepareFunction; else defaultIcon"
                                                >
                                                    <ngx-column-with-icon
                                                        [rowData]="dataRow"
                                                        [colTitle]="columnSetting.valuePrepareFunction()"
                                                        [tableIcons]="icons"
                                                    >
                                                    </ngx-column-with-icon>
                                                </div>
                                                <ng-template #defaultIcon>
                                                    <ngx-icon-box icon="{{ icons }}"></ngx-icon-box>
                                                </ng-template>
                                            </ng-container>
                                            <ng-template #cTagN>
                                                <div
                                                    class="icons-container"
                                                    *ngIf="columnSetting.valuePrepareFunction; else defaultIcon"
                                                >
                                                    <ngx-column-with-icon
                                                        [rowData]="dataRow"
                                                        [colTitle]="columnSetting.valuePrepareFunction()"
                                                        [tableIcons]="icons"
                                                    >
                                                    </ngx-column-with-icon>
                                                </div>
                                                <ng-template #defaultIcon>
                                                    <ngx-icon-box icon="{{ icons }}"></ngx-icon-box>
                                                </ng-template>
                                            </ng-template>
                                        </button>
                                    </div>

                                    <ng-template #iconsName>
                                        <button
                                            nbButton
                                            status="basic"
                                            size="tiny"
                                            [ngClass]="
                                                typeOfTable === 'analysisHistory'
                                                    ? 'analysis-history-btn'
                                                    : 'non-analysis-history-btn'
                                            "
                                            *ngFor="let names of namesForAction"
                                            (click)="toggleDetail(dataRow[columnSetting.dataKey], this, names)"
                                        >
                                            <ng-container
                                                *ngIf="
                                                    dataRow[columnSetting.dataKey] &&
                                                        dataRow[columnSetting.dataKey].isCollapsed;
                                                    else cTagN
                                                "
                                            >
                                                <button nbButton status="basic" size="small">{{ names }}</button>
                                            </ng-container>
                                            <ng-template #cTagN>
                                                <button nbButton status="basic" size="small">{{ names }}</button>
                                            </ng-template>
                                        </button>
                                    </ng-template>

                                    <!---------------------------------need to be changed dynamically-------------------------------------->

                                    <div
                                        class="dialog-box"
                                        [ngClass]="
                                            !dataRow[columnSetting.dataKey].isToggled ? 'display-block' : 'display-none'
                                        "
                                    >
                                        <button
                                            class="btn-close-toggle"
                                            (click)="toggleFileBoxClose(dataRow[columnSetting.dataKey])"
                                        >
                                            x
                                        </button>
                                        <div class="row">
                                            <div class="col action-tab" (click)="fileDownload(dataRow, 'full')">
                                                <p>Full Download</p>
                                            </div>
                                        </div>
                                        <!--
                                    <div class="container-fluid">
                                      <div class="row">
                                        <div class="col action-tab"
                                             (click)="fileDownload(this,'full')"><p>Full
                                          Download</p></div>
                                      </div>
                                      <div class="row">
                                        <div class="col action-tab"
                                             (click)="fileDownload(this,'partial')"><p>Partial
                                          Download</p></div>
                                      </div>
                                    </div>
                                    <div class="container-fluid">
                                      <div class="row">
                                        <div class="col action-tab"
                                             (click)="fileEmail(this,'full')"><p>Full Email</p>
                                        </div>
                                      </div>
                                      <div class="row">
                                        <div class="col action-tab"
                                             (click)="fileEmail(this,'partial')"><p>Partial
                                          Email</p></div>
                                      </div>
                                    </div>
                                    --></div>
                                </ng-container>

                                <ng-container *ngSwitchCase="COLUMN_TYPE.EXPANDABLE_CONTAINER_WITH_CHART">
                                    <div *ngIf="iconsForAction.length >= 1; else iconsName">
                                        <button
                                            nbButton
                                            status="basic"
                                            size="tiny"
                                            [ngClass]="
                                                typeOfTable === 'analysisHistory'
                                                    ? 'analysis-history-btn'
                                                    : 'non-analysis-history-btn'
                                            "
                                            *ngFor="let icons of iconsForAction"
                                            (click)="toggleDetail(dataRow[columnSetting.dataKey], this, icons)"
                                        >
                                            <ng-container
                                                *ngIf="
                                                    dataRow[columnSetting.dataKey] &&
                                                        dataRow[columnSetting.dataKey].isCollapsed;
                                                    else cTagN
                                                "
                                            >
                                                <ngx-icon-box icon="{{ icons }}"></ngx-icon-box>
                                            </ng-container>
                                            <ng-template #cTagN>
                                                <ngx-icon-box icon="{{ icons }}"></ngx-icon-box>
                                            </ng-template>
                                        </button>
                                    </div>

                                    <ng-template #iconsName>
                                        <button
                                            nbButton
                                            status="basic"
                                            size="tiny"
                                            class="chart-detail-btn"
                                            [ngClass]="
                                                typeOfTable === 'analysisHistory'
                                                    ? 'analysis-history-btn'
                                                    : 'non-analysis-history-btn'
                                            "
                                            *ngFor="let names of namesForAction"
                                            (click)="toggleDetail(dataRow[columnSetting.dataKey], this, names)"
                                        >
                                            <ng-container
                                                *ngIf="
                                                    dataRow[columnSetting.dataKey] &&
                                                        dataRow[columnSetting.dataKey].isCollapsed;
                                                    else cTagN
                                                "
                                            >
                                                <button nbButton status="basic" size="small">{{ names }}</button>
                                            </ng-container>
                                            <ng-template #cTagN>
                                                <button nbButton status="basic" size="small">{{ names }}</button>
                                            </ng-template>
                                        </button>
                                    </ng-template>
                                </ng-container>
                            </ng-container>
                            <!----------------------------------------------------------------------------------------->

                            <ng-template #defaultTable>
                                <ng-container *ngIf="typeOfTable === 'curveData'; else defaultTable">
                                    <ng-container *ngSwitchCase="COLUMN_TYPE.EXPANDABLE">
                                        <button
                                            class="btn btn-ssm btn-secondary"
                                            (click)="toggleDetail(dataRow[columnSetting.dataKey])"
                                        >
                                            <ng-container
                                                *ngIf="
                                                    dataRow[columnSetting.dataKey] &&
                                                        dataRow[columnSetting.dataKey].isCollapsed;
                                                    else cTagN
                                                "
                                            >
                                                +
                                            </ng-container>
                                            <ng-template #cTagN>-</ng-template>
                                        </button>
                                    </ng-container>

                                    <!-- Expandable Switch -->
                                    <ng-container *ngSwitchCase="COLUMN_TYPE.EXPANDABLE_CONTAINER">
                                        <button
                                            class="btn btn-ssm btn-secondary"
                                            (click)="toggleDetail(dataRow[columnSetting.dataKey])"
                                        >
                                            <ng-container
                                                *ngIf="
                                                    dataRow[columnSetting.dataKey] &&
                                                        dataRow[columnSetting.dataKey].isCollapsed;
                                                    else cTagN
                                                "
                                            >
                                                +
                                            </ng-container>
                                            <ng-template #cTagN>-</ng-template>
                                        </button>
                                    </ng-container>

                                    <ng-container *ngSwitchCase="COLUMN_TYPE.EXPANDABLE_CONTAINER_WITH_CHART">
                                        <button
                                            class="btn btn-ssm btn-secondary"
                                            (click)="toggleDetail(dataRow[columnSetting.dataKey])"
                                        >
                                            <ng-container
                                                *ngIf="
                                                    dataRow[columnSetting.dataKey] &&
                                                        dataRow[columnSetting.dataKey].isCollapsed;
                                                    else cTagN
                                                "
                                            >
                                                +
                                            </ng-container>
                                            <ng-template #cTagN>-</ng-template>
                                        </button>
                                    </ng-container>
                                </ng-container>
                            </ng-template>
                        </ng-container>
                    </td>
                </tr>

                <tr class="d-flex no-hover">
                    <ng-container *ngFor="let columnSettingExp of tableSettings.columns">
                        <ng-container [ngSwitch]="columnSettingExp.type">
                            <!--                        -->
                            <ng-container *ngSwitchCase="COLUMN_TYPE.EXPANDABLE">
                                <td
                                    class="width-100"
                                    [attr.colspan]="tableSettings.columns.length"
                                    id="{{ 'row_' + i + '_' + columnSettingExp.title }}"
                                    [ngbCollapse]="
                                        dataRow[columnSettingExp.dataKey] &&
                                        dataRow[columnSettingExp.dataKey].isCollapsed
                                    "
                                >
                                    <ngx-sc-table-simple
                                        [dataset]="dataRow[columnSettingExp.dataKey]"
                                        [tableSettings]="columnSettingExp"
                                    >
                                    </ngx-sc-table-simple>
                                </td>
                            </ng-container>
                            <!--                        -->
                            <ng-container *ngSwitchCase="COLUMN_TYPE.EXPANDABLE_CONTAINER">
                                <td
                                    class="width-100"
                                    [attr.colspan]="tableSettings.columns.length"
                                    id="{{ 'row_' + i + '_' + columnSettingExp.title }}"
                                    [ngbCollapse]="
                                        dataRow[columnSettingExp.dataKey] &&
                                        dataRow[columnSettingExp.dataKey].isCollapsed
                                    "
                                >
                                    <ngx-sc-table-container
                                        [rowData]="dataRow"
                                        [rowIndex]="i"
                                        [dataKey]="columnSettingExp.dataKey"
                                        [value]="dataRow[columnSettingExp.dataKey]"
                                        [renderComponent]="columnSettingExp.renderComponent"
                                        [onComponentInitFunction]="columnSettingExp.onComponentInitFunction"
                                    >
                                    </ngx-sc-table-container>
                                </td>
                            </ng-container>

                            <!--need to modify in effective way-->
                            <ng-container *ngSwitchCase="COLUMN_TYPE.EXPANDABLE_CONTAINER_WITH_CHART">
                                <td
                                    class="width-100"
                                    [attr.colspan]="tableSettings.columns.length"
                                    id="{{ 'row_' + i + '_' + columnSettingExp.title }}"
                                    [ngbCollapse]="
                                        dataRow[columnSettingExp.dataKey] &&
                                        dataRow[columnSettingExp.dataKey].isCollapsed
                                    "
                                >
                                    <div *ngIf="!dataRow[columnSettingExp.dataKey].isCollapsed">
                                        <ngx-sc-chat
                                            [rowData]="dataRow"
                                            [rowIndex]="i"
                                            [dataKey]="columnSettingExp.dataKey"
                                            [value]="dataRow[columnSettingExp.dataKey]"
                                            [renderComponent]="columnSettingExp.renderComponent"
                                            [onComponentInitFunction]="columnSettingExp.onComponentInitFunction"
                                        >
                                        </ngx-sc-chat>
                                    </div>
                                </td>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </tr>
            </ng-container>
        </tbody>
    </table>

    <div class="d-flex justify-content-between">
        <div class="w-25"></div>

        <ng-container *ngIf="showBottomPager">
            <ngx-table-pager
                class="ngx-sc-table-pager"
                (onPageSelected)="pageSelected($event)"
                [selectedPage]="this.service.pageIndex"
                [pagesCount]="this.service.pagesCount"
            >
            </ngx-table-pager>
        </ng-container>
        <div class="w-25"></div>
    </div>
</div>
